import React, { useCallback } from 'react';

import { Button, Text } from '@components';
import { useEmailQuote } from '@hooks';
import { i18n } from '@i18n';
import { useQuoteStore } from '@store';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';
import { sendAnalyticsEvent, testProps } from '@utils';

export interface EmailQuoteButtonProps {
  type: 'button' | 'link';
}

export const EmailQuoteButton: React.FC<EmailQuoteButtonProps> = ({ type }) => {
  const { isEmailQuoteVisible, openEmailQuoteModal } = useEmailQuote();
  const setDidEmailQuoteShow = useQuoteStore(state => state.setDidEmailQuoteShow);

  const handleEmailQuote = useCallback(() => {
    openEmailQuoteModal();
    setDidEmailQuoteShow(true);
    sendAnalyticsEvent('Email a Quote Form Opened', { flow: EMAIL_QUOTE_FLOW_TYPE.FOOTER });
  }, [openEmailQuoteModal, setDidEmailQuoteShow]);

  if (type === 'link' && isEmailQuoteVisible) {
    return (
      <a
        {...testProps('email_quote_button')}
        className="text-white cursor-pointer block sm:inline"
        onClick={handleEmailQuote}>
        {' '}
        |{' '}
        <Text variant="caption-12/regular" className="inline">
          {i18n.t('checkout.text_one_pager_email_quote_btn')}
        </Text>
      </a>
    );
  }
  if (type === 'button') {
    return (
      <Button
        {...testProps('email_quote_button')}
        variant="snow"
        className="sm:w-[150px]"
        onClick={handleEmailQuote}>
        <Text variant="sub-heading-14/bold">
          {i18n.t('checkout.text_one_pager_email_quote_btn')}
        </Text>
      </Button>
    );
  }
  return null;
};
