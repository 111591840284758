import { useCallback } from 'react';

import { Boost } from 'freely-shared-types';
import { getBoostModalType, isBoostExtraCancellation } from 'freely-shared-utils';

import { getSelectedBoostById, openModalSelector, useModalStore, useTripStore } from '@store';

/**
 * Gets the appropriate modal component based on boost
 * @param selectedBoost
 */
function getBoostComponentName(selectedBoost?: Boost) {
  const boostModalType = getBoostModalType(selectedBoost);
  switch (boostModalType) {
    case 'addSpecifiedItems':
      return 'AddSpecifiedItemsModal';

    case 'extraCancellation':
      return 'ExtraCancellationModal';

    case 'payPerDayCalendar':
      return 'BoostCalendarModal';

    case null:
    default:
      return '';
  }
}
export function useBoostSelectionModal(boostId?: string) {
  const openModal = useModalStore(openModalSelector);
  const selectedBoost = useTripStore(getSelectedBoostById(boostId));
  const revertExtraCancellation = useTripStore(state => state.revertExtraCancellation);

  //Used to revert extra cancellation on modal press.
  const onClose = useCallback(async () => {
    if (!isBoostExtraCancellation(selectedBoost)) {
      return;
    }

    await revertExtraCancellation();
  }, [revertExtraCancellation, selectedBoost]);

  /**
   * Checks to see if the boost has its own selection modal
   * and will display the required modal.
   */
  return useCallback(
    (onSuccess?: () => void) => {
      const bodyComponentName = getBoostComponentName(selectedBoost);

      if (!bodyComponentName || !selectedBoost) {
        return;
      }

      return openModal(`Modal`, {
        body: {
          type: bodyComponentName,
          boostId: selectedBoost.boostId,
          onSuccess: onSuccess,
        },
        size: 'md',
        bodyContainerClassName: 'mt-2',
        titleClassName: '',
        sendExitEvent: true,
        onClose,
      });
    },
    [openModal, selectedBoost, onClose],
  );
}
