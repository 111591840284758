import { FC } from 'react';

import { Text } from '../text';

export interface ToastProps {
  onOk: () => void;
  message: string;
}

export const Toast: FC<ToastProps> = ({ message, onOk }) => (
  <div className="bg-charcoal rounded-lg shadow-lg">
    <div className="flex flex-wrap items-center justify-between">
      <div className="ml-3 w-3/4 overflow-x-auto p-2 font-medium text-white sm:w-80 sm:p-3">
        <Text variant="body-18/medium" className="truncate">
          {message}
        </Text>
      </div>
      <div className="border-l-steal flex-shrink-0 border-l sm:ml-2">
        <button
          type="button"
          className="focus:ring-steal m-2 flex rounded-md p-2 focus:outline-none focus:ring-2 sm:m-3"
          onClick={onOk}>
          <span className="sr-only">Dismiss</span>
          <Text variant="body-18/bold" className="text-steal" aria-hidden="true">
            OK
          </Text>
        </button>
      </div>
    </div>
  </div>
);
