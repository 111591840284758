import React from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { isUSRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getExcessDescription } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Popover, Text } from '@components';
import { i18n } from '@i18n';
import { tripExcessAmountSelector, useTripStore } from '@store';

type Props = {
  className?: string;
};

export const ExcessAmount: React.FC<Props> = ({ className }) => {
  const isUS = useRegionStore(isUSRegionSelector);
  const excessAmount = useTripStore(tripExcessAmountSelector);
  const excessDescription = getExcessDescription({ excessAmount });

  return !isUS ? (
    <div className={twMerge('flex flex-row items-center', className)}>
      <div className="flex flex-row mr-1.5">
        <Text variant="body-18/bold" className="pr-1">
          {i18n.t('myQuote.excess.label', { excessAmount })}
        </Text>
      </div>
      <Popover
        titleProps={{ children: i18n.t('myQuote.excess.title'), withMarkDown: true }}
        contentProps={{ children: excessDescription }}>
        <Assets.InfoCircle
          cursor="pointer"
          fill={colors.steal}
          height={14}
          width={14}
          className="ml-0.5 mt-1.5"
        />
      </Popover>
    </div>
  ) : null;
};
