import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { EmailQuoteDetails } from 'freely-shared-types';
import { abandonedCartSchema } from 'freely-shared-utils';

import { Button, Input, Notification, Text } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEmailQuote, useEmailQuoteModalCSNumberExperiment } from '@hooks';
import { i18n } from '@i18n';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';

type Props = {
  flow?: EMAIL_QUOTE_FLOW_TYPE;
};

export const EmailQuote: React.FC<Props> = ({ flow = EMAIL_QUOTE_FLOW_TYPE.FOOTER }) => {
  const { sendEmailQuote, closeEmailQuoteModal, hasExceedEmailLimit } = useEmailQuote();
  const { payload } = useEmailQuoteModalCSNumberExperiment();
  const description = i18n.t(
    payload?.csNumberEnabled || flow === EMAIL_QUOTE_FLOW_TYPE.FOOTER
      ? 'emailQuote.sendEmail.description.customerService'
      : 'emailQuote.sendEmail.description.default',
  );
  const firstNameValidationMessage = i18n.t('emailQuote.sendEmail.inputs.firstName.validation');
  const lastNameValidationMessage = i18n.t('emailQuote.sendEmail.inputs.lastName.validation');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<EmailQuoteDetails>({
    resolver: zodResolver(
      abandonedCartSchema(firstNameValidationMessage, lastNameValidationMessage),
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: EmailQuoteDetails) => {
    await sendEmailQuote(data);
  };

  useEffect(() => {
    if (hasExceedEmailLimit) {
      reset();
    }
  }, [hasExceedEmailLimit, reset]);

  return (
    <form className="w-full relative md:px-4">
      <Text variant="callout-16/400" className="pb-6 text-left whitespace-pre-wrap" withMarkDown>
        {description?.replace(/\n/gi, '&nbsp;\n')}
      </Text>
      {hasExceedEmailLimit && (
        <Notification
          variant="alert"
          headingProps={{
            children: i18n.t('emailQuote.sendEmail.exceededLimitNotification.title'),
          }}
          descriptionProps={{
            children: i18n.t('emailQuote.sendEmail.exceededLimitNotification.description'),
          }}
        />
      )}
      <div className="flex-col mb-4 space-y-4">
        <div className="flex-1">
          <Input
            isSuccess={!!watch('firstName') && !errors.firstName}
            placeholder={i18n.t('emailQuote.sendEmail.inputs.firstName.placeholder')}
            {...register('firstName')}
            autoComplete="given-name"
            type={'text'}
            hasError={!!errors.firstName}
            errorMessage={errors.firstName?.message}
            errorMessageClassName={'pl-2 pt-1 text-left'}
            isDisabled={hasExceedEmailLimit}
          />
        </div>
        <div className="flex-1">
          <Input
            isSuccess={!!watch('lastName') && !errors.lastName}
            placeholder={i18n.t('emailQuote.sendEmail.inputs.lastName.placeholder')}
            {...register('lastName')}
            autoComplete="family-name"
            type={'text'}
            hasError={!!errors.lastName}
            errorMessage={errors.lastName?.message}
            errorMessageClassName={'pl-2 pt-1 text-left'}
            isDisabled={hasExceedEmailLimit}
          />
        </div>
        <Input
          inputMode="email"
          containerProps={{ className: 'mb-4' }}
          isSuccess={!!watch('email') && !errors.email}
          placeholder={i18n.t('emailQuote.sendEmail.inputs.email.placeholder')}
          {...register('email')}
          type="email"
          autoComplete="email"
          hasError={!!errors.email}
          errorMessage={errors.email?.message}
          errorMessageClassName={'pl-2 pt-1 text-left'}
          isDisabled={hasExceedEmailLimit}
        />
        <div>
          <Text variant="caption-12/400" className="text-left" withMarkDown>
            {i18n.t('emailQuote.sendEmail.agreement')}
          </Text>
        </div>
        <div className={'flex flex-col space-y-3'}>
          <Button
            variant="charcoal"
            size="md"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={hasExceedEmailLimit}>
            {i18n.t('emailQuote.sendEmail.buttonActions.send')}
          </Button>
          <Button variant="outline" size="md" type="submit" onClick={closeEmailQuoteModal}>
            {i18n.t('emailQuote.sendEmail.buttonActions.noThanks')}
          </Button>
        </div>
      </div>
    </form>
  );
};
