import { logToSentry, wait } from 'freely-shared-utils';

import { Button } from '@components';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  closeModalSelector,
  getTrip,
  openModalSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useModalStore,
} from '@store';
import { sendAnalyticsEvent } from '@utils';

export const EmcScreeningResultsFooterButtons = () => {
  const { emcCurrentTraveller } = useEmcTraveller();
  const { isCovered, isAdded } = emcCurrentTraveller ?? {};
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const removeNameChangedTraveller = useEmcStore(state => state.removeNameChangedTraveller);

  const handleContinue = async () => {
    removeNameChangedTraveller(emcDeclaring?.travellerId ?? '');
    openModal('FullScreenModal', {
      body: {
        type: 'EMCRejectedEmailAssessment',
      },
    });
  };

  const handleDeclineCover = () => {
    openModal('FullScreenModal', {
      body: {
        type: 'EMCDecline',
        primaryAction: async () => {
          closeModal();
          await wait(1);
          setOpenEmcSection(undefined);
          if (isAdded) {
            try {
              await saveScreeningResult({
                isAdded: false,
                isSelected: true,
              });
              await getTrip();
            } catch (e) {
              logToSentry(e as Error);
            }
          }
        },
        secondaryAction: () => {
          closeModal();
        },
      },
    });
  };

  const handleIncludeCover = async (isAdded = true) => {
    if (!emcCurrentTraveller?.isAdded) {
      try {
        await saveScreeningResult({
          isAdded,
          isSelected: true,
        });
        await getTrip();
        sendAnalyticsEvent('Cover Added', {
          TravellerID: emcCurrentTraveller?.travellerId ?? '',
          Premium: emcCurrentTraveller?.price ?? '',
        });
      } catch (e) {
        logToSentry(e as Error);
      }
    }
    return setOpenEmcSection(undefined);
  };

  if (!isCovered) {
    return (
      <Button
        className="mt-10 w-full md:w-auto md:min-w-[18rem] mb-10"
        variant="charcoal"
        onClick={handleContinue}>
        {i18n.t('emcShared.screeningResult.button.rejected.primary')}
      </Button>
    );
  }
  if (emcDeclaring?.requireReDeclare) {
    return (
      <Button
        className="mt-10 w-full md:w-auto md:min-w-[18rem] mb-10"
        variant="charcoal"
        onClick={() => handleIncludeCover(!!isAdded)}>
        {i18n.t('emcShared.screeningResult.button.redeclaring')}
      </Button>
    );
  }
  return (
    <div className="flex flex-col w-full md:flex-row md:justify-end md:space-x-4">
      <Button
        className="w-full md:w-auto md:min-w-[18rem] order-2 md:order-1"
        variant="snow"
        onClick={handleDeclineCover}>
        {i18n.t('emcShared.screeningResult.button.covered.secondary')}
      </Button>
      <Button
        className="w-full md:w-auto md:min-w-[18rem] order-1 md:order-2 mb-4"
        variant="charcoal"
        onClick={handleIncludeCover}>
        {i18n.t('emcShared.screeningResult.button.covered.primary')}
      </Button>
    </div>
  );
};
