import { FC, Fragment, useState } from 'react';

import { convertCentsToDollars, getExistingSpecifiedItems } from 'freely-shared-utils';

import { useBoostSelectionModal } from '@hooks';
import { i18n } from '@i18n';
import { getSelectedBoostById, useTripStore } from '@store';

import { Button } from '../button';
import { Loader } from '../loader';
import { SpecifiedItemListItem } from '../specifiedItemListItem';
import { Text } from '../text';

type Props = {
  boostId?: string;
  showButton?: boolean;
};

export const SpecifiedItemsList: FC<Props> = ({ boostId, showButton = true }) => {
  const removeBoost = useTripStore(state => state.removeBoost);
  const boost = useTripStore(getSelectedBoostById(boostId));
  const specifiedItemsList = getExistingSpecifiedItems(boost);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const openBoostModal = useBoostSelectionModal(boostId);

  const onRemoveAll = async () => {
    if (!boostId) {
      return;
    }
    setIsRemoveLoading(true);
    await removeBoost({ boostId });
    setIsRemoveLoading(false);
  };
  const numberOfItems = specifiedItemsList.length;

  if (!specifiedItemsList || !numberOfItems) {
    return <></>;
  }

  return (
    <>
      <div className=" border-rain mb-4 rounded-lg border p-4">
        <div className="border-rain flex flex-row items-start justify-between border-b pb-4 sm:items-center">
          <Text variant="sub-title-20/700">
            {i18n.t('boostDetails.specifiedItems.title', { count: numberOfItems })}
          </Text>
          {isRemoveLoading && <Loader size="sm" />}
          {!isRemoveLoading && (
            <button onClick={onRemoveAll} className="underline whitespace-nowrap">
              <Text variant="sub-heading-14/500">
                {i18n.t('boostDetails.boostHeader.primaryButton.removeAll')}
              </Text>
            </button>
          )}
        </div>
        {specifiedItemsList.map((it, index) => {
          return (
            <Fragment key={it.itemId}>
              <SpecifiedItemListItem specifiedItem={it} index={index} />
            </Fragment>
          );
        })}
        <div className="border-rain flex flex-row items-center justify-between pt-2">
          <Text variant="sub-title-20/700">{i18n.t('boostDetails.specifiedItems.subtotal')}</Text>
          <Text variant="sub-title-20/700">{convertCentsToDollars(boost?.price)}</Text>
        </div>
      </div>
      {showButton && (
        <Button
          onClick={openBoostModal}
          variant="mint"
          className="my-5 w-full md:w-[30%] lg:w-[20%]">
          {i18n.t('boostDetails.specifiedItems.primaryButton.add')}
        </Button>
      )}
    </>
  );
};
