import { useEffect } from 'react';

import { SelectTravelDates } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';
import { sendAnalyticsPage } from '@utils';

export const TravelDates = () => {
  useEffect(() => {
    sendAnalyticsPage('Trip Dates Page Viewed');
  }, []);

  return (
    <SelectTravelDates
      onNext={() => router.navigate(ROUTE_PATHS().tripTravellers)}
      onBack={() => router.navigate(-1)}
    />
  );
};
