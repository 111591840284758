import React from 'react';

import { testProps } from '@utils';

import { Button } from '../../button';

export interface Props {
  onBack: () => void;
  onNext: () => Promise<void> | void;
  isLoading: boolean;
  isNextDisabled: boolean;
}

export const DestinationsFooter: React.FC<Props> = ({
  isNextDisabled,
  isLoading,
  onBack,
  onNext,
}) => (
  <div className="mt-6 flex w-full flex-col items-center gap-4 border-t pt-6">
    <Button
      {...testProps('destination-next-button')}
      isJumboSize
      variant="mint"
      className="w-full"
      size="md"
      isLoading={isLoading}
      disabled={isNextDisabled}
      onClick={onNext}>
      Next
    </Button>
    <Button
      className="w-full"
      {...testProps('destination-back-button')}
      variant="snow"
      size="md"
      onClick={onBack}>
      Back
    </Button>
  </div>
);
