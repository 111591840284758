import {
  CognitoAWSCredentialsAndIdentityIdProvider,
  DefaultIdentityIdStore,
  cognitoUserPoolsTokenProvider,
} from 'aws-amplify/auth/cognito';
import { defaultStorage, sessionStorage } from 'aws-amplify/utils';
import { useEffect, useState } from 'react';

import { getMaintenanceStatus, useConfigStore } from 'freely-shared-stores';
import { PLATFORM, WebConfig } from 'freely-shared-types';
import { amplifyConfigure } from 'freely-shared-utils';

import { getUserIpAddress } from '@utils';

import { version } from '../../package.json';

export const useAppInit = () => {
  const getConfig = useConfigStore(state => state?.getConfig);
  const isUnderMaintenance = useConfigStore(getMaintenanceStatus);
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    (async () => {
      const config = await getConfig();
      const awsConfig =
        config?.web.AWS_AMPLIFY ?? import.meta.env.VITE_REMOTE_CONFIG['AWS_AMPLIFY'];
      const appConfig =
        config?.web.APP_CONFIG ?? (import.meta.env.VITE_REMOTE_CONFIG['APP_CONFIG'] as WebConfig);

      const cognitoCredentialsProvider = new CognitoAWSCredentialsAndIdentityIdProvider(
        new DefaultIdentityIdStore(
          appConfig?.AUTH_STORAGE_TYPE === 'sessionStorage' ? sessionStorage : defaultStorage,
        ),
      );

      amplifyConfigure(awsConfig, {
        API: {
          REST: {
            headers: async () => ({
              platform: PLATFORM.WEB,
              'x-app-version': version,
            }),
          },
        },
        Auth: {
          credentialsProvider: cognitoCredentialsProvider,
        },
      });

      cognitoUserPoolsTokenProvider.setKeyValueStorage(
        appConfig?.AUTH_STORAGE_TYPE === 'sessionStorage' ? sessionStorage : defaultStorage,
      );

      if (appConfig?.AUTH_STORAGE_TYPE === 'sessionStorage') {
        //remove cognito identity id from local storage to fix race condition due to having same identity on different tabs
        const cognitoIdentityKey = Object.keys(localStorage).find(i =>
          i.startsWith(
            `com.amplify.Cognito.${import.meta.env.VITE_AWS_REGION ?? 'ap-southeast-2'}`,
          ),
        );
        if (cognitoIdentityKey) {
          localStorage.removeItem(cognitoIdentityKey);
        }
      }

      getUserIpAddress();
      setIsInitialised(true);
    })();
  }, [getConfig]);

  return { isInitialised, isUnderMaintenance };
};
