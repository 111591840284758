import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Tab } from '@headlessui/react';

import { Text } from '../text';

export type TabViewProps = {
  items: Array<{
    label: string;
    key: string;
    children: ReactNode;
  }>;
  activeTabClassName?: string;
  inActiveTabClassName?: string;
  bordered?: boolean;
  onChange?: (index: number) => void;
};

export const TabView: FC<TabViewProps> = ({
  items,
  activeTabClassName,
  inActiveTabClassName,
  bordered = true,
  onChange,
}) => (
  <div>
    <Tab.Group onChange={onChange}>
      <Tab.List className="flex">
        {items.map(({ key, label }) => (
          <Tab
            key={key}
            className={({ selected }) =>
              twMerge(
                'border-rain  focus:border-rain w-full rounded-t-md py-4 focus:ring-0',
                selected
                  ? 'border border-b-0 bg-white'
                  : inActiveTabClassName
                  ? `${inActiveTabClassName} hover:bg-snow/80`
                  : 'bg-snow hover:bg-snow/80 border',
                selected && activeTabClassName,
                !bordered && 'border-0',
              )
            }>
            <Text variant="callout-16/bold">{label}</Text>
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className={twMerge('border-rain  border border-t-0 ', !bordered && 'border-0')}>
        {items.map(({ children, key }) => (
          <Tab.Panel key={key} className="focus-visible:outline-mint-100  p-3 ">
            {children}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  </div>
);
