import { Text } from '@components';
import { i18n } from '@i18n';

export const CovidConsentFooter = () => {
  return (
    <div className="px-6 pt-8">
      <Text className="mb-1" variant="body-18/bold">
        {i18n.t('covidConsent.footer.title')}
      </Text>
      <Text variant="footnote-13/regular">{i18n.t('covidConsent.footer.description')}</Text>
    </div>
  );
};
