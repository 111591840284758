import { generateClient } from 'aws-amplify/api';
import { print } from 'graphql';

import { getCognitoJwtToken } from 'freely-shared-utils';

import { GraphqlApiError } from './graphqlApiError';
import * as mutations from './mutations';
import * as queries from './queries';
import * as subscriptions from './subscriptions';

export type OnSubscriptionCallback = (data: any) => void;

const client = generateClient();

type OnSubscriptionError = (
  resubscribe: () => void,
  nextErrorHandler?: OnSubscriptionCallback,
) => OnSubscriptionCallback;
export const handleSubscriptionError: OnSubscriptionError =
  (resubscribe, nextErrorHandler) => data => {
    const errorMessage: string = data.error?.errorMessage;
    if (errorMessage && errorMessage.includes('Socket closed')) {
      resubscribe();
    } else {
      nextErrorHandler && nextErrorHandler(data);
    }
  };

export async function query<T>(queryName: string, input: any) {
  try {
    const authToken = await getCognitoJwtToken();
    const response: any = await client.graphql({
      query: (queries as any)[queryName],
      variables: {
        input,
      },
      authToken,
    });
    return response.data[queryName] as T;
  } catch (ex) {
    throw new GraphqlApiError(
      `Error calling query : ${queryName}`,
      print((queries as any)[queryName]),
      { input },
      ex,
    );
  }
}

export const mutate = async (mutationName: string, input: any) => {
  try {
    const authToken = await getCognitoJwtToken();
    const response: any = await client.graphql({
      query: (mutations as any)[mutationName],
      variables: {
        input,
      },
      authToken,
    });
    return response.data[mutationName];
  } catch (ex) {
    throw new GraphqlApiError(
      `Error calling mutation : ${mutationName}`,
      print((mutations as any)[mutationName]),
      { input },
      ex,
    );
  }
};

export const subscribe = async (
  subscriptionName: string,
  callbacks: { next: OnSubscriptionCallback; error?: OnSubscriptionCallback },
  variables: any,
) => {
  try {
    const authToken = await getCognitoJwtToken();

    if (!authToken) {
      throw Error('Invalid session.');
    }

    return (
      client.graphql({
        query: (subscriptions as any)[subscriptionName],
        variables,
        authToken,
      }) as any
    ).subscribe(callbacks);
  } catch (err) {
    console.log(err);
  }
};
