import AppImage from './images/app-image.png';
import AppStoreBadge from './images/app-store-badge.png';
import EmailQuoteHeaderDesktop from './images/email-quote-header-desktop.png';
import EmailQuoteHeaderMobile from './images/email-quote-header-mobile.png';
import FreelyAppIcon from './images/freely-app-icon.png';
import FreelyAppQR from './images/freely-app-qrcode.png';
import FreelyIphone from './images/freely-iphone.png';
import GooglePlayBadge from './images/google-play-badge.png';
import HomeBackground from './images/home-background.jpg';
import FreelySwitch from './lotties/freely-switch.json';
import AfterPay from './svg/AfterPay.svg';
import ApplePay from './svg/ApplePay.svg';
import ApplePayButtonLogo from './svg/ApplePayButtonLogo.svg';
import GooglePayButtonLogo from './svg/GooglePayButtonLogo.svg';
import AddToAppleWallet from './svg/add-to-apple-wallet.svg';
import Add from './svg/add.svg';
import AfterPayLogo from './svg/after-pay-logo.svg';
import AlertIcon from './svg/alert-icon.svg';
import AlertOutline from './svg/alert-outline.svg';
// Alert is completely different from AlertIcon and AlertOutline
import Alert from './svg/alert.svg';
import AmbulanceNew from './svg/ambulance-new.svg';
import Ambulance from './svg/ambulance.svg';
import Amex from './svg/amex.svg';
import AppleAppStore from './svg/apple-app-store.svg';
import ArrowLeftAlt from './svg/arrow-left-alt.svg';
import ArrowLeft from './svg/arrow-left.svg';
import ArrowRight from './svg/arrow-right.svg';
import ArrowUp from './svg/arrow-up.svg';
import CalendarCancel from './svg/calendar-cancel.svg';
import CalendarCoolOff from './svg/calendar-cool-off.svg';
import CalendarMonth from './svg/calendar-month.svg';
import Calendar from './svg/calendar.svg';
import CallCenter from './svg/call-center.svg';
import CheckCircleOutline from './svg/check-circle-outline.svg';
import CheckCircle from './svg/check-circle.svg';
import CheckGreen from './svg/check-green.svg';
import CheckboxSquareSelected from './svg/checkbox_sqaure_selected.svg';
import CheckboxSquareUnselected from './svg/checkbox_sqaure_unselected.svg';
import ChevronDown from './svg/chevron-down.svg';
import ChevronUp from './svg/chevron-up.svg';
import Claim from './svg/claim.svg';
import CloseCircleLight from './svg/close-circle-light.svg';
import CloseCircleWhite from './svg/close-circle-white.svg';
import Close from './svg/close.svg';
import Covered from './svg/covered.svg';
import Covid from './svg/covid.svg';
import CreditCardError from './svg/credit-card-error.svg';
import CreditCard from './svg/credit-card.svg';
import CustomerSupport from './svg/customer-support.svg';
import Death from './svg/death.svg';
import Delete from './svg/delete.svg';
import DentalNew from './svg/dental-new.svg';
import Dental from './svg/dental.svg';
import Disability from './svg/disability.svg';
import Discount from './svg/discount.svg';
import Discover from './svg/discover.svg';
import Documentation from './svg/documentation.svg';
import Edit from './svg/edit.svg';
import FaceFrown from './svg/face-frown.svg';
import FlagAu from './svg/flag-au.svg';
import FlagUs from './svg/flag-us.svg';
import FreelyPrime from './svg/freely-prime.svg';
import GeoLocation from './svg/geo-location.svg';
import Gift from './svg/gift.svg';
import GooglePay from './svg/google-pay.svg';
import Helicopter from './svg/helicopter.svg';
import Hospital from './svg/hospital.svg';
import InfoCircleBlue from './svg/info-circle-blue.svg';
import InfoCircle from './svg/info-circle.svg';
import Legal from './svg/legal.svg';
import Locked from './svg/locked.svg';
import LuggageDelay from './svg/lugguage-delay.svg';
import Luggage from './svg/lugguage.svg';
import Mandatory from './svg/mandatory.svg';
import Mastercard from './svg/mastercard.svg';
import NotCovered from './svg/not-covered.svg';
import OnePercentPlanet from './svg/one-percent-planet.svg';
import PaymentAfterpay from './svg/payments-afterpay.svg';
import PlaneDeparting from './svg/plane-departing.svg';
import PlaneReturning from './svg/plane-returning.svg';
import Plane from './svg/plane.svg';
import Redeem from './svg/redeem.svg';
import RedirectModal from './svg/redirect-modal.svg';
import Search from './svg/search.svg';
import Star from './svg/star.svg';
import Support from './svg/support.svg';
import ThinArrow from './svg/thin-arrow.svg';
import Toggles from './svg/toggles.svg';
import ToolsCircle from './svg/tools-circle.svg';
import Tools from './svg/tools.svg';
import Verified from './svg/verified.svg';
import Visa from './svg/visa.svg';
import Wallet from './svg/wallet.svg';
import WorldPlane from './svg/world-plane.svg';
import Zurich from './svg/zurich.svg';

export const Assets = {
  FreelyAppIcon,
  Add,
  AddToAppleWallet,
  AfterPay,
  AfterPayLogo,
  Alert,
  AlertIcon,
  AlertOutline,
  Ambulance,
  AmbulanceNew,
  Amex,
  AppImage,
  AppStoreBadge,
  AppleAppStore,
  ApplePay,
  ArrowLeft,
  ArrowLeftAlt,
  ArrowRight,
  ArrowUp,
  Calendar,
  CalendarCancel,
  CalendarCoolOff,
  CalendarMonth,
  CallCenter,
  CheckCircle,
  CheckCircleOutline,
  CheckGreen,
  CheckboxSquareSelected,
  CheckboxSquareUnselected,
  ChevronDown,
  ChevronUp,
  Claim,
  Close,
  CloseCircleLight,
  CloseCircleWhite,
  Covered,
  Covid,
  CreditCardError,
  CreditCard,
  CustomerSupport,
  Death,
  Delete,
  Dental,
  DentalNew,
  Disability,
  Discount,
  Discover,
  Documentation,
  Edit,
  EmailQuoteHeaderDesktop,
  EmailQuoteHeaderMobile,
  FaceFrown,
  FlagAu,
  FlagUs,
  FreelyAppQR,
  FreelyIphone,
  FreelyPrime,
  FreelySwitch,
  GeoLocation,
  Gift,
  GooglePlayBadge,
  Helicopter,
  HomeBackground,
  Hospital,
  InfoCircle,
  InfoCircleBlue,
  Legal,
  Locked,
  Luggage,
  LuggageDelay,
  Mandatory,
  Mastercard,
  NotCovered,
  OnePercentPlanet,
  PaymentAfterpay,
  Plane,
  PlaneDeparting,
  PlaneReturning,
  Redeem,
  RedirectModal,
  Search,
  Star,
  Support,
  ThinArrow,
  Toggles,
  Tools,
  ToolsCircle,
  Verified,
  Visa,
  Wallet,
  WorldPlane,
  Zurich,
  GooglePayButtonLogo,
  ApplePayButtonLogo,
  GooglePay,
} as const;
