import { useCallback } from 'react';

import { ModalProps } from '@components';
import { i18n } from '@i18n';
import { openModalSelector, useModalStore } from '@store';

export const useChooseRegion = () => {
  const openModal = useModalStore(openModalSelector);

  const openRegionModal = useCallback(
    (props?: ModalProps) => {
      openModal('RegionGridModal', {
        title: i18n.t('global.countryOfResidency.modal.title'),
        titleClassName: 'text-left',
        ...props,
      });
    },
    [openModal],
  );

  return { openRegionModal };
};
