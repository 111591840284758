import { FC, useCallback } from 'react';

import { REGIONS } from 'freely-shared-constants';
import { Region } from 'freely-shared-types';

import { i18n } from '@i18n';
import { ROUTES } from '@routes/router';
import { closeModalSelector, useModalStore } from '@store';
import { clearSessionStorage, testProps } from '@utils';

import { Flag } from '../flag';
import { Text } from '../text';

export const RegionGrid: FC = () => {
  const regions = Object.values(REGIONS);
  const closeModal = useModalStore(closeModalSelector);
  const onSelect = useCallback(
    (data: Region) => {
      clearSessionStorage();
      window.location.href = `/${ROUTES.QUOTE}/${data.country.toLowerCase()}`;

      closeModal();
    },
    [closeModal],
  );
  return (
    <div>
      <Text variant="sub-heading-14/medium" className="mb-4">
        {i18n.t('global.countryOfResidency.modal.subtitle')}
      </Text>
      <ul role="list" className="grid grid-cols-2 gap-4">
        {regions.map(({ code, name }) => (
          <li
            key={code}
            className="bg-snow border-rain col-span-1 flex flex-col rounded-md border text-center shadow hover:border-black hover:border-opacity-30">
            <button
              {...testProps(`select-country-button-${name}`)}
              className="flex flex-1 flex-col items-center p-5"
              onClick={() => onSelect({ country: code })}>
              <Text variant="sub-title-20/bold" className="mb-5">
                {name}
              </Text>
              <Flag country={code} className="w-24" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
