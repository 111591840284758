import React from 'react';

import { colors } from 'freely-shared-design';
import { TRIP_STATUS } from 'freely-shared-types';

import { Assets } from '@assets';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { useTripStore } from '@store';

import { Text } from '../../text';

const ConfirmationHeader: React.FC = () => {
  const { openRedirectToFreelyModal } = useNavigateToFreely();
  const tripStatus = useTripStore(state => state.trip.state);
  const isPaid = tripStatus === TRIP_STATUS.PAID;
  return (
    <div className="bg-snow relative">
      <div className="entry-content bg-snow mx-0 flex items-center gap-4 py-4 md:gap-6 md:py-6 lg:mx-auto">
        <button onClick={openRedirectToFreelyModal}>
          <Assets.FreelyPrime
            fill={colors.fuji[800]}
            className=" w-16 md:w-20 xl:absolute xl:top-1/2 xl:left-3.5 xl:translate-y-[-50%]"
          />
        </button>
        {isPaid && (
          <div className="lg:max-w-content flex flex-1 items-center">
            <div className="py-2">
              <Text
                variant={{ sm: 'callout-16/bold', md: 'sub-title-20/bold', lg: 'title-24/bold' }}>
                {i18n.t('checkout.confirmation.header.title')}
                <span className="text-steal">{i18n.t('checkout.confirmation.header.title2')}</span>
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationHeader;
