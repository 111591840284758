import { useConfigStore } from 'freely-shared-stores';
import { hasPreCancellationBoostPrice } from 'freely-shared-utils';

import { Text } from '@components/text';
import { i18n } from '@i18n';
import { useTripStore } from '@store';

export const LegalConsentListItems = () => {
  const isPreTripCancellationEligible = useTripStore(state =>
    hasPreCancellationBoostPrice(state.trip),
  );

  const termsOfUseLink = useConfigStore(state => state?.regionSpecificConfig?.APP_TERMS);

  const legalListItems = i18n.t('checkout.legal', {
    returnObjects: true,
    termsAndConditions: termsOfUseLink,
  });

  const nonEligibleLegalItem =
    !isPreTripCancellationEligible && legalListItems?.find(i => i?.type === 'nonEligible');

  return (
    <ul className="flex-1">
      {nonEligibleLegalItem && (
        <li className="mb-8 first:mt-6 last:mb-6">
          <Text variant={'body-18/bold'} className="mb-2 text-cherry-100" withMarkDown>
            {nonEligibleLegalItem?.title}
          </Text>
          <Text variant={'body-18/medium'} className="mb-2" withMarkDown>
            {nonEligibleLegalItem?.description}
          </Text>
        </li>
      )}
      {legalListItems
        ?.filter(i => !i?.type)
        .map((item, index) => (
          <li key={index} className="mb-8 first:mt-6 last:mb-6">
            <div className="[&_a]:text-fuji-600">
              <Text variant="body-18/medium" withMarkDown>
                {item.title}
              </Text>
            </div>
          </li>
        ))}
    </ul>
  );
};
