import { ComponentProps, useCallback, useMemo } from 'react';

import { useConfigStore } from 'freely-shared-stores';
import { PAYMENT_TYPE, US_PAYMENT_TOKEN_TYPE } from 'freely-shared-types';

import GooglePayButton from '@google-pay/button-react';
import { useCheckoutStore, useGuestStore, useTripStore } from '@store';
import { webEnv } from '@utils';
import { onProcessingPaymentModal, sendAnalyticsEvent, sendAnalyticsUserId } from '@utils';

type GooglePayButtonProps = ComponentProps<typeof GooglePayButton>;

export const useGooglePay = () => {
  const purchaseUsTrip = useTripStore(state => state.purchaseUsTrip);
  const price = useTripStore(state => state.trip?.price);
  const updateTripPolicyStatus = useTripStore(state => state.updateTripPolicyStatus);
  const updateGuest = useGuestStore(state => state.updateGuest);
  const googlePayMerchantId = useConfigStore(state => state.config?.GOOGLE_PAY_MERCHANT_ID);
  const setGoogleCreditCardDetails = useCheckoutStore(state => state.setGoogleCreditCardDetails);
  const isGooglePayFeatureFlagEnabled = useConfigStore(
    state => !!state.regionSpecificConfig?.NATIVE_PAYMENTS?.GOOGLE_PAY,
  );

  const googleCreditDetails = useCheckoutStore(state => state.googleCreditCardDetails);

  const handleGoogleClick = useCallback(
    async (paymentData: google.payments.api.PaymentData) => {
      sendAnalyticsEvent('Google Pay Option Selected');

      // The last 4 digits of the credit card number
      const creditCardNumber = (paymentData as any)?.paymentMethodData?.info?.cardDetails;
      const cardType = (paymentData as any)?.paymentMethodData?.info?.cardNetwork as PAYMENT_TYPE;
      const paymentToken = JSON.parse(
        (paymentData as any)?.paymentMethodData?.tokenizationData?.token as string,
      );
      setGoogleCreditCardDetails({
        cardNumber: creditCardNumber,
        cardType: cardType,
        paymentToken: paymentToken,
      });
    },
    [setGoogleCreditCardDetails],
  );

  const handleGooglePayment = useCallback(async () => {
    updateTripPolicyStatus({ policyStatus: 'NORMAL' });
    sendAnalyticsEvent('Google Pay Button Clicked');

    const guestDetails = await updateGuest();
    sendAnalyticsUserId(guestDetails?.userId ?? null, {
      email: guestDetails?.email?.toLowerCase(),
    });

    await purchaseUsTrip({
      paymentToken: googleCreditDetails?.paymentToken,
      type: googleCreditDetails?.cardType,
      tokenType: US_PAYMENT_TOKEN_TYPE.GOOGLE_PAY,
    });

    onProcessingPaymentModal();
  }, [
    googleCreditDetails?.cardType,
    googleCreditDetails?.paymentToken,
    purchaseUsTrip,
    updateGuest,
    updateTripPolicyStatus,
  ]);

  const googlePayButtonProps: GooglePayButtonProps = useMemo(
    () => ({
      style: { width: '100%' },
      buttonLocale: 'en',
      buttonType: 'buy',
      buttonSizeMode: 'fill',
      environment: webEnv.VITE_ENV === 'prod' ? 'PRODUCTION' : 'TEST',
      paymentRequest: {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX', 'DISCOVER'],
            },
            tokenizationSpecification: {
              type: 'DIRECT',
              parameters: {
                protocolVersion: 'ECv2',
                publicKey: webEnv.VITE_TRAVELX_GOOGLE_PAY_PUBLIC_KEY,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: googlePayMerchantId ?? '',
          merchantName: 'Freely',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: (price / 100).toString(),
          currencyCode: 'USD',
          countryCode: 'US',
        },
      },
      onLoadPaymentData: handleGoogleClick,
    }),
    [googlePayMerchantId, handleGoogleClick, price],
  );

  return {
    googlePayButtonProps,
    isGooglePayAvailable: isGooglePayFeatureFlagEnabled,
    handleGooglePayment,
  };
};
