import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { REGIONS } from 'freely-shared-constants';
import { openApiInstances } from 'freely-shared-open-api';
import {
  getRegionSelector,
  isAddTripEnabledSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { RegionCode } from 'freely-shared-types';
import { logToSentry } from 'freely-shared-utils';

import { Footer, Header, StopSell } from '@components';
import { GetQuoteLoading } from '@components/getQuoteLoading/getQuoteLoading.component';
import { useEmailQuoteReminder, useScrollToTop, useTimeout } from '@hooks';
import { ROUTES, ROUTE_PATHS, navigateToFirstRegionView, router } from '@routes/router';
import {
  getTripSelector,
  useFeatureFlagsStore,
  useGuestStore,
  useModalStore,
  useTripStore,
} from '@store';
import '@store/featureFlags/featureFlags.subscription';
import {
  clearSessionStorage,
  getAnonymousId,
  sendAnalyticsECommerce,
  setClickIdAnonymousId,
} from '@utils';

import { Emc } from '../checkout';

export const Quote = () => {
  useScrollToTop();
  const setRegion = useRegionStore(state => state?.setRegion);
  const getConfig = useConfigStore(state => state?.getConfig);
  const trip = useTripStore(getTripSelector);
  const setIsCreatingOrUpdatingTrip = useTripStore(state => state.setIsCreatingOrUpdatingTrip);
  const isCreatingOrUpdatingTrip = useTripStore(state => state.isCreatingOrUpdatingTrip);
  const resetTripState = useTripStore(state => state.resetTripState);
  const resetGuestState = useGuestStore(state => state.resetGuestState);
  const region = useRegionStore(getRegionSelector);
  const initFeatureFlags = useFeatureFlagsStore(state => state.initFeatureFlags);
  const isAddTripEnable = useConfigStore(isAddTripEnabledSelector);
  const params = useParams();
  const resetModalState = useModalStore(state => state.resetModalState);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isLoadingQuote = useMemo(
    () => location.pathname.endsWith(`/${ROUTES.LOAD_QUOTE}`) && !!searchParams.get('quoteId'),
    [location.pathname, searchParams],
  );
  const shouldDisableWindowScroll = useModalStore(state =>
    state.currentOpenModals.some(it => 'CovidConsent' === it),
  );

  const onClose = () => {
    clearSessionStorage();
    getConfig();
    resetTripState();
    resetModalState();
    resetGuestState();
    window.location.href = `/quote/${params.region}`;
  };
  useTimeout({ onClose });
  useEmailQuoteReminder();

  /**
   * This is to fix the issue with the back button
   * if the user clicks the back button on the browser on to this page it will go back to the start of our app
   */
  useLayoutEffect(() => {
    window.onpopstate = () => {
      if (window.location.pathname === `/${ROUTES.QUOTE}/${region?.country.toLowerCase()}`) {
        router.navigate(-1);
      }
    };
  });

  useLayoutEffect(() => {
    window.onpopstate = e => {
      if (isCreatingOrUpdatingTrip) {
        e.preventDefault();
        router.navigate(1);
        setIsCreatingOrUpdatingTrip(false);
      }
    };
  });

  useLayoutEffect(() => {
    (async () => {
      const paramRegion = params.region?.toUpperCase();
      const config = await getConfig();
      const QuoteApiConfig = config?.web?.OPEN_APIS?.endpoints?.find(it => it.name === 'Quote');
      const regionParam = `/${paramRegion?.toLowerCase() ?? 'au'}`;

      openApiInstances.Quote.defaults.headers.common['x-platform'] = 'web';
      openApiInstances.Quote.defaults.headers.common.platform = 'web';
      const anonymousId = await getAnonymousId();

      for (const key in REGIONS) {
        const regionCode = REGIONS[key as RegionCode].code;

        if (paramRegion === regionCode) {
          setRegion({ country: regionCode });

          if (QuoteApiConfig) {
            openApiInstances.Quote.defaults.baseURL = QuoteApiConfig.endpoint + regionParam;
            openApiInstances.Quote.defaults.headers.common['x-api-key'] = regionParam?.includes(
              'au',
            )
              ? import.meta.env.VITE_AU_OPEN_API_KEY
              : import.meta.env.VITE_US_OPEN_API_KEY;
          }

          if (anonymousId) {
            await initFeatureFlags({
              region: regionCode,
              platform: 'web',
              anonymousId,
              apiKey: import.meta.env.VITE_POSTHOG_API_KEY,
              apiURL: import.meta.env.VITE_POSTHOG_API_HOST,
            }).catch(e => logToSentry(e, { tag: 'initFeatureFlags' }));
          }
        }

        if (!isLoadingQuote) {
          const routePaths = ROUTE_PATHS();
          const baseRoutes = [
            `/${ROUTES.QUOTE}/${regionCode.toLowerCase()}`,
            routePaths.tripDestinations,
            routePaths.tripDates,
            routePaths.tripTravellers,
            routePaths.tripCost,
            routePaths.tripDepositDate,
            routePaths.stateOfResidence,
          ];

          if (baseRoutes.includes(window.location.pathname) || !trip?.sortKey) {
            resetTripState();
            navigateToFirstRegionView();
            clearSessionStorage();
          }
        }
      }
    })();
  }, [
    getConfig,
    initFeatureFlags,
    isLoadingQuote,
    params.region,
    resetTripState,
    setRegion,
    trip?.sortKey,
  ]);

  useEffect(() => {
    sendAnalyticsECommerce('Cart Viewed');
    setClickIdAnonymousId();
  }, []);

  if (!isAddTripEnable) {
    return <StopSell />;
  }

  return (
    <div
      className={twMerge(
        'flex min-h-screen flex-col',
        shouldDisableWindowScroll && 'overflow-hidden max-h-screen',
      )}>
      <Header />
      <Outlet />
      <GetQuoteLoading />
      <Emc />
      <Footer />
    </div>
  );
};
