export enum ERROR_CODE {
  INTERNAL_ERROR = 'internal_error',
  QUOTE_EXPIRED = 'quote_expired',
  QUOTE_ALREADY_PAID = 'quote_already_paid',
  QUOTE_START_DATE_PASSED = 'quote_start_date_passed',
  QUOTE_END_DATE_PASSED = 'quote_end_date_passed',
  QUOTE_ELIGIBILITY_CHANGED = 'quote_eligibility_changed',
  QUOTE_INVALID_STATUS = 'invalid_quote_status',
  QUOTE_NOT_FOUND = 'quote_not_found',
  PAYMENT_IN_PROGRESS = 'payment_in_progress',
}
