import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { Text, TextProps } from '@components';

export type NotificationVariantTypes = 'base' | 'info' | 'error' | 'alert' | 'warning';
export interface NotificationProps {
  variant?: NotificationVariantTypes;
  headingProps: TextProps;
  descriptionProps?: TextProps;
  textContainerStyle?: string;
  className?: string;
  left?: React.ReactElement;
  right?: React.ReactElement;
  iconClassname?: string;
}
const NotificationVariants: Record<
  NotificationVariantTypes,
  { backgroundColor: string; Icon: React.FC<React.SVGProps<SVGElement>>; fill: string }
> = {
  base: {
    backgroundColor: 'bg-snow',
    Icon: Assets.InfoCircleBlue,
    fill: `${colors.snow}`,
  },
  info: {
    backgroundColor: 'bg-mint-10',
    Icon: Assets.InfoCircleBlue,
    fill: `${colors.mint[10]}`,
  },
  error: {
    backgroundColor: 'bg-cherry-10',
    Icon: Assets.AlertIcon,
    fill: `${colors.cherry[100]}`,
  },
  alert: {
    backgroundColor: 'bg-cherry-10',
    Icon: Assets.AlertIcon,
    fill: `${colors.cherry[100]}`,
  },
  warning: {
    backgroundColor: 'bg-sunset-10',
    Icon: Assets.AlertIcon,
    fill: `${colors.sunset[100]}`,
  },
};

export const Notification = ({
  headingProps,
  descriptionProps,
  variant = 'base',
  textContainerStyle,
  iconClassname,
  left,
  right,
  className,
}: NotificationProps) => {
  const { Icon, fill, backgroundColor } = NotificationVariants[variant];
  return (
    <div className={twMerge('flex rounded-xl p-3 my-2', backgroundColor, className)}>
      {left ?? (
        <Icon className={twMerge(iconClassname ?? 'h-4 w-8 sm:w-4 mr-2 mt-0.5')} fill={fill} />
      )}
      <div className={twMerge('gap-2', textContainerStyle)}>
        <Text variant="sub-heading-14/bold" className="text-left pb-1" {...headingProps} />
        {descriptionProps && (
          <Text className="text-left" variant="sub-heading-14/regular" {...descriptionProps} />
        )}
      </div>
      {right}
    </div>
  );
};
