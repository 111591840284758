import { HTMLAttributes, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { convertCentsToDollars } from 'freely-shared-utils';

import { Button, Text, TotalPriceButton } from '@components';
import { AfterPayBanner } from '@components/afterPayBanner';
import { useBoostUpsellFlow, useEmailQuote } from '@hooks';
import { i18n } from '@i18n';
import { ROUTE_PATHS, getCurrentPathname, router } from '@routes/router';
import { useFeatureFlagsStore, useTripStore } from '@store';
import { testProps } from '@utils';

interface TotalPriceAndGroupDiscountProps {
  showBuyNow?: boolean;
  showAfterPay?: boolean;
}

export const TotalPriceAndGroupDiscount = ({
  showBuyNow = false,
  showAfterPay = false,
}: TotalPriceAndGroupDiscountProps) => {
  const totalPrice = useTripStore(state => state?.trip?.price);

  return (
    <div className="flex flex-col gap-4 py-5">
      <TotalPrice />
      {showAfterPay && <AfterPayBanner className="pt-1" price={totalPrice} />}
      {showBuyNow ? <BuyNowButtonSection /> : null}
    </div>
  );
};

const TotalPrice = () => {
  const totalPrice = useTripStore(state => convertCentsToDollars(state?.trip?.price));
  return (
    <div className="flex items-center justify-between py-5 border-y">
      <Text variant="h6-28">{i18n.t('checkout.sideBar.payment.title')}</Text>
      <div className="flex">
        <Text variant="body-18/bold">{i18n.t('checkout.sideBar.payment.currencySign')}</Text>
        <Text variant="h6-28" className="pr-2">
          {totalPrice.replace('$', '')}
        </Text>
      </div>
    </div>
  );
};

export const BuyNowButtonSection = (props: HTMLAttributes<HTMLDivElement>) => {
  const { checkout } = ROUTE_PATHS();
  const currentPathname = getCurrentPathname();

  const emailQuote = useEmailQuote();
  const isEmailQuoteEnabled = useFeatureFlagsStore(
    state => state.featureFlags?.['email-quote']?.isEnabled,
  );

  const button = useMemo(() => {
    if (currentPathname === checkout) {
      return <CheckoutAction />;
    }
    return <MyQuoteAction />;
  }, [checkout, currentPathname]);

  return (
    <div
      {...props}
      className={twMerge(
        'flex-row gap-2 py-3 lg:py-0 sm:flex-row-reverse md:flex-row hidden lg:flex',
        props.className,
      )}>
      {button}
      {isEmailQuoteEnabled && (
        <Button
          {...testProps('email_quote_button')}
          variant="snow"
          className="w-full sm:w-[150px] lg:w-full"
          onClick={() => {
            emailQuote?.openEmailQuoteModal();
          }}>
          <Text variant="sub-heading-14/bold">
            {i18n.t('checkout.text_one_pager_email_quote_btn')}
          </Text>
        </Button>
      )}
    </div>
  );
};

function CheckoutAction() {
  return (
    <div className="w-full sm:max-w-[250px] sm:min-w-[150px] md:max-w-full items-center justify-center flex">
      <TotalPriceButton />
    </div>
  );
}

function MyQuoteAction() {
  const [isLoading, setIsLoading] = useState(false);
  const { boostUpsellFlow } = useBoostUpsellFlow();
  const tripCost = useTripStore(state => state?.trip?.price);
  return (
    <Button
      isLoading={isLoading}
      {...testProps('text_buy_now_button')}
      variant="charcoal"
      onClick={async () => {
        boostUpsellFlow(async () => {
          setIsLoading(true);
          router.navigate(ROUTE_PATHS().checkout);
          setIsLoading(false);
        });
      }}
      className="w-full sm:w-[150px] lg:w-full ">
      <Text variant="sub-heading-14/bold">
        Continue <span className="sm:hidden">for {convertCentsToDollars(tripCost)}</span>
      </Text>
    </Button>
  );
}
