import { useMemo } from 'react';

import { convertCentsToDollars, isBoostAdded } from 'freely-shared-utils';

import { ListItem, Text } from '@components';
import { TotalPriceAndGroupDiscount } from '@components/sideBar/totalPriceAndGroupDiscount';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { useTripStore } from '@store';
import { testProps } from '@utils';

export const BoostSummary = () => {
  const { emcBoost, emcCurrentTraveller } = useEmcTraveller();

  const allAddedBoosts = useTripStore(state =>
    state.trip?.boosts.filter(boost => boost.code !== 'EMC' && isBoostAdded(boost)),
  );

  const duration = useMemo(() => allAddedBoosts[0]?.duration ?? 1, [allAddedBoosts]);

  if (!allAddedBoosts.length) {
    return null;
  }

  return (
    <div>
      <div>
        <Text className="mt-3" variant="title-24/bold">
          {i18n.t('emcShared.screeningResult.summary.additionalPremium')}
        </Text>
        <ListItem
          {...testProps(`boost-item-additional-premium`)}
          backgroundColor="bg-mint-100"
          variant="noIcon"
          titleTextProps={{
            children: i18n.t('emcShared.screeningResult.tripSummary.additionalPremium.title'),
            variant: 'body-18/700',
          }}
          className="mt-3"
          subtitle={i18n.t('emcShared.screeningResult.tripSummary.boost.duration', {
            count: duration,
          })}
          rightText={{ children: convertCentsToDollars(emcCurrentTraveller?.price) }}
          disabled={true}
        />
      </div>
      <div>
        <Text className="mt-3" variant="title-24/bold">
          {i18n.t('checkout.sideBar.paymentSummary.title')}
        </Text>
        {allAddedBoosts.map(boost => (
          <ListItem
            {...testProps(`boost-item-${boost.name}`)}
            key={boost.boostId}
            variant="noIcon"
            titleTextProps={{ children: boost.name, variant: 'body-18/700' }}
            className="mt-3"
            subtitle={i18n.t('emcShared.screeningResult.tripSummary.boost.duration', {
              count: boost?.duration ?? 1,
            })}
            rightText={{ children: convertCentsToDollars(boost.price) }}
            disabled={true}
          />
        ))}
        <ListItem
          {...testProps(`boost-item-${emcBoost?.name}`)}
          variant="noIcon"
          titleTextProps={{
            children: emcBoost?.name,
            variant: 'body-18/700',
          }}
          className="mt-3"
          subtitle={i18n.t('emcShared.screeningResult.tripSummary.boost.duration', {
            count: duration,
          })}
          rightText={{ children: convertCentsToDollars(emcBoost?.price) }}
          disabled={true}
        />
      </div>
      <TotalPriceAndGroupDiscount />
    </div>
  );
};
