import React from 'react';

import { Assets } from '@assets';
import { Text } from '@components';
import { useEmailQuote } from '@hooks';
import { i18n } from '@i18n';

export const EmailQuoteHeader: React.FC = () => {
  const { closeEmailQuoteModal } = useEmailQuote();

  return (
    <div className="relative">
      <img src={Assets.EmailQuoteHeaderMobile} className="object-cover w-full md:hidden" />
      <img src={Assets.EmailQuoteHeaderDesktop} className="object-cover w-full hidden md:block" />
      <div className="absolute top-0 right-0 pt-4 pr-4">
        <button
          tabIndex={-1}
          type="button"
          className="focus:ring-mint-100 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
          onClick={closeEmailQuoteModal}>
          <span className="sr-only">Close</span>
          <Assets.CloseCircleWhite className="w-5 h-5" aria-hidden="true" fill="black" />
        </button>
      </div>
      <Text
        variant="title-28/bold"
        className="text-white absolute whitespace-pre-line w-full px-4 bottom-4 inset-x-0 sm:px-6 md:px-10">
        {i18n.t('emailQuote.header')}
      </Text>
    </div>
  );
};
