import currency from 'currency.js';
import React, { FC } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

import {
  DEFAULT_MAX_TRIP_COST_PER_PERSON,
  DEFAULT_MIN_TRIP_COST_PER_PERSON,
} from 'freely-shared-constants';
import { useConfigStore } from 'freely-shared-stores';
import { TripCostForm } from 'freely-shared-types';
import { validateTripCostButton } from 'freely-shared-utils';

import { getTripSelector, useTripStore } from '@store';

import { Input } from '../input';

interface Props {
  prefixValue?: string;
  isModal?: boolean;
}

export const TripCostInput: FC<Props> = ({ prefixValue, isModal }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<TripCostForm>();
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const trip = useTripStore(getTripSelector);

  const selectedSecondaryTravellerCount =
    trip?.secondaryTravellers.filter(t => t.isSelected).length ?? 0;

  const tripCostMin = React.useMemo(
    () =>
      (regionSpecificConfig?.RULES.TRIP_COST_MIN_PER_PERSON ?? DEFAULT_MIN_TRIP_COST_PER_PERSON) *
      (selectedSecondaryTravellerCount + 1),
    [regionSpecificConfig, selectedSecondaryTravellerCount],
  );
  const tripCostMax = React.useMemo(
    () =>
      (regionSpecificConfig?.RULES.TRIP_COST_MAX_PER_PERSON ?? DEFAULT_MAX_TRIP_COST_PER_PERSON) *
      (selectedSecondaryTravellerCount + 1),
    [regionSpecificConfig, selectedSecondaryTravellerCount],
  );
  const tripCostFormValue = watch('totalTripCost');
  const formTripCost = currency(tripCostFormValue).intValue;
  const tripCostInStore = trip?.totalTripCost ?? 0;
  const totalTripCost = currency(tripCostInStore).intValue;
  const hasTripCostGreaterError = errors.totalTripCost?.message?.includes(
    'Trip cost should be greater',
  );
  const hasValidTripCost = !!errors.totalTripCost?.message && formTripCost !== totalTripCost;
  const hasError = hasTripCostGreaterError || hasValidTripCost;

  return (
    <Controller
      name="totalTripCost"
      control={control}
      rules={{
        validate: {
          max: v =>
            currency(v).value <= tripCostMax ||
            `Trip cost should be less than or equal to ${tripCostMax}.`,
          min: v =>
            currency(v).value >= tripCostMin ||
            `Trip cost should be greater than or equal to ${tripCostMin}.`,
          validateTripCosButton: v =>
            validateTripCostButton({
              formTripCost: v,
              tripCost: trip?.totalTripCost,
              isModal,
            }),
        },
      }}
      render={(props: { field: ControllerRenderProps<TripCostForm, 'totalTripCost'> }) => (
        <Input
          onChange={e => {
            if (e.target.value === prefixValue) {
              props.field.onChange('');
            } else {
              props.field.onChange(e.target.value);
            }
          }}
          inputMode="numeric"
          onBlur={props.field.onBlur}
          value={
            props.field.value.length > 0
              ? prefixValue +
                currency(props.field.value, {
                  symbol: '',
                  precision: 0,
                  separator: ',',
                }).format()
              : ''
          }
          placeholder="Enter your trip cost..."
          hasError={hasError}
          errorMessage={errors.totalTripCost?.message}
        />
      )}
    />
  );
};
