import { CreateStore, createStore } from 'freely-shared-stores';
import { ScreeningQuestions } from 'freely-shared-types';

import { i18n } from '@i18n';
import { TravellerDeclarationError } from '@types';

export type EmcOpen =
  | 'information'
  | 'pre-assessment'
  | 'assessment'
  | 'screening-result'
  | undefined;

export interface EmcState {
  screeningQuestions: ScreeningQuestions;
  openEmcSection: EmcOpen;
  isInfoIcon?: boolean;
  nameChangedTravellers?: string[];
  errorTravellers: TravellerDeclarationError[];
  showEmcErrorsOnContinue?: boolean;
}

export interface EmcActions {
  resetScreeningQuestions: () => void;
  updateScreeningQuestion: (index: number, hasSelectedNo: boolean) => void;
  setOpenEmcSection: (isOpen: EmcOpen, isInfoIcon?: boolean) => void;
  previousEmcStep: () => void;
  setNameChangedTravellers: (nameChangedTraveller: string) => void;
  removeNameChangedTraveller: (nameChangedTraveller: string) => void;
  setErrorTravellers: (errorTravellers: TravellerDeclarationError[]) => void;
  setShowEmcErrorsOnContinue: (showEmcErrorsOnContinue: boolean) => void;
  resetNameChangedTraveller: () => void;
}

export const EMC_STEPS = [
  'information',
  'pre-assessment',
  'assessment',
  'screening-result',
] as const;

export type EmcStore = EmcActions & EmcState;

const screeningQuestions = i18n.t('emcShared.preAssessment.screeningQuestions', {
  returnObjects: true,
});

const screeningQuestionsData: ScreeningQuestions = screeningQuestions.map(question => ({
  question,
  hasSelectedNo: undefined,
  errorType: undefined,
}));

const emcInitialState: EmcState = {
  screeningQuestions: screeningQuestionsData,
  openEmcSection: undefined,
  nameChangedTravellers: [],
  errorTravellers: [],
  showEmcErrorsOnContinue: false,
};

export const emcStore: CreateStore<EmcStore> = set => ({
  ...emcInitialState,
  resetScreeningQuestions: () =>
    set(state => {
      state.screeningQuestions = screeningQuestionsData;
    }),
  updateScreeningQuestion: (index, hasSelectedNo) => {
    set(state => {
      state.screeningQuestions[index].hasSelectedNo = hasSelectedNo;
      state.screeningQuestions[index].errorType = !hasSelectedNo ? 'hasSelectedYes' : undefined;
    });
  },
  setOpenEmcSection: (v, isInfoIcon = false) => {
    set(state => {
      state.openEmcSection = v;
      state.isInfoIcon = isInfoIcon;
    });
  },
  previousEmcStep: () => {
    set(state => {
      const currentIndex = state.openEmcSection ? EMC_STEPS.indexOf(state.openEmcSection) : 0;
      const previousIndex = currentIndex - 1;
      state.openEmcSection = EMC_STEPS?.[previousIndex];
    });
  },
  setNameChangedTravellers: nameChangedTraveller => {
    set(state => {
      // check if traveller already exists in the array
      if (state.nameChangedTravellers?.includes(nameChangedTraveller)) return;
      state.nameChangedTravellers?.push(nameChangedTraveller);
    });
  },
  removeNameChangedTraveller: nameChangedTraveller => {
    set(state => {
      state.nameChangedTravellers = state.nameChangedTravellers?.filter(
        traveller => traveller !== nameChangedTraveller,
      );
    });
  },
  resetNameChangedTraveller: () => {
    set(state => {
      state.nameChangedTravellers = [];
    });
  },
  setErrorTravellers: errorTravellers => {
    set(state => {
      state.errorTravellers = errorTravellers;
    });
  },
  setShowEmcErrorsOnContinue: showEmcErrorsOnContinue => {
    set(state => {
      state.showEmcErrorsOnContinue = showEmcErrorsOnContinue;
    });
  },
});

export const useEmcStore = createStore(emcStore, {
  name: 'useEmcStore',
});
