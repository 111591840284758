import kebabCase from 'lodash/kebabCase';
import { FC } from 'react';

import { getDestinationsLabel } from 'freely-shared-utils';

import { useGetTravellersList } from '@hooks';
import { i18n } from '@i18n';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { getTripSelector, getTripTravelDates, useTripStore } from '@store';
import { testProps } from '@utils';

import { BookingDateListItem } from '../../depositDateListItem';
import { ListItem } from '../../listItem';
import { Text } from '../../text';
import { TripCostListItem } from '../../tripCostListItem';
import { useEditDatesModal } from './useEditDatesModal/useEditDatesModal';
import { useEditDestinationsModal } from './useEditDestinationsModal/useEditDestinationsModal';
import { useEditTravellersModal } from './useEditTravellersModal/useEditTravellersModal';
import { useEditTripCostModal } from './useEditTripCostModal/useEditTripCostModal';

interface TripDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export const TripDetails: FC<TripDetailsProps> = ({ ...rest }) => {
  const trip = useTripStore(getTripSelector);
  const currentPathname = getCurrentPathname();

  const destinationsList = getDestinationsLabel(trip);
  const tripTravelDates = getTripTravelDates(trip);

  const { openEditDestinationsModal } = useEditDestinationsModal();
  const { openEditDatesModal } = useEditDatesModal();
  const { openEditTravellersModal } = useEditTravellersModal();
  const { openEditTripCostModal } = useEditTripCostModal();
  const { getTravellersList } = useGetTravellersList();
  const travellerDetails = getTravellersList();
  const isCheckout = currentPathname === ROUTE_PATHS().checkout;
  const variant = isCheckout ? 'chevron' : 'edit';

  return (
    <div {...rest}>
      <Text variant="title-24/bold">{i18n.t('checkout.sideBar.trip.title')}</Text>
      {destinationsList?.length > 0 && (
        <ListItem
          {...testProps(`trip-destinations-list-${kebabCase(destinationsList)}`)}
          className="mt-3"
          variant={variant}
          titleTextProps={{ children: i18n.t('checkout.sideBar.trip.list1.title') }}
          subtitle={destinationsList}
          onClick={openEditDestinationsModal}
          disabled={isCheckout}
        />
      )}
      {tripTravelDates && (
        <ListItem
          {...testProps(`trip-details-travel-date-${kebabCase(tripTravelDates)}`)}
          className="mt-3"
          variant={variant}
          titleTextProps={{ children: i18n.t('checkout.sideBar.trip.list2.title') }}
          subtitle={tripTravelDates}
          isTimezoneDisplayed
          onClick={openEditDatesModal}
          disabled={isCheckout}
        />
      )}
      {trip?.primaryTraveller && travellerDetails?.length > 0 ? (
        <ListItem
          {...testProps(`trip-details-edit-traveler-button-${travellerDetails.join('-')}`)}
          className="mt-3"
          variant={variant}
          titleTextProps={{ children: i18n.t('checkout.sideBar.trip.list3.title') }}
          subtitle={travellerDetails}
          onClick={openEditTravellersModal}
          disabled={isCheckout}
        />
      ) : null}
      <TripCostListItem variant={variant} onClick={openEditTripCostModal} disabled={isCheckout} />
      <BookingDateListItem variant={variant} disabled={isCheckout} />
    </div>
  );
};
