import { CreateStore, createStore } from 'freely-shared-stores';

export interface Global {
  id: string;
  message: string;
}

export interface GlobalState {
  isCovidConsentAgreed?: boolean;
  isReferralModalClosed?: boolean;
}

export interface GlobalActions {
  setIsCovidConsentAgreed: (isCovidConsentAgreed: boolean) => void;
  setIsReferralModalClosed: (isReferralModalClosed: boolean) => void;
}

export type GlobalStore = GlobalState & GlobalActions;

const initialState: GlobalState = {
  isCovidConsentAgreed: false,
  isReferralModalClosed: false,
};

const globalStore: CreateStore<GlobalStore> = set => ({
  ...initialState,
  setIsCovidConsentAgreed(isCovidConsentAgreed: boolean) {
    set(state => {
      state.isCovidConsentAgreed = isCovidConsentAgreed;
    });
  },
  setIsReferralModalClosed(isReferralModalClosed: boolean) {
    set(state => {
      state.isReferralModalClosed = isReferralModalClosed;
    });
  },
});

export const useGlobalStore = createStore(globalStore, {
  name: 'useGlobalStore',
});
