import isArray from 'lodash/isArray';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { ListItemVariants } from 'freely-shared-types';

import { Assets } from '@assets';
import { i18n } from '@i18n';

import { Loader } from '../loader';
import { Text, TextProps } from '../text';

export interface ListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: ListItemVariants;
  titleTextProps: TextProps;
  subtitle?: string | string[];
  rightText?: TextProps;
  isTimezoneDisplayed?: boolean;
  onClick?: () => Promise<void> | void;
  className?: string;
  leftIcon?: ReactNode;
  backgroundColor?: string;
  disableHover?: boolean;
  rightIconProps?: React.SVGProps<SVGElement>;
  disabled?: boolean;
}

export const ListItem = ({
  variant = 'chevron',
  subtitle,
  rightText,
  isTimezoneDisplayed = false,
  onClick,
  className,
  leftIcon,
  backgroundColor,
  disabled = false,
  disableHover = disabled,
  rightIconProps,
  titleTextProps,
  ...rest
}: ListItemProps) => {
  const hover = !disableHover && 'hover:cursor-pointer hover:opacity-50 ';
  const leftTextContainer =
    variant !== 'edit' ? twMerge('rounded-[0.625rem]', hover) : 'rounded-l-[0.625rem] ';

  const [isLoading, setIsLoading] = useState(false);
  const handleClick = async () => {
    setIsLoading(true);
    await onClick?.();
    setIsLoading(false);
  };

  /**
   * get icons based on variant
   * @param variant
   */
  const getIconVariant = (variant: ListItemVariants) => {
    switch (variant) {
      case 'chevron':
        return (
          <Assets.ArrowRight
            {...rightIconProps}
            className={`m-2 mt-1 h-4 ${rightIconProps?.className}`}
            fill={colors.flint}
            color={colors.flint}
          />
        );
      case 'noIcon':
        return <div className={`m-2 mt-1 h-4 ${rightIconProps?.className}`}> </div>;
      case 'remove':
        return (
          <>
            {isLoading ? (
              <Loader size="sm" />
            ) : (
              <Assets.CloseCircleLight
                {...rightIconProps}
                className={`m-2 mt-0.5 h-5 ${rightIconProps?.className}`}
                fill="gray"
              />
            )}
          </>
        );
      default:
        return (
          <Assets.ChevronDown className="m-2 mt-1 h-4" fill={colors.flint} color={colors.flint} />
        );
    }
  };

  const onItemClick = !disabled && variant !== 'edit' ? handleClick : undefined;

  return (
    <div className={twMerge('relative flex flex-row', className)} {...rest} onClick={onItemClick}>
      {variant !== 'remove' && isLoading && (
        <div
          className={twMerge(
            'bg-snow/40 absolute inset-0 flex   w-full items-center justify-center text-center',
            backgroundColor,
          )}>
          <Loader size="md" />
        </div>
      )}
      <div
        className={twMerge('bg-snow flex flex-auto flex-row ', leftTextContainer, backgroundColor)}>
        <div className="flex flex-auto flex-col justify-center p-5">
          <div className="flex flex-row items-center">
            {leftIcon && <div className="mr-2">{leftIcon}</div>}
            <Text
              variant="sub-title-20/bold"
              className={twMerge('inline-block', subtitle && 'mb-2')}
              {...titleTextProps}
            />
            {isTimezoneDisplayed && (
              <div className="ml-2 mb-2 flex items-center rounded-[0.25rem] bg-white p-1">
                <Text variant="sub-heading-14/medium">
                  {i18n.t('checkout.sideBar.travelDates.timeZone')}
                </Text>
              </div>
            )}
          </div>
          {isArray(subtitle) &&
            subtitle.map((item, index) => (
              <Text key={index} variant="callout-16/500" className="text-charcoal">
                {item}
              </Text>
            ))}
          {subtitle && !isArray(subtitle) && (
            <Text variant="callout-16/500" className="text-charcoal">
              {subtitle}
            </Text>
          )}
        </div>
        {variant !== 'edit' && (
          <div className="flex flex-col justify-center align-middle">
            <div className="flex flex-row justify-center align-middle">
              {rightText && <Text variant="sub-title-20/medium" className="mr-4" {...rightText} />}
              {rightText && getIconVariant(variant)}
            </div>
          </div>
        )}
      </div>
      {variant === 'edit' && (
        <>
          <div className={'flex h-auto border border-white'} />
          <div
            className={twMerge(
              'flex flex-col justify-center rounded-r-[0.625rem] align-middle bg-cabo-50',
              hover,
              backgroundColor,
            )}
            onClick={handleClick}>
            <div className="flex flex-row justify-center p-5 align-middle">
              <Text variant="sub-heading-14/regular" className="text-charcoal  ">
                Edit
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
