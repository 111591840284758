import { Fragment } from 'react';

import { Transition } from '@headlessui/react';

export type SlideModalProps = {
  children: React.ReactNode;
};
export const SlideModal = ({ children }: SlideModalProps) => {
  return (
    <>
      <BackgroundLayer />
      <SlideOverLayer>
        <FadeIn delay="100">{children}</FadeIn>
      </SlideOverLayer>
    </>
  );
};
const BackgroundLayer = () => (
  <Transition.Child
    enter="transition-opacity ease-in-out duration-500"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity ease-in-out duration-500"
    leaveFrom="opacity-100"
    leaveTo="opacity-0">
    <div className="fixed inset-0 bg-black/25 backdrop-blur-md z-20" />
  </Transition.Child>
);

const SlideOverLayer = ({ children }: { children: React.ReactNode }) => (
  <Transition.Child
    as={Fragment}
    enter="transform transition ease-in-out duration-500"
    enterFrom="translate-y-full"
    enterTo="translate-y-0"
    leave="transform transition ease-in-out duration-500 delay-100"
    leaveFrom="translate-x-0"
    leaveTo="translate-y-full">
    <div className="fixed inset-0 overflow-hidden z-30 rounded-2xl">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pt-12">
          <div className="pointer-events-auto w-screen">
            <div
              id="slide-modal-content"
              className="flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white shadow-xl rounded-2xl">
              <div className="h-full px-4 sm:px-6">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition.Child>
);

const FadeIn = ({ delay, children }: { delay: string; children: React.ReactNode }) => (
  <Transition.Child
    enter={`transition-all ease-in-out duration-700 ${delay}`}
    enterFrom="opacity-0 translate-y-6"
    enterTo="opacity-100 translate-y-0"
    leave="transition-all ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0">
    {children}
  </Transition.Child>
);
