import React from 'react';

import { BoostSheetTable, SheetTable } from 'freely-shared-types';

import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';

import { Text } from '../text';

export interface SheetTableListProps {
  item: BoostSheetTable;
}

export const SheetTableList: React.FC<SheetTableListProps> = ({ item }) => {
  const shouldShowDisclaimer = useBoostDetailsStore(state => state?.boostDetails?.code === 'OMD');
  return (
    <div>
      <Text variant="title-24/bold" className="pb-5">
        {item.title}
      </Text>
      {item.tables.length > 0 &&
        item.tables.map((it, index) => (
          <div key={`${it.columnNames.title}${index}`}>
            <SheetTableListItem table={it} />
          </div>
        ))}
      <Text className="my-4" variant="caption-12/400">
        {shouldShowDisclaimer && i18n.t('myQuote.medicalAndDentalDisclaimer')}
      </Text>
    </div>
  );
};
export function SheetTableListItem({ table }: { table: SheetTable }) {
  return (
    <div className="bg-snow mb-6 rounded-2xl px-6 py-3">
      <Text variant="body-18/bold" className="py-2">
        {table.columnNames.title}
      </Text>
      {table.items.length > 0 &&
        table.items.map((it, index, row) => {
          const isLastElementInList = index + 1 === row.length;
          return (
            <TableRow
              child={it.child}
              title={it.title}
              value={it.value}
              key={`${it.title}${it.value}${index}`}
              hasBottomBorder={!isLastElementInList}
            />
          );
        })}
    </div>
  );
}

type TableRowProps = {
  child?: { title: string; value: string };
  title: string;
  value: string;
  hasBottomBorder?: boolean;
};

function TableRow({ title, value, hasBottomBorder = true, child }: TableRowProps) {
  const childRow = child ? (
    <div className="flex flex-row justify-between pb-4">
      <Text variant="callout-16/regular" className="ml-5 italic">{`- ${child.title}`}</Text>
      <Text variant="callout-16/regular">{child.value}</Text>
    </div>
  ) : null;

  return (
    <div className={hasBottomBorder ? 'border-gray border-b-[1px]' : ''}>
      <div className="flex flex-row justify-between py-4">
        <Text variant="callout-16/regular">{title}</Text>
        <Text variant="callout-16/bold">{value}</Text>
      </div>
      {childRow}
    </div>
  );
}
