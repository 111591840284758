import { getRegionState } from 'freely-shared-stores';
import { AnalyticsCommonProps, PLATFORM } from 'freely-shared-types';
import { logToSentry } from 'freely-shared-utils';
import { getQueryString } from 'freely-shared-utils';

import { AnalyticsBrowser } from '@segment/analytics-next';
import { useTripStore } from '@store';
import { WebAnalyticsEvent } from '@types';

// import { getLocalStorageIpAddress } from '@utils';
import { webEnv } from '../envSchema';

const TAG = 'analytics';
const __DEV__ = process.env.NODE_ENV === 'development';
const analytics = AnalyticsBrowser.load({
  writeKey: webEnv.VITE_SEGMENT_WRITE_KEY,
});

/*
    By default Segment will only forward events to Mixpanel
    Enable event forwarding to other destinations by passing a custom options object
    when making a call to sendAnalyticsEvent
     */
const defaultSegmentOptions = {
  integrations: {
    All: true,
    Slack: false,
  },
};

export const sendAnalyticsEvent = <T extends keyof WebAnalyticsEvent['Track']>(
  eventName: T,
  properties?: WebAnalyticsEvent['Track'][T],
  options?: { [key: string]: unknown },
) => {
  try {
    const region = getRegionState();
    const quoteId = useTripStore.getState().trip?.metaData?.quoteId;

    const commonProps: AnalyticsCommonProps = {
      platform: PLATFORM.WEB,
      // ipAddress: getLocalStorageIpAddress(),
      region: region?.country ?? '',
      ...(quoteId && { quoteId: quoteId }),
    };

    analytics
      .track(
        eventName,
        region && region.country ? { ...commonProps, ...properties } : { ...properties },
        { ...defaultSegmentOptions, ...options },
      )
      .catch(e => {
        throw e;
      });

    if (__DEV__) {
      console.log('Segment Track ========>', eventName);
      console.log('Segment Track Properties ========>', properties);
    }
  } catch (e) {
    logToSentry(e as Error, { tag: TAG });
  }
};

export const sendAnalyticsPage = <T extends keyof WebAnalyticsEvent['Page']>(
  eventName: T,
  properties?: WebAnalyticsEvent['Page'][T],
  options?: { [key: string]: unknown },
) => {
  try {
    const region = getRegionState();
    const quoteId = useTripStore.getState().trip?.metaData?.quoteId;

    const commonProps: AnalyticsCommonProps = {
      platform: PLATFORM.WEB,
      // ipAddress: getLocalStorageIpAddress(),
      region: region?.country ?? '',
      ...(quoteId && { quoteId: quoteId }),
    };
    analytics
      .page(
        eventName,
        region && region.country ? { ...commonProps, ...properties } : { ...properties },
        { ...defaultSegmentOptions, ...options },
      )
      .catch(e => {
        throw e;
      });

    if (__DEV__) {
      console.log('Segment Page ========>', eventName);
    }
  } catch (e) {
    logToSentry(e as Error, { tag: TAG });
  }
};

export const sendAnalyticsUserId = (
  userId: string | null,
  properties: { [key: string]: unknown } = {},
  options: { [key: string]: unknown } = defaultSegmentOptions,
) => {
  try {
    const region = getRegionState();
    const quoteId = useTripStore.getState().trip?.metaData?.quoteId;

    const commonProps: AnalyticsCommonProps = {
      // ipAddress: getLocalStorageIpAddress(),
      platform: PLATFORM.WEB,
      region: region?.country ?? '',
      state: region?.state ?? '',
      ...(quoteId && { quoteId: quoteId }),
    };
    analytics
      .identify(
        userId,
        region && region.country ? { ...commonProps, ...properties } : { ...properties },
        options,
      )
      .catch(e => {
        throw e;
      });

    if (__DEV__) {
      console.log('Segment Identify =========>', userId);
    }
  } catch (e) {
    logToSentry(e as Error, { tag: TAG });
  }
};

export const sendAnalyticsECommerce = <T extends keyof WebAnalyticsEvent['Track']>(
  eventName: T,
  properties?: WebAnalyticsEvent['Track'][T],
) => {
  sendAnalyticsEvent(eventName, properties, {
    integrations: {
      All: true,
      Slack: false,
    },
  });
  if (__DEV__) {
    console.log('Segment ECommerce Track ========>', eventName);
  }
};

export const getAnonymousId = async () => {
  try {
    return (await analytics.user())?.anonymousId();
  } catch (e) {
    logToSentry(e as Error, { tag: TAG });
  }
};

export const setClickIdAnonymousId = () => {
  const irclickid = getQueryString('irclickid') ?? '';
  const anonymousId = getQueryString('marketinganonymousid') ?? '';
  if (!irclickid || !anonymousId) return;
  localStorage.setItem('clickId', irclickid);
  localStorage.setItem('marketingAnonymousId', anonymousId);
};

export const getClickIdAnonymousId = () => {
  return {
    clickId: localStorage.getItem('clickId') ?? '',
    anonymousId: localStorage.getItem('marketingAnonymousId') ?? '',
  };
};

export const removeClickIdAnonymousId = () => {
  localStorage.removeItem('clickId');
  localStorage.removeItem('marketingAnonymousId');
};
