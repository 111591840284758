import kebabCase from 'lodash/kebabCase';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { Boost } from 'freely-shared-types';
import { getBoostModalType, isBoostAdded, isPayPerDayBoost } from 'freely-shared-utils';

import { BoostPrice, Button, Notification, Text, TextProps } from '@components';
import { i18n } from '@i18n';
import { testProps } from '@utils';

import { BoostModalHeader } from '../boostModalHeader';

function getBoostButtonTextId(boost: Boost) {
  const modalType = getBoostModalType(boost);
  const showEditBoostText = isBoostAdded(boost);
  switch (modalType) {
    case 'extraCancellation':
      return i18n.t('global.actions.save');
    case 'addSpecifiedItems':
      return showEditBoostText
        ? i18n.t('boostModals.specifiedItems.button')
        : i18n.t('boostDetails.extraCancellationModal.actions.addItem');
    default:
      return i18n.t('boostDetails.extraCancellationModal.actions.addTrip');
  }
}

export interface BoostModalContainerProps {
  children?: React.ReactNode;
  boost?: Boost;
  onSubmit: () => Promise<void> | void;
  titleProps?: TextProps;
  isBoostModalFooterDisabled?: boolean;
}

export function BoostModalContainer({
  onSubmit,
  boost,
  children,
  titleProps,
  isBoostModalFooterDisabled = false,
}: BoostModalContainerProps) {
  const region = useRegionStore(getRegionSelector);
  if (!boost) {
    return null;
  }
  const showPayPerDayNotification = isPayPerDayBoost(boost);
  return (
    <div className="flex flex-1 flex-col pt-4">
      <div className="overflow-y-auto max-h-[70vh] px-4 my-6 no-scrollbar sm:px-8">
        <div>
          <BoostModalHeader boost={boost} />
          {region?.country === 'AU' && showPayPerDayNotification && (
            <Notification
              variant="info"
              headingProps={{
                variant: 'sub-heading-14/regular',
                children:
                  'Great news! You can now add multiple dates, both single and in ranges, to your schedule.',
              }}
            />
          )}
        </div>
        <div>
          <Text
            variant="h4-40"
            {...titleProps}
            className={twMerge('my-2', titleProps?.className)}
          />
          {children}
        </div>
      </div>
      <div className="bg-snow flex flex-row items-center rounded-b-2xl p-8">
        <div className="flex flex-[5] md:flex-[6]">
          {boost && <BoostPrice boost={boost} type="boostModal" />}
        </div>
        <div className="flex flex-[5] justify-end  text-center md:flex-[4]">
          <Button
            {...testProps(`get-boost-modal-button-${kebabCase(boost.name)}-save`)}
            onClick={onSubmit as () => void}
            disabled={isBoostModalFooterDisabled}
            type="submit"
            variant="mint"
            className="w-full">
            {getBoostButtonTextId(boost)}
          </Button>
        </div>
      </div>
    </div>
  );
}
