import React from 'react';

import { REGIONS } from 'freely-shared-constants';

import { Assets } from '@assets';

interface FlagProps {
  country?: keyof typeof REGIONS;
  className?: string;
}

export const Flag: React.FC<FlagProps> = ({ country, className }) => {
  switch (country) {
    case 'AU':
      return <Assets.FlagAu className={className} />;
    case 'US':
      return <Assets.FlagUs className={className} />;
    default:
      return null;
  }
};
