import { useMemo } from 'react';

import { convertCentsToDollars } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { useCheckoutFlow } from '@hooks';
import { i18n } from '@i18n';
import { useCheckoutStore, useTripStore } from '@store';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { onPayPress, testProps } from '@utils';

export const TotalPriceButton = () => {
  const { tripCost, isFreeOfCharge, isTripPending } = useTripStore(state => ({
    tripCost: state?.trip?.price,
    isFreeOfCharge: !!state?.trip?.promotionCode?.isFreeOfCharge,
    isTripPending: state.pending,
  }));

  const { onCheckoutProgress } = useCheckoutFlow();
  const stripe = useStripe();
  const elements = useElements();

  const { paymentSectionType, openSection, isLegalConsentChecked } = useCheckoutStore(state => ({
    paymentSectionType: state?.paymentSectionType,
    openSection: state?.openSection,
    isLegalConsentChecked: state?.isLegalConsentChecked,
  }));

  const onClick = async () => {
    await onCheckoutProgress(undefined, async () => {
      if (
        openSection === 'payment' ||
        (openSection === 'legalStuff' && isLegalConsentChecked && isFreeOfCharge)
      ) {
        const paymentPayload = isFreeOfCharge ? {} : { stripe, elements };
        return await onPayPress(paymentPayload);
      }
    });
  };

  const buttonText = useMemo(() => {
    if (openSection === 'payment') {
      if (paymentSectionType === 'applepay') {
        return (
          <div className="flex flex-row items-center justify-center">
            <Text variant="sub-title-20/regular" className="inline mr-1">
              {i18n.t('checkout.payment.button.payWith')}
            </Text>
            <Assets.ApplePayButtonLogo />
          </div>
        );
      } else if (paymentSectionType === 'googlepay') {
        return <Assets.GooglePayButtonLogo />;
      } else {
        return i18n.t('checkout.payment.button.pay', {
          amount: convertCentsToDollars(tripCost),
        });
      }
    }

    return 'Continue';
  }, [openSection, paymentSectionType, tripCost]);

  return (
    <Button
      {...testProps('text_buy_now_button')}
      variant="charcoal"
      onClick={onClick}
      isLoading={isTripPending}
      className="w-full">
      {typeof buttonText === 'string' ? (
        <Text variant="sub-heading-14/bold">
          {openSection === 'payment'
            ? i18n.t('checkout.payment.button.pay', { amount: convertCentsToDollars(tripCost) })
            : i18n.t('checkout.payment.button.continue')}
        </Text>
      ) : (
        buttonText
      )}
    </Button>
  );
};
