import { useMemo } from 'react';

import {
  EMCCancel,
  EMCCancelModalProps,
  EMCDecline,
  EMCDeclineModalProps,
  EMCRedeclare,
  EMCRedeclareModalProps,
  EMCRejectedEmailAssessment,
} from '@components';

export const fullScreenModalBodyComponents = {
  EMCCancel,
  EMCDecline,
  EMCRedeclare,
  EMCRejectedEmailAssessment,
};

export type FullScreenComponentProps = {
  EMCCancel: EMCCancelModalProps;
  EMCDecline: EMCDeclineModalProps;
  EMCRedeclare: EMCRedeclareModalProps;
  EMCRejectedEmailAssessment: Record<string, unknown>;
};

export type FullScreenModalComponentName = keyof typeof fullScreenModalBodyComponents;

export type FullScreenModalBodyProps<T extends FullScreenModalComponentName> = {
  type: T;
} & FullScreenComponentProps[T];

export function FullScreenModalBody<T extends FullScreenModalComponentName>(
  props: FullScreenModalBodyProps<T>,
) {
  const FullScreenBodyComponent = useMemo(
    () =>
      (fullScreenModalBodyComponents?.[props?.type] as React.FC<FullScreenComponentProps[T]>) ??
      null,
    [props?.type],
  );

  if (!FullScreenBodyComponent) {
    return null;
  }

  return <FullScreenBodyComponent {...(props as FullScreenComponentProps[T])} />;
}
