import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';
import { getDefaultPDSLink } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Button, Container, Text } from '@components';
import { i18n } from '@i18n';
import { router } from '@routes/router';
import {
  closeModalSelector,
  useBoostDetailsStore,
  useGlobalStore,
  useModalStore,
  useTripStore,
} from '@store';
import { getAUCovidConsentType, sendAnalyticsEvent, sendAnalyticsPage, testProps } from '@utils';

export type CovidConsentProps = {
  isModal?: boolean;
};

export const CovidConsent = ({ isModal = false }: CovidConsentProps) => {
  const isTripPending = useTripStore(state => state.pending);
  const isBoostDetailsPending = useBoostDetailsStore(state => state.pending);
  const isPending = isTripPending || isBoostDetailsPending;
  const closeModal = useModalStore(closeModalSelector);
  const setIsCovidConsentAgreed = useGlobalStore(state => state.setIsCovidConsentAgreed);
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));

  const covidConsentNotificationDescription =
    tripType === 'domestic'
      ? i18n.t('covidConsent.covidHighlights.domestic.description')
      : i18n.t('covidConsent.covidHighlights.international.description');

  useEffect(() => {
    sendAnalyticsPage('DFAT Page Viewed');
  }, []);

  const onAgreePress = async () => {
    sendAnalyticsEvent('Covid Consent Agree Clicked');
    setIsCovidConsentAgreed(true);
    if (isModal) {
      closeModal();
    }
  };

  const onBackPress = () => {
    sendAnalyticsEvent('Covid Consent Back Clicked');
    router.navigate(-1);
    if (isModal) {
      closeModal();
    }
  };
  return (
    <Container className="my-0 mx-auto mt-6 max-w-[54rem] md:mx-12 md:mt-12 lg:mx-auto">
      <Text variant="h4-40" className="md:text-center">
        {i18n.t('covidConsent.header')}
      </Text>
      <div className="bg-lawn-70 my-10 flex rounded-[1rem] bg-opacity-20 p-4">
        <div>
          <Assets.CheckGreen width="27px" height="27px" />
        </div>
        <div className="pl-4">
          <Text variant="sub-title-20/bold" className="mb-2">
            {i18n.t('covidConsent.covidHighlights.title')}
          </Text>
          <Text variant="body-18/regular">{covidConsentNotificationDescription}</Text>
        </div>
      </div>
      <div className="md:px-6">
        <Text variant="sub-title-20/bold" className="mb-4">
          {i18n.t('covidConsent.benefits.title')}
        </Text>
        <CovidBenefitList />
        <div className="bg-mint-10 my-8 flex rounded-[1rem] p-4 md:items-center">
          <div>
            <Assets.InfoCircleBlue width="24px" height="24px" />
          </div>
          <Text withMarkDown variant="body-18/regular" className="pl-4">
            {i18n.t('covidConsent.covidInfoNote')}
          </Text>
        </div>
        <div className="mb-2">
          <SpecialExcess />
        </div>
        <div className="mt-4 mb-8">
          <Text withMarkDown variant="body-18/regular">
            {i18n.t('covidConsent.terms', {
              PDS_LINK:
                useConfigStore.getState()?.regionSpecificConfig?.PDS?.CURRENT ??
                getDefaultPDSLink(),
            })}
          </Text>
        </div>
      </div>
      <div className="w-full mt-6 border-t border-rain" />
      <div className="flex  items-center w-full gap-4 py-6">
        <Button
          className="w-full"
          {...testProps('covid-consent-back-button')}
          variant="snow"
          onClick={onBackPress}>
          {i18n.t('covidConsent.buttonActions.back')}
        </Button>
        <Button
          isLoading={isPending}
          {...testProps('covid-consent-agree-and-continue-button')}
          onClick={onAgreePress}
          className="w-full">
          {i18n.t('covidConsent.buttonActions.agreeAndContinue')}
        </Button>
      </div>
    </Container>
  );
};

const listItems = i18n.t('covidConsent.benefits.listItems', {
  lng: 'en-AU',
  returnObjects: true,
});

function CovidBenefitList() {
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));
  const filteredListItems = listItems.filter(item => item.type === tripType);
  const listStyle = tripType === 'domestic' ? 'list-disc' : 'list-decimal';

  if (!filteredListItems.length) {
    return null;
  }

  return (
    <ul className={twMerge('pl-5', listStyle)}>
      {filteredListItems.map(({ text }, index) => (
        <li key={index} className={listStyle}>
          <Text className="mb-4" variant="body-18/regular">
            {text}
          </Text>
        </li>
      ))}
    </ul>
  );
}

const specialExcessContent = i18n.t('covidConsent.specialExcess', {
  lng: 'en-AU',
  returnObjects: true,
  variables: {
    PDS_LINK_CURRENT:
      useConfigStore.getState()?.regionSpecificConfig?.PDS?.CURRENT ?? getDefaultPDSLink(),
  },
});
function SpecialExcess() {
  const tripType = useTripStore(state => getAUCovidConsentType(state?.trip?.tripType));
  return <Text withMarkDown>{specialExcessContent[tripType]}</Text>;
}
