import { PAYMENT_TYPE } from 'freely-shared-types';

/**
 * utility function to mask account number
 * e.g. 123456789 -> XXXXX6789
 * @param accountNumber - string for account number
 * @param visibleDigits - number of digits to be visible
 * @returns masked account number
 */
export const maskNumber = ({
  accountNumber,
  visibleDigits = 4,
}: {
  accountNumber: string;
  visibleDigits: number;
}) => {
  if (visibleDigits < 0) {
    throw new Error('Cannot have visible digits less than 0');
  }

  if (accountNumber.length < visibleDigits) {
    throw new Error('Cannot have visible digits more than account number length');
  }

  // mask all
  if (visibleDigits === 0) {
    return accountNumber.replace(/\d/g, 'X');
  }

  const lastDigits = accountNumber.slice(-visibleDigits);
  const maskedDigits = accountNumber.slice(0, -visibleDigits).replace(/\d/g, 'X');
  return `${maskedDigits}${lastDigits}`;
};

/**
 * utility function to mask credit card number based on its cardType
 * e.g. 123456789 -> 1234 5678 90
 * @param cardType - PAYMENT_TYPE or undefined
 * @returns masked credit card number
 */

type PartialPaymentType = Partial<Record<PAYMENT_TYPE, string>>;

export function formatCardNumberByType(cardType: PAYMENT_TYPE) {
  const cardTypeRules: PartialPaymentType = {
    [PAYMENT_TYPE.AMEX]: '#### ###### #####',
    [PAYMENT_TYPE.DISCOVER]: '#### #### #### #### ###',
    [PAYMENT_TYPE.MASTER_CARD]: '#### #### #### ####',
    [PAYMENT_TYPE.VISA]: '#### #### #### #### ###',
  };
  return cardTypeRules[cardType as PAYMENT_TYPE];
}

export function formatCardNumberByTypeNative(cardType: PAYMENT_TYPE) {
  const cardTypeRules: PartialPaymentType = {
    [PAYMENT_TYPE.AMEX]: '[0000] [000000] [00000]',
    [PAYMENT_TYPE.DISCOVER]: '[0000] [0000] [0000] [0000] [000]',
    [PAYMENT_TYPE.MASTER_CARD]: '[0000] [0000] [0000] [0000]',
    [PAYMENT_TYPE.VISA]: '[0000] [0000] [0000] [0000] [000]',
  };
  return cardTypeRules[cardType as PAYMENT_TYPE];
}
