import { FC, useEffect } from 'react';

import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { i18n } from '@i18n';
import { ROUTE_PATHS, getCurrentPathname, router } from '@routes/router';
import { useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export interface QuoteRetryModalProps {
  children?: React.ReactNode;
}

export const QuoteRetry: FC<QuoteRetryModalProps> = () => {
  const assistanceNumberConfig =
    useConfigStore(state => state.regionSpecificConfig?.CUSTOMER_SERVICE_OVERSEAS_NUMBER) ??
    '+61 2 8907 5085';

  const syncTrip = useTripStore(state => state.syncTrip);

  useEffect(() => {
    sendAnalyticsEvent('Quote API Time Out Modal');
  }, []);

  return (
    <div className="flex flex-col gap-8 items-center max-w-md text-center">
      <Assets.FaceFrown />
      <Text variant="h6-28">{i18n.t('global.errors.quoteRetry.title')}</Text>
      <Text variant="body-18/medium">{i18n.t('global.errors.quoteRetry.description')}</Text>
      <Text variant="body-18/medium" withMarkDown>
        {i18n.t('global.errors.quoteRetry.descriptionTwo', {
          CUSTOMER_SERVICE_OVERSEAS_NUMBER: assistanceNumberConfig,
        })}
      </Text>

      <div className="flex my-2 gap-4 w-full md:w-1/2">
        <Button
          onClick={async () => {
            sendAnalyticsEvent('Quote API Error Modal Try Again');
            const trip = await syncTrip();
            const { myQuote } = ROUTE_PATHS();
            if (trip) {
              if (getCurrentPathname() !== myQuote) {
                // We should navigate to the quote page if we are not already there
                router.navigate(myQuote);
              }
            }
          }}
          className="w-full"
          variant="white">
          Try again
        </Button>
      </div>
    </div>
  );
};
