import React from 'react';

import { Boost, BoostUpsellType } from 'freely-shared-types';
import { getPayPerDayRate, isPayPerDayFree } from 'freely-shared-utils';

import { useBoostSelectionModal, useDisplayToast } from '@hooks';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import {
  closeModalSelector,
  getTripSelector,
  useBoostDetailsStore,
  useModalStore,
  useTripStore,
} from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { BoostPrice } from '../boostPrice';
import { Button } from '../button';
import { Text } from '../text';

export interface UpSellProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  boost: Boost;
  boostUpsellType?: BoostUpsellType;
  onComplete?: () => void;
}
const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
export const UpSell: React.FC<UpSellProps> = ({
  title,
  description,
  boost,
  boostUpsellType,
  onComplete,
  ...rest
}) => {
  const closeModal = useModalStore(closeModalSelector);
  const addBoost = useTripStore(state => state.addBoost);
  const boostId = boost.boostId;
  const trip = useTripStore(getTripSelector);
  const payPerDayRate = getPayPerDayRate(boost);
  const isFreePayPerDay = isPayPerDayFree(trip, boost);
  const isPayPerDayBoost = payPerDayRate || isFreePayPerDay;
  const { displayBoostToast } = useDisplayToast();

  const onDetailsBtnClick = async () => {
    await getBoostDetails(boostId);
    router.navigate(ROUTE_PATHS().boostDetails(boostId));
    closeModal();
  };

  const openBoostSelectionModal = useBoostSelectionModal(boostId);

  /*
   * handles when add to trip is click on second modal popup
   *
   * Note: this callback function is specially used when you have multiple modals to open. If you have only one modal, then this is not required
   *
   * */
  const onSuccess = () => {
    onComplete?.();
    displayBoostToast(boost);
  };

  const addToTrip = async () => {
    sendAnalyticsEvent('Upsell Experiment Accept Clicked', { upsellType: boostUpsellType });
    if (!boostId) {
      return;
    }

    if (!isPayPerDayBoost) {
      const boostResponse = await addBoost({ boostId, updatePayload: {} });
      if (boostResponse) {
        displayBoostToast(boost);
        closeModal();
      }
    } else {
      closeModal();
      openBoostSelectionModal(onSuccess);
    }
  };

  return (
    <div className={'w-full text-left'} {...rest}>
      <Text variant={'h4-40'}>{title}</Text>
      <Text variant={'body-18/500'} className={'pt-5 pb-6'}>
        {description}
      </Text>
      <div className="border-rain mb-12 grid grid-cols-4 flex-col border-y">
        <div className="col-span-3 py-2">
          <Text variant="sub-title-20/bold" className="text-left align-middle">
            {boost.name}
          </Text>
          <BoostPrice boost={boost} type="boostCard" />
        </div>
        <div className="py-5 text-right">
          <div>
            <Button className="w-20 sm:w-24" variant="snow" size="sm" onClick={onDetailsBtnClick}>
              {i18n.t('boostModals.boostUpsell.buttonActions.details')}
            </Button>
          </div>
        </div>
      </div>
      <div className={'flex flex-col space-y-3'}>
        <Button {...testProps('upsell-modal-btn-add')} variant="mint" size="lg" onClick={addToTrip}>
          {i18n.t('boostModals.boostUpsell.buttonActions.addToTrip')}
        </Button>
        <Button
          {...testProps('upsell-modal-btn-no')}
          variant="snow"
          size="lg"
          onClick={() => {
            sendAnalyticsEvent('Upsell Experiment Modal Deny Clicked', {
              upsellType: boostUpsellType,
            });
            closeModal();
            onComplete?.();
          }}>
          {i18n.t('boostModals.boostUpsell.buttonActions.cancel')}
        </Button>
      </div>
    </div>
  );
};
