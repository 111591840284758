import { EmcAccordionItem } from 'freely-shared-types';

type Information = {
  title: string;
  subtitle: string;
  description: string;
  button: string;
  accordionItems: EmcAccordionItem[];
};

const considerExistingMedicalConditions: EmcAccordionItem = {
  title: 'What do we consider an existing medical condition?',
  content: [
    {
      type: 'text',
      text: 'At Freely, an existing medical condition is considered:',
      className: 'mb-5',
    },
    {
      type: 'bullet-list',
      text: 'any physical, mental, or dental conditions for which the traveller(s) have seen a medical professional (such as endometriosis, depression, or gum disease)',
    },
    {
      type: 'bullet-list',
      text: 'any conditions for which the traveller(s) have been receiving ongoing treatment or been prescribed medication (such as back pain, anxiety, or high cholesterol)',
    },
    {
      type: 'bullet-list',
      text: 'any ongoing chronic conditions (such as heart disease, cancer, or diabetes)',
    },
    {
      type: 'bullet-list',
      text: 'any symptoms or signs of illness for which the traveller(s) have not yet sought a medical diagnosis or are currently awaiting a specialist’s opinion (such as chest pain, persistent cough, or unexplained bleeding) and/or',
    },
    {
      type: 'bullet-list',
      text: 'a pregnancy of which the traveller(s) have experienced complications with this or a previous pregnancy, there is a multiple pregnancy (such as twins or triplets), and/or the conception was medically assisted (such as via IVF, fertility treatment, or hormone therapies).',
    },
    {
      type: 'text',
      text: 'Please visit the [PDS]({{PDS_LINK}}) for more details',
      className: 'mt-5',
      isMarkdown: true,
    },
  ],
};
const declareExistingMedicalConditions: EmcAccordionItem = {
  title: 'What happens if I don’t declare any existing medical conditions?',
  content: [
    {
      type: 'bullet-list',
      text: 'If you do not declare any existing condition(s) that aren’t automatically included or meet the criteria for automatic cover, your policy may not include cover for claims relating to – or exacerbated by – any existing condition(s) of the traveller(s) on this policy.',
    },
    {
      type: 'bullet-list',
      text: 'If you (or any other traveller(s) on this policy) are unsure whether you have any existing medical conditions, you should check with your doctor and obtain the names of your conditions (if applicable) before completing the assessment and purchasing a policy.',
    },
  ],
};
const dontPurchaseExistingMedicalConditions: EmcAccordionItem = {
  title: `What happens if I have an existing medical condition but do not take steps to cover my existing medical conditions?  `,
  content: [
    {
      type: 'text',
      text: 'We will not pay any claims which in any way relate to, or are exacerbated by, Your Existing Medical Condition if:',
      className: 'mb-5',
    },
    {
      type: 'bullet-list',
      text: 'You do not apply for this cover for that Existing Medical Condition at the Relevant Time or, at the latest, before you depart on Your Journey',
    },
    {
      type: 'bullet-list',
      text: 'You apply for this cover for that Existing Medical Condition and We do not agree to provide cover; or',
    },
    {
      type: 'bullet-list',
      text: 'We agree to provide cover for that Existing Medical Condition and You do not pay the required extra premium.',
    },
    {
      type: 'text',
      text: 'This means, for example, that You will have to pay for an overseas medical emergency which can be very expensive in some countries.',
      className: 'mt-5',
    },
  ],
};
const automaticallyIncludedExistingMedicalConditions: EmcAccordionItem = {
  title: 'Existing medical conditions automatically included in my policy?',
  content: [
    {
      type: 'text',
      text: 'Several existing medical conditions (listed below with their individual criteria) for no additional premium, are already included in the Explorer plan, provided the traveller meets all the following criteria:',
      className: 'mb-5',
    },
    {
      type: 'bullet-list',
      text: 'All the traveller’s existing medical conditions are on the list',
    },
    {
      type: 'bullet-list',
      text: 'The traveller has not been hospitalised or required treatment from a medical practitioner in the last 12 months for any of the listed conditions',
    },
    {
      type: 'bullet-list',
      text: 'The traveller is not under investigation for any of the listed conditions',
    },
    {
      type: 'bullet-list',
      text: 'The traveller is not awaiting investigation, surgery, treatment, or procedures for any of the listed conditions, and',
    },
    {
      type: 'bullet-list',
      text: 'The traveller’s condition satisfies the relevant criteria for that condition.',
    },
    {
      type: 'text',
      text: 'Still not sure, complete the online medical assessment and declare all conditions, including those automatically included.',
      className: 'mt-5',
    },
    {
      type: 'bold-text',
      text: 'For ease of reference, here are all the existing medical conditions automatically included in the Explorer Plan.',
      className: 'my-5',
    },
    { type: 'table', name: 'Acne', items: [{ type: 'text', text: 'No additional criteria.' }] },
    {
      type: 'table',
      name: 'ADHD <br/>(Attention Deficit Hyperactivity Disorder)',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Allergy',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t required treatment by a medical practitioner for this condition in the last six months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller has no known respiratory conditions (example: asthma).',
        },
      ],
    },
    {
      type: 'table',
      name: 'Anxiety',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t been diagnosed with depression in the last three years.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller’s prescribed medication hasn’t changed in the last 12 months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller isn’t currently waiting to see a mental health clinician (e.g. psychologist or psychiatrist).',
        },
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t previously been required to cancel or curtail their travel plans because of their anxiety.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Asthma',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller is less than 60 years of age when they purchase the policy.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t had an asthma exacerbation requiring treatment by a medical practitioner in the last 12 months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller has been a non-smoker for at least the last 18 months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t need prescribed oxygen outside of a hospital.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t have achronic lung condition or disease (whether chronic or otherwise) including chronic bronchitis, chronic obstructive pulmonary disease (COPD), emphysema, or pulmonary fibrosis.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Autism',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller have not previously been required to cancel or curtail your travel plans due to your Autism.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Bell’s Palsy',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Bunions',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Carpal Tunnel Syndrome',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Cataracts or Glaucoma',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t had an operation for this condition in the last 90 days.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller has no ongoing complications of this condition.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Coeliac Disease',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Congenital Blindness',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Congenital Deafness',
      items: [
        {
          type: 'text',
          text: 'No additional criteria.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Depression',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t been hospitalised for this condition in the last two years.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller’s prescribed medication hasn’t changed in the last 12 months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller isn’t currently waiting to see a mental health clinician (e.g. psychologist or psychiatrist).',
        },
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t previously been required to cancel or curtail their travel plans because of their depression.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Ear Grommets',
      items: [{ type: 'bullet-list', text: 'The traveller doesn’t have an ear infection.' }],
    },
    {
      type: 'table',
      name: 'Epilepsy',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t have an underlying medical condition (e.g. previous head trauma, brain tumour, or stroke).',
        },
      ],
    },
    {
      type: 'table',
      name: 'Gastric Reflux',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller’s gastric reflux doesn’t relate to an underlying diagnosis (e.g. hernia/gastric ulcer). ',
        },
      ],
    },
    { type: 'table', name: 'Goitre', items: [{ type: 'text', text: 'No additional criteria.' }] },
    {
      type: 'table',
      name: `Graves' Disease`,
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    { type: 'table', name: 'Gout', items: [{ type: 'text', text: 'No additional criteria.' }] },
    {
      type: 'table',
      name: 'Hiatus Hernia',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Hip Replacement, Knee Replacement, Shoulder Replacement or Hip Resurfacing',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller’s procedure was performed more than 12 months ago and less than 10 years ago. The traveller hasn’t had any post-operative complications related to that surgery. Post-operative complications include joint dislocation and infection.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Hypercholesterolaemia (High Cholesterol)',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t have a known heart or cardiovascular condition.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Hypertension (High Blood Pressure)',
      items: [
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t have a known heart or cardiovascular condition.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller doesn’t have diabetes (Type I or Type II).',
        },
        {
          type: 'bullet-list',
          text: 'The traveller’s hypertension is stable and managed by their medical practitioner.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller’s prescribed blood pressure medication hasn’t changed in the last 12 months.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller isn’t suffering symptoms of hypertension.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller isn’t having investigations related to blood pressure.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Learning Difficulties',
      items: [
        {
          type: 'text',
          text: 'The traveller has no ongoing complications for this condition.',
        },
      ],
    },
    { type: 'table', name: 'Migraine', items: [{ type: 'text', text: 'No additional criteria.' }] },
    {
      type: 'table',
      name: 'Peptic Ulcer or Gastric Ulcer',
      items: [
        {
          type: 'bullet-list',
          text: 'The peptic/gastric ulcer has been stable in the last 12 months.',
        },
      ],
    },
    {
      type: 'table',
      name: 'Plantar Fasciitis',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Pregnancy',
      items: [
        {
          type: 'bullet-list',
          text: 'Limited automatic cover applies up the 24th week of pregnancy (i.e. 23 weeks and six days). (Please read the PDS for full coverage restrictions, such as, there is no cover for childbirth or costs-related to the health or care of a newborn.)',
        },
        {
          type: 'bullet-list',
          text: 'If the traveller is aware of the pregnancy at the “relevant time”, they will need to apply for cover via our online medical assessment if there have been complications with this pregnancy or a previous pregnancy, they are having a multiple pregnancy (e.g. twins or triplets), and/or the conception was medically assisted (e.g. using assisted fertility treatment including hormone therapies or IVF).',
        },
      ],
    },
    {
      type: 'table',
      name: 'Raynaud’s Disease',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Skin Cancer',
      items: [
        { type: 'bullet-list', text: 'The traveller’s skin cancer isn’t a melanoma.' },
        {
          type: 'bullet-list',
          text: 'The traveller hasn’t had chemotherapy or radiotherapy for this condition.',
        },
        {
          type: 'bullet-list',
          text: 'The traveller’s skin cancer doesn’t require any follow up treatment (e.g. chemotherapy, radiotherapy or further excision).',
        },
      ],
    },
    {
      type: 'table',
      name: 'Stenosing Tenosynovitis (Trigger Finger)',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Urinary Incontinence',
      items: [{ type: 'text', text: 'No additional criteria.' }],
    },
    {
      type: 'table',
      name: 'Underactive Thyroid or Overactive Thyroid',
      items: [
        {
          type: 'bullet-list',
          text: 'The cause of the traveller’s underactive/overactive thyroid wasn’t a tumour.',
        },
      ],
    },
  ],
};

export const emcSharedInformationAU: Information = {
  title: 'Our online medical assessment',
  subtitle: 'How to include existing medical conditions cover in your policy',
  button: 'Continue to pre-assessment',
  description:
    'Your Freely policy provides cover for claims relating to unforeseeable Medical Conditions that may impact your travel.<br/><br/>\nAt Freely, we understand our bodies are complex, holistic systems and that we may have existing medical conditions, not automatically covered by the policy. By simply completing the online medical assessment, we aim to provide the right cover for your trip. If cover is included in the policy, claims related to or exacerbated by the approved existing medical condition(s) can then be covered.<br/><br/>\nLets start.',
  accordionItems: [
    considerExistingMedicalConditions,
    declareExistingMedicalConditions,
    dontPurchaseExistingMedicalConditions,
    automaticallyIncludedExistingMedicalConditions,
  ],
};
