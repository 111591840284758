import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { i18n } from '@i18n';

import { Text } from '../text';

type DateSelectedProps = {
  variant: 'departing' | 'returning';
  dateText: string | undefined;
};

export const DateSelected = ({ variant, dateText }: DateSelectedProps) => {
  const renderIcon =
    variant === 'departing' ? (
      <Assets.PlaneDeparting className="m-2 hidden sm:flex" fill={colors.steal} />
    ) : (
      <Assets.PlaneReturning className="m-2 hidden sm:flex" fill={colors.steal} />
    );
  const label =
    variant === 'departing'
      ? i18n.t('travelDates.dateSelected.departure')
      : i18n.t('travelDates.dateSelected.return');
  const subTitleText = dateText ? dateText : i18n.t('travelDates.dateSelected.notSelected');
  const subTitleStyle = dateText ? 'text-black' : 'text-steal';

  return (
    <div
      className="border-flint flex w-2/5 flex-row items-center rounded-[0.625rem] border border-opacity-40 pl-[1rem] 
    sm:w-56 sm:pl-0">
      {renderIcon}
      <div>
        <Text variant="caption-12/regular">{label}</Text>
        <Text
          variant={{ sm: 'sub-heading-14/700', lg: 'callout-16/bold' }}
          className={subTitleStyle}>
          {subTitleText}
        </Text>
      </div>
    </div>
  );
};
