import { useEffect } from 'react';

import { BOOST_CATEGORY } from 'freely-shared-types';
import { filterIneligiblePreCancellation } from 'freely-shared-utils';

import { BoostListItem, Text } from '@components';
import { i18n } from '@i18n';
import { getFilteredBoostsByCategorySelector, useTripStore } from '@store';

export const ExtraCoverSection = () => {
  const myStuffBoost = useTripStore(getFilteredBoostsByCategorySelector(BOOST_CATEGORY.MY_STUFF));

  // handle scroll to i18Text
  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash === 'extras') {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const extraCoverageText = i18n.t('myQuote.extraCoverage.title');
  // extract text from (here)
  const items = extraCoverageText?.split(/([()])/);
  const title = items?.[0].trim();
  const bracketText = items?.slice(1).join('');

  return (
    <>
      <a id="extras" />
      <div className="w-full my-6 border-t" />
      <div className="flex flex-row flex-wrap items-center mb-2">
        <Text
          variant={{
            sm: 'title-24/bold',
            lg: 'h6-28',
          }}
          className="pr-1 md:pr-2">
          {title}
        </Text>
        <Text
          variant={{
            sm: 'title-24/medium',
            lg: 'title-24/regular',
          }}>
          {bracketText}
        </Text>
      </div>
      <Text variant={{ sm: 'body-18/regular', lg: 'callout-16/regular' }} className="mb-6">
        {i18n.t('myQuote.extraCoverage.subtitle')}
      </Text>
      {myStuffBoost?.filter(filterIneligiblePreCancellation)?.map(it => (
        <BoostListItem boost={it} key={it.boostId} />
      ))}
    </>
  );
};
