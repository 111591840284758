import { get, post } from 'aws-amplify/api';

import { currentCredentials, logToSentry, throwError } from 'freely-shared-utils';

const TAG = 'shared/api/rest/payment';
export const getPaymentIntent = async (arg: { tripId: string; apiVersion: string }) => {
  const identity = await currentCredentials();
  const apiName = 'Payment';
  const ephemeralPath = '/stripeephemeralkey';
  const paymentIntentPath = '/v2/paymentintent';

  try {
    const stripeephemeralkeyResponse = await get({
      apiName,
      path: ephemeralPath,
      options: {
        identity,
        queryParams: {
          api_version: arg.apiVersion,
        },
      } as any,
    }).response;

    const stripeephemeralkey = (await stripeephemeralkeyResponse.body.json()) as any;

    const paymentIntentResponse = await post({
      apiName,
      path: paymentIntentPath,
      options: {
        identity,
        body: { tripId: arg.tripId },
      } as any,
    }).response;
    const paymentIntent = (await paymentIntentResponse.body.json()) as any;

    return { stripeephemeralkey, paymentIntent };
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    return throwError({ error, message: 'Failed to get payment intent. Please try again.' });
  }
};

export const notifyAuthorization = async (arg: { clientSecret: string }) => {
  const identity = await currentCredentials();
  const apiName = 'Payment';
  const path = '/notification';
  const options = {
    identity,
    body: { clientSecret: arg.clientSecret },
  };
  try {
    await post({
      apiName,
      path,
      options,
    });
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    throwError({ error, message: 'Failed to notify authorization. Please try again.' });
  }
};
