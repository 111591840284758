import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { colors } from 'freely-shared-design';
import { regionDateUtils } from 'freely-shared-stores';
import { GuestDetailsForm } from 'freely-shared-types';
import { getEmcBoost, logToSentry } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Button, Text, YesNoRadioButton } from '@components';
import { useEmcTraveller } from '@hooks';
import { useCheckoutFlow } from '@hooks';
import { i18n } from '@i18n';
import {
  getTrip,
  getTripSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useFeatureFlagsStore,
  useTripStore,
} from '@store';
import { hasTravellerErrors, sendAnalyticsEvent, validateEmcTravellers } from '@utils';

import { EmcErrorText } from './emcErrorText.component';

export interface EmcCheckRadioGroupProps {
  firstName?: string;
  lastName?: string;
  sortKey?: string;
  isEmcProceedError?: boolean;
  dob?: string;
}

export const EmcCheckRadioGroup = ({
  firstName,
  lastName,
  sortKey,
  dob,
}: EmcCheckRadioGroupProps) => {
  const trip = useTripStore(getTripSelector);
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  const emcBoost = getEmcBoost(trip);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const { getEmcTravellerById } = useEmcTraveller();
  const hasTravellerInEmcBoostWithSameSortKey = emcBoost?.travellers?.some(
    t => t?.sortKey === sortKey,
  );

  const age = regionDateUtils().getAge(dob ?? '');

  const setErrorTravellers = useEmcStore(state => state.setErrorTravellers);
  const setShowEmcErrorsOnContinue = useEmcStore(state => state.setShowEmcErrorsOnContinue);

  const emcTraveller = getEmcTravellerById(sortKey);

  const { watch } = useFormContext<GuestDetailsForm>();
  const isEmcEnabled = useFeatureFlagsStore(state => state?.featureFlags?.['emc']?.isEnabled);

  // Hides the Start Assessment button
  const [hasAgreed, setHasAgreed] = useState(emcTraveller?.isSelected);
  const { shouldShowPriceModal } = useCheckoutFlow();

  const handleRadioButtonChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!firstName || !lastName || !dob) {
      const errors = validateEmcTravellers(emcBoost, {
        firstName,
        lastName,
        dob,
      } as GuestDetailsForm);
      if (hasTravellerErrors(errors)) {
        e.currentTarget.checked = false;
        setErrorTravellers(errors);
        setShowEmcErrorsOnContinue(true);
        return;
      }
    }

    setHasAgreed(e.target.value === 'Yes');
    setShowEmcErrorsOnContinue(false);
    try {
      await saveScreeningResult({
        isAdded: false,
        isSelected: e.target.value === 'Yes',
        firstName,
        lastName,
        travellerId: sortKey,
        isCompleted: false,
      });
      const response = await getTrip();
      if (response) {
        const emcBoost = getEmcBoost(response);
        const errors = validateEmcTravellers(emcBoost);
        setErrorTravellers(errors);
      }

      sendAnalyticsEvent('EMC Button Clicked', {
        Value: e.target.value as 'Yes' | 'No',
        TravellerID: sortKey ?? '',
      });
    } catch (e) {
      logToSentry(e as Error);
    }
  };

  /*
   BR: Check if the DOB of any primary or secondary traveler has changed.
   If so, display the price change modal.
   For instance, if the DOB of the primary traveler changes and the Start Assessment
   button is clicked for a secondary traveler, trigger the price change modal.
   Otherwise, continue to the EMC flow.
*/

  const handleStartAssessment = useCallback(() => {
    sendAnalyticsEvent('Start assessment button clicked', {
      TravellerID: sortKey ?? '',
    });
    const checkoutResult = shouldShowPriceModal(watch());
    if (checkoutResult === 'priceChangeModal') {
      return;
    }
    setEmcDeclaring({
      boostId: emcBoost?.boostId ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      travellerId: sortKey ?? '',
      age,
    });
    setOpenEmcSection('information');
  }, [
    age,
    emcBoost?.boostId,
    firstName,
    lastName,
    setEmcDeclaring,
    setOpenEmcSection,
    shouldShowPriceModal,
    sortKey,
    watch,
  ]);

  if (!isEmcEnabled || !emcBoost || !hasTravellerInEmcBoostWithSameSortKey) {
    return null;
  }

  return (
    <div className="my-4">
      <div className="flex max-w-[90%] sm:max-w-auto mb-2">
        <Text className="mb-2 " variant="callout-16/400">
          Do you need cover for an Existing {''}
          <span
            onClick={() => {
              setOpenEmcSection('information', true);
            }}
            className="underline text-fuji-600 cursor-pointer hover:text-mint-100">
            Medical Condition?
          </span>
        </Text>
        <Assets.InfoCircle
          className="ml-2 h-4 hover:cursor-pointer inline-block"
          fill={colors.steal}
          onClick={() => setOpenEmcSection('information', true)}
        />
      </div>
      <EmcErrorText sortKey={sortKey} />
      <YesNoRadioButton
        onChange={handleRadioButtonChange}
        id={`emc-for-${firstName}-${lastName}-${sortKey}`}
        name={`emc-for-${firstName}-${lastName}-${sortKey}`}
        hasAgreed={hasAgreed}
      />
      {hasAgreed ? (
        <Button
          withFixedWith
          className="mt-4"
          disabled={!firstName || !lastName || !hasAgreed}
          onClick={handleStartAssessment}>
          {i18n.t('emcShared.global.assessment.button')}
        </Button>
      ) : null}
    </div>
  );
};
