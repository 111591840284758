import { FC, useMemo } from 'react';

import { colors } from 'freely-shared-design';

// Leaving variant as a string for now, but it could be an enum
// At the moment it's only used for mint, but it could be used for other variants such as the green checkmark
export interface CheckboxSVGIconProps {
  variant?: {
    theme?: 'mint' | 'green';
    shape?: 'circle' | 'square';
  };
  isChecked?: boolean;
  className?: string;
  hasError?: boolean;
}

export const CheckboxSVGIcon: FC<CheckboxSVGIconProps> = ({
  variant,
  isChecked = false,
  className,
  hasError,
}) => {
  const theme = useMemo(
    () => getThemeVariant(variant?.theme ?? 'mint', isChecked),
    [variant?.theme, isChecked],
  );

  if (variant?.shape === 'square') {
    return (
      <svg viewBox="0 0 28 29" width="27px" height="27px" className={className}>
        <rect
          x="0.5"
          y="1"
          width="27"
          height="27"
          rx="7.5"
          fill={theme.fillColor}
          stroke={hasError ? colors.cherry[100] : theme.fillStrokeColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.39945 16.194C5.1882 14.9587 7.00507 13.1058 8.21631 14.3411L10.7902 16.966L18.8147 8.78237C20.0259 7.5471 21.7671 9.4 20.6315 10.6353L11.6986 19.6681C11.2444 20.2086 10.4117 20.2858 9.88177 19.7454L6.39945 16.194Z"
          fill={theme.checkColor}
        />
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 24 25" width="27px" height="27px" className={className}>
      <circle cx="12" cy="13" r="11" fill={theme.checkColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12.8105C24 19.4323 18.6217 24.8105 12 24.8105C5.37827 24.8105 0 19.4323 0 12.8105C0 6.18882 5.37827 0.810547 12 0.810547C18.6217 0.810547 24 6.18882 24 12.8105L10.1348 14.427L6.77716 11.0694L4.94368 12.9346L10.1348 18.1574L18.5282 9.76407L16.663 7.89888L10.1348 14.427L24 12.8105Z"
        fill={theme.fillStrokeColor}
      />
    </svg>
  );
};

const getThemeVariant = (theme: string, isChecked: boolean) => {
  switch (theme) {
    case 'mint':
      return {
        fillColor: isChecked ? colors.mint[100] : colors.white,
        fillStrokeColor: isChecked ? colors.mint[100] : colors.steal,
        checkColor: isChecked ? colors.black : colors.white,
      };
    case 'green':
      return {
        fillColor: isChecked ? colors.lawn[100] : colors.white,
        fillStrokeColor: isChecked ? colors.lawn[100] : colors.steal,
        checkColor: colors.white,
      };
    default:
      return {};
  }
};
