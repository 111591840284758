import { AccordionContainer, ExcessAmount, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';

export const Benefits = () => {
  const boostMessage = useBoostDetailsStore(
    state => state.mandatoryBoostDetails?.template?.boostMessage,
  );
  const title = useBoostDetailsStore(state => state.mandatoryBoostDetails?.template?.title);
  return (
    <div>
      <div className="flex flex-row items-center flex-wrap mb-4">
        <Text
          variant={{
            lg: 'h6-28',
            md: 'h6-28',
            sm: 'sub-title-20/bold',
          }}
          className="pr-1 md:pr-2">
          {title ?? ''}
        </Text>
        <Text variant={{ lg: 'title-24/regular', sm: 'callout-16/regular' }}>
          {i18n.t('myQuote.benefitsWhatsIncluded')}
        </Text>
        <ExcessAmount className="hidden md:flex md:ml-auto" />
      </div>
      <Text variant="callout-16/regular" className="mb-4 md:mb-8">
        {boostMessage}
      </Text>
      <ExcessAmount className="mb-8 md:hidden" />
      <AccordionContainer />
    </div>
  );
};
