import { get, put } from 'aws-amplify/api';

import type { Boost, Region } from 'freely-shared-types';
import { currentCredentials, throwError } from 'freely-shared-utils';

export const fetchBoostDetailsById = async (arg: {
  boostId: string;
  region: Region;
  isDefaultBoostTemplate?: boolean;
  queryParams?: Record<string, string>;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/boosts/${arg.boostId}/details`;
  const options = {
    queryParams: arg.queryParams,
  };

  try {
    const result = await get({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({ error, message: 'Getting boost by Id failed. Please try again.' });
  }
};

export const fetchUnauthBoostDetailsById = async (arg: {
  boostId: string;
  region: Region;
  isDefaultBoostTemplate?: boolean;
  queryParams?: Record<string, string>;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/unauth/boosts/${arg.boostId}/details`;

  try {
    const identity = await currentCredentials();
    const options = {
      identity,
      queryParams: arg.queryParams,
    };

    const result = await get({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({ error, message: 'Getting boost by Id failed. Please try again.' });
  }
};

export const updateBoost = async (arg: {
  region: Region;
  boostId: string;
  updatePayload: Partial<Boost>;
  isPaid: boolean;
  queryParams: Record<string, any>;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/boosts/${arg.boostId}/${arg.isPaid ? 'paid' : 'details'}`;

  const options = {
    body: arg.updatePayload,
    queryParams: arg.queryParams,
  } as any;

  try {
    const result = await put({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    return throwError({ error, message: 'Error updating boost. Please try again.' });
  }
};

export const updateUnauthBoost = async (arg: {
  region: Region;
  boostId: string;
  updatePayload: Partial<Boost>;
  isPaid: boolean;
  isDefaultBoostTemplate?: boolean;
  queryParams?: Record<string, string>;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/unauth/boosts/${arg.boostId}/details`;

  try {
    const identity = await currentCredentials();
    const options = {
      body: arg.updatePayload,
      identity,
      queryParams: arg.queryParams,
    } as any;

    const result = await put({
      apiName,
      path,
      options,
    }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    return throwError({ error, message: 'Error updating boost. Please try again.' });
  }
};
