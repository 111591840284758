import { FC } from 'react';

import { PAYMENT_TYPE } from 'freely-shared-types';

import { Assets } from '@assets';

interface CardTypeIconProps {
  cardType?: PAYMENT_TYPE;
  assetHeight?: number;
  assetWidth?: number;
}

export const CardTypeIcon: FC<CardTypeIconProps> = ({
  cardType,
  assetHeight = 72,
  assetWidth = 72,
}) => {
  if (cardType === PAYMENT_TYPE.VISA) {
    return <Assets.Visa height={assetHeight} width={assetWidth} />;
  } else if (cardType === PAYMENT_TYPE.MASTER_CARD) {
    return <Assets.Mastercard width={assetWidth} height={assetHeight} />;
  } else if (cardType === PAYMENT_TYPE.AMEX) {
    return <Assets.Amex width={assetWidth} height={assetHeight} />;
  } else if (cardType === PAYMENT_TYPE.DISCOVER) {
    return <Assets.Discover width={assetWidth} height={assetHeight} />;
  }
  return null;
};
