/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const agentIndividualSubscription = /* GraphQL */ `
  subscription AgentIndividualSubscription($userId: String!) {
    agentIndividualSubscription(userId: $userId) {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on BroadcastEvent {
        message
      }
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
export const permanentSubscriptionByRole = /* GraphQL */ `
  subscription PermanentSubscriptionByRole {
    permanentSubscriptionByRole {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on BroadcastEvent {
        message
      }
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
export const temporarySubscriptionByRole = /* GraphQL */ `
  subscription TemporarySubscriptionByRole {
    temporarySubscriptionByRole {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on BroadcastEvent {
        message
      }
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
export const customerIndividualSubscription = /* GraphQL */ `
  subscription CustomerIndividualSubscription($userId: String!) {
    customerIndividualSubscription(userId: $userId) {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
export const guestSubscription = /* GraphQL */ `
  subscription GuestSubscription($userId: String!) {
    guestSubscription(userId: $userId) {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
export const broadcastSubscription = /* GraphQL */ `
  subscription BroadcastSubscription {
    broadcastSubscription {
      id
      userId
      eventType
      targetRoles
      teamId
      ... on BroadcastEvent {
        message
      }
      ... on HyperMessageUpdateEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
      }
      ... on ChatEvent {
        chatId
        chatStatus
        queueId
        ticketId
        targetAgentId
      }
      ... on TypingEvent {
        isTyping
        sender {
          id
          firstName
          lastName
        }
      }
      ... on AgentAssignedCountEvent {
        assignedCount {
          region
          count
        }
      }
      ... on PermanentRoleEvent {
        unassignedCount {
          region
          count
        }
        teamOpenChatsCount {
          region
          count
        }
      }
      ... on PolicyIssued {
        tripId
        paymentStatus
        policyIssuance
        policyNumber
      }
      ... on OnlineAgentNumberEvent {
        onlineAgentsCount {
          region
          count
        }
      }
      ... on UserEvent {
        isOnline
      }
      ... on IndividualEvent {
        message
      }
      ... on TripUpdatedEvent {
        status
        tripId
      }
      ... on COIUpdated {
        tripId
        presignedCoiLink
      }
      ... on MessageUpdated {
        status
      }
      ... on QueueSettingsEvent {
        queueId
      }
      ... on HyperMessageEvent {
        hyperMessage {
          messageId
          createdAt
          chatId
          ticketId
          hyperMessageType
          ... on TextMessage {
            updateAt
            content
          }
          ... on RatingMessage {
            updateAt
            tags
            addTags
          }
          ... on RiskAlertMessageObject {
            updateAt
            header
            createTs
            riskAlertMessageId
            backgroundUrl
            riskLevel
            title
            text
            countryName
            countryCode
            category
          }
          ... on SystemMessage {
            updateAt
            content
            title
            status
          }
          ... on FileObjectMessage {
            updateAt
            content
            fileName
            s3Path
            fileExtension
            fileSize
            deleted
            deletedTs
            deletedBy
          }
          ... on AlertMessageObject {
            updateAt
            header
            createTs
            backgroundUrl
            alertLevel
            contentTitle
            contentCategory
            contentSummary
            contentText
            contentCTAText
            contentCTAUrl
          }
        }
        queueId
        region
        chatStatus
        targetAgentId
      }
    }
  }
`;
