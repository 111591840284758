const AUTH_KEY = {
  prod: '2IoKDHCewr7HLMyrHnWSV7ozsWDoTJsP5dk3Lk89',
  staging: 'KXQ3wRYVY722BR5MfrUh46RogOGMDiZlaeO6kEoP',
  dev: 'jj653VcTCe8OQNbAzJu0m5LQVi8uOUh14fYh30n6',
} as const;

const BASE_URL = {
  prod: 'https://rest.gofreely.xyz',
  staging: 'https://rest-staging.staging.gofreely.xyz',
  dev: 'https://rest-dev.dev.gofreely.xyz',
} as const;

export const getVeriskConfig = (env: 'dev' | 'staging' | 'prod' = 'dev') => {
  const veriskApiUrl = `https://gateway${
    env !== 'prod' ? 'uat' : ''
  }.verisk.com/rating/au/travel/` as const;

  const baseUrl = BASE_URL[env];

  const authKey = AUTH_KEY[env];

  const tokenUrl = `${baseUrl}/freely-tpi-service/tpi/verisk/temporary-token` as const;

  return {
    veriskApiUrl,
    baseUrl,
    authKey,
    tokenUrl,
  };
};
