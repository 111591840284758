import { FC, useEffect } from 'react';

import { Assets } from '@assets';
import { Button, ModalBodyContainer, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export interface EMCCancelModalProps {
  primaryAction: () => void;
  secondaryAction: () => void;
}

export const EMCCancel: FC<EMCCancelModalProps> = ({ primaryAction, secondaryAction }) => {
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);

  const handleCancel = () => {
    secondaryAction();
    sendAnalyticsEvent('Medical assessment cancelled', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  };

  useEffect(() => {
    sendAnalyticsEvent('Cancel medical assessment modal opened', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  }, []);

  return (
    <ModalBodyContainer
      icon={<Assets.AlertOutline />}
      header={<Text variant="h6-28">{i18n.t('emcShared.global.modal.cancel.title')}</Text>}
      description={
        <Text variant="callout-16/regular">
          {i18n.t('emcShared.global.modal.cancel.description')}
        </Text>
      }
      buttons={
        <div className="flex flex-col w-full md:flex-row my-2 gap-4">
          <Button withFixedWith variant="white" onClick={primaryAction}>
            {i18n.t('emcShared.global.modal.cancel.actions.primary')}
          </Button>
          <Button withFixedWith variant="charcoal" onClick={handleCancel}>
            {i18n.t('emcShared.global.modal.cancel.actions.secondary')}
          </Button>
        </div>
      }
    />
  );
};
