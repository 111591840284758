import { Ref, createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  FontStyleVariants,
  FreelyTypographyBody,
  FreelyTypographyHeading,
  typography,
} from 'freely-shared-design';

import { CustomMarkdown } from '../markdown';

type Size = 'lg' | 'md' | 'sm';
type StringWeight = keyof FontStyleVariants;
type NumberWeight = '400' | '500' | '700';

type Weight = StringWeight | NumberWeight;

export type FontVariant =
  | `${keyof FreelyTypographyBody}/${StringWeight | NumberWeight}`
  | keyof FreelyTypographyHeading;

export interface ResponsiveFontVariant {
  sm: FontVariant;
  md?: FontVariant;
  lg: FontVariant;
}

export interface TextProps extends React.HTMLAttributes<HTMLElement> {
  variant?: FontVariant | ResponsiveFontVariant;
  withMarkDown?: boolean;
  onClick?: (() => Promise<void>) | (() => void);
  useFB5Font?: boolean;
}

const getElementName = (variant?: FontVariant) => {
  if (variant?.startsWith('h0')) {
    return 'h1';
  } else if (variant?.startsWith('h7')) {
    return 'h6';
  } else if (variant?.startsWith('h')) {
    return variant.substring(0, 2);
  } else {
    return 'p';
  }
};
const getWeightName: (weight?: Weight) => StringWeight = weight => {
  if (!weight) {
    return 'bold';
  }
  switch (weight) {
    case '400':
      return 'regular';
    case '500':
      return 'medium';
    case '700':
      return 'bold';
    default:
      return weight;
  }
};

export const Text = forwardRef(
  (
    {
      variant = 'body-18/regular',
      children,
      style,
      withMarkDown,
      className,
      useFB5Font,
      ...rest
    }: TextProps,
    ref: Ref<HTMLElement>,
  ) => {
    const fb5FontStyle = useFB5Font ? { fontFamily: 'Freely-B5', fontWeight: 500 } : {};
    if (typeof variant !== 'object') {
      const fontType = variant.split('/')[0] as keyof FreelyTypographyBody;
      const weight = getWeightName(variant.split('/')[1] as Weight);

      const content = withMarkDown ? (
        <CustomMarkdown>{children as string}</CustomMarkdown>
      ) : (
        children
      );
      const Element = createElement(
        getElementName(variant),
        {
          className,
          style: {
            ...(typography?.[fontType]?.[weight] ?? {}),
            ...style,
            ...fb5FontStyle,
          },
          ref,
          ...rest,
        },
        content as never,
      );
      return Element;
    } else {
      const responsiveClasses = {
        sm: twMerge('md:hidden', className),
        md: twMerge('hidden md:block lg:hidden', className),
        lg: twMerge('hidden lg:block', className),
      };
      const fontType = {
        sm: variant.sm.split('/')[0] as keyof FreelyTypographyBody,
        md: (variant.md ?? variant.lg).split('/')[0] as keyof FreelyTypographyBody,
        lg: variant.lg.split('/')[0] as keyof FreelyTypographyBody,
      };

      const weight = {
        sm: getWeightName(variant.sm.split('/')[1] as Weight),
        md: getWeightName((variant.md ?? variant.lg).split('/')[1] as Weight),
        lg: getWeightName(variant.lg.split('/')[1] as Weight),
      };

      const content = withMarkDown ? (
        <CustomMarkdown>{children as string}</CustomMarkdown>
      ) : (
        children
      );

      const sizes: Size[] = ['sm', 'md', 'lg'];
      const Elements = sizes.map((size: Size) => {
        return createElement(
          getElementName(variant[size]),
          {
            key: size,
            className: responsiveClasses[size],
            style: {
              ...(typography?.[fontType?.[size]]?.[weight?.[size]] ?? {}),
              ...style,
              ...fb5FontStyle,
            },
            ref,
            ...rest,
          },
          content as never,
        );
      });

      return <>{Elements}</>;
    }
  },
);
Text.displayName = 'Text';
