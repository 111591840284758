import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';

export type IconProps = React.SVGProps<SVGElement> & {
  type: 'Add' | 'Close' | 'Edit' | 'CheckCircle' | 'ArrowRight';
  className?: string;
  iconClassName?: string;
} & VariantProps<typeof container>;

const container = cva('h-10 w-10', {
  variants: {
    rounded: {
      true: 'rounded-full',
    },
    fill: {
      undefined: 'bg-none',
      default: 'bg-steal',
      mint: 'bg-mint-100',
      cherry: 'bg-cherry-40',
      snow: 'bg-snow',
      charcoal: 'bg-charcoal',
    },
  },
});

const icon = cva('h-full w-full fill-black p-2');

export const Icon = ({ type, rounded, fill, className, iconClassName, ...rest }: IconProps) => {
  const containerStyle = twMerge(container({ className, rounded, fill }));
  const iconStyle = twMerge(icon({ className: iconClassName }));

  const Component = Assets[type];

  return (
    <div className={containerStyle}>
      <Component className={iconStyle} {...rest} />
    </div>
  );
};
