import React from 'react';

import { useConfigStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { i18n } from '@i18n';

import { Button } from '../button';
import { Text } from '../text';

export const StopSell: React.FC = () => {
  const content = useConfigStore(
    state => state?.regionSpecificConfig?.FEATURE_TOGGLE?.addTrip?.disabledMessage,
  );

  return (
    <div className="bg-mint-100 flex h-screen w-screen flex-col items-center justify-center space-y-7 px-4 text-center">
      <Assets.Tools />
      <Text variant="h4-40">{content?.title}</Text>
      <Text variant="body-18/medium">{content?.subtitle}</Text>
      <div className="max-w-2xl border-y border-black border-opacity-20 p-7 text-center">
        <Text variant="sub-heading-14/medium">{i18n.t('stopSell.emergencyAssistantLabel')}</Text>
        <a href={`tel: ${i18n.t('stopSell.emergencyNumber')}`}>
          <Button variant="white" className="mt-6">
            {i18n.t('stopSell.emergencyNumber')}
          </Button>
        </a>
      </div>
    </div>
  );
};
