import React from 'react';

import { i18n } from '@i18n';
import { testProps } from '@utils';

import { Button } from '../../button';
import { Text } from '../../text';

export interface Props {
  onNext: () => Promise<void> | void;
  isLoading: boolean;
  isNextDisabled: boolean;
}

export const DestinationsFooter: React.FC<Props> = ({ isLoading, isNextDisabled, onNext }) => {
  return (
    <div className="mt-6 flex w-full flex-col items-center gap-4 border-t pt-6">
      <Button
        isJumboSize
        {...testProps('country-destination-select-next-button')}
        variant="mint"
        isLoading={isLoading}
        disabled={isNextDisabled}
        onClick={onNext}>
        Next
      </Button>
      <Text className="leading-5" variant="sub-heading-14/medium" withMarkDown>
        {i18n.t('selectDestinations.footer.note')}
      </Text>
    </div>
  );
};
