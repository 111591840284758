import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Outlet, useBlocker } from 'react-router-dom';

import { Checkbox, LegalConsentListItems, PaymentElement, QuoteFlowContainer } from '@components';
import { useInitialiseTravellerDetailsForm } from '@hooks';
import { i18n } from '@i18n';
import { useCheckoutStore, useEmcStore, useTripStore } from '@store';
import {
  sendAnalyticsEvent,
  sendAnalyticsPage,
  sendAnalyticsTravellerDetailsPageView,
} from '@utils';

import { PrimaryTravellerForm } from '../travellerDetails/primaryTravellerForm.component';
import { SecondaryTravellersForm } from '../travellerDetails/secondaryTravellerForm.component';
import { TravellerDetailsSummary } from '../travellerDetails/travellerDetailsSummary.component';
import { CheckoutSection } from './checkoutSection/checkoutSection';
import { StripePaymentElementProvider } from './payment/stripePaymentElementProvider.component';

export const Checkout = () => {
  const { methods } = useInitialiseTravellerDetailsForm();
  const setHasLegalConsentError = useCheckoutStore(state => state.setHasLegalConsentError);
  const hasLegalConsentError = useCheckoutStore(state => state.hasLegalConsentError);
  const isLegalConsentChecked = useCheckoutStore(state => state.isLegalConsentChecked);
  const setIsLegalConsentChecked = useCheckoutStore(state => state.setIsLegalConsentChecked);
  const hasSecondaryTravellers = useTripStore(state => state.trip.secondaryTravellers.length > 0);

  const isFreeOfCharge = useTripStore(state => !!state.trip.promotionCode?.isFreeOfCharge);
  const [isPrimaryTravellerFormValidated, setIsPrimaryTravellerFormValidated] = useState(false);
  const [areSecondaryTravellersFormValidated, setAreSecondaryTravellersFormValidated] = useState(
    !hasSecondaryTravellers,
  );

  const setErrorTravellers = useEmcStore(state => state.setErrorTravellers);
  const setShowEmcErrorsOnContinue = useEmcStore(state => state.setShowEmcErrorsOnContinue);

  const handleLegalTermsAgreed = useCallback(() => {
    setIsLegalConsentChecked(!isLegalConsentChecked);
    if (!isLegalConsentChecked) {
      sendAnalyticsEvent('Traveller Details Legal Terms Agreed');
      setHasLegalConsentError(false);
    }
  }, [isLegalConsentChecked, setHasLegalConsentError, setIsLegalConsentChecked]);

  const handleLegalTermsViewed = useCallback(() => {
    sendAnalyticsPage('Legal Terms Page Viewed');
  }, []);

  const handlePaymentSectionViewed = useCallback(
    () => sendAnalyticsPage('Payment Page Viewed'),
    [],
  );

  const handleTravellerDetailsSectionViewed = useCallback(() => {
    sendAnalyticsTravellerDetailsPageView();
  }, []);

  useEffect(() => {
    if (isPrimaryTravellerFormValidated && areSecondaryTravellersFormValidated) {
      sendAnalyticsEvent('Traveller details field validated');
    }
  }, [isPrimaryTravellerFormValidated, areSecondaryTravellersFormValidated]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!useEmcStore.getState().openEmcSection && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      useEmcStore.getState().setOpenEmcSection(undefined);
    }
  }, [blocker]);

  useEffect(() => {
    setErrorTravellers([]);
    setShowEmcErrorsOnContinue(false);
  }, [setErrorTravellers, setShowEmcErrorsOnContinue]);

  return (
    <StripePaymentElementProvider>
      <FormProvider {...methods}>
        <QuoteFlowContainer>
          <CheckoutSection
            type="travellerDetails"
            sectionNumber={1}
            title={i18n.t('checkout.title')}
            subtitle={i18n.t('checkout.subtitle')}
            SecondaryComponent={TravellerDetailsSummary}
            onOpen={handleTravellerDetailsSectionViewed}>
            <PrimaryTravellerForm onFormValidate={setIsPrimaryTravellerFormValidated} />
            <SecondaryTravellersForm onFormValidate={setAreSecondaryTravellersFormValidated} />
          </CheckoutSection>

          <CheckoutSection
            type="legalStuff"
            sectionNumber={2}
            onOpen={handleLegalTermsViewed}
            title={i18n.t('checkout.text_checkout_legal_section_title')}
            subtitle={i18n.t('checkout.text_checkout_legal_section_subtitle')}>
            <LegalConsentListItems />
            <Checkbox
              errorTextClassName="ml-0"
              hasError={hasLegalConsentError}
              className="mb-4"
              isChecked={isLegalConsentChecked}
              labelProps={{
                children: i18n.t('checkout.text_checkout_legal_section_checkout_label'),
              }}
              onClick={handleLegalTermsAgreed}
              errorMessage={`Please check the box to confirm that you've read and agree to all the statements above.`}
            />
          </CheckoutSection>

          {!isFreeOfCharge ? (
            <CheckoutSection
              onOpen={handlePaymentSectionViewed}
              type="payment"
              sectionNumber={3}
              title={i18n.t('checkout.text_checkout_payment_section_title')}>
              <PaymentElement />
            </CheckoutSection>
          ) : null}
        </QuoteFlowContainer>
      </FormProvider>
      <Outlet />
    </StripePaymentElementProvider>
  );
};
