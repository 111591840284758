import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { GuestDetailsForm } from 'freely-shared-types';
import { getEmcBoost } from 'freely-shared-utils';

import { Button, Text } from '@components';
import { useCheckoutFlow, useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  getTripSelector,
  useBoostDetailsStore,
  useCheckoutStore,
  useEmcStore,
  useTripStore,
} from '@store';

type EmcConfirmAssessmentProps = {
  travellerId?: string;
  age: number;
  firstName: string;
  lastName: string;
};

function useGetConfirmAssessmentType(travellerId?: string) {
  const { getEmcTravellerById } = useEmcTraveller();
  const emcTraveller = getEmcTravellerById(travellerId);
  const nameChangedTravellers = useEmcStore(state => state.nameChangedTravellers);
  const shouldRedeclare = !!emcTraveller?.requireRedeclare && !!emcTraveller.isCompleted;
  const hasTravellerNameChanged = !!nameChangedTravellers?.includes(travellerId ?? '');

  if (shouldRedeclare) {
    return 'DISCOUNT_CODE_APPLIED_OR_REMOVED';
  }

  if (hasTravellerNameChanged) {
    return 'NAME_CHANGED';
  }

  return null;
}

export const EmcConfirmAssessment = ({
  travellerId,
  age,
  firstName,
  lastName,
}: EmcConfirmAssessmentProps) => {
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  const trip = useTripStore(getTripSelector);
  const emcBoost = getEmcBoost(trip);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const { getEmcTravellerById } = useEmcTraveller();
  const emcTraveller = getEmcTravellerById(travellerId);
  const hasConfirmedAssessment = useCheckoutStore(state => state.hasConfirmedAssessment);
  const setOpenSection = useCheckoutStore(state => state.setOpenSection);
  const confirmationType = useGetConfirmAssessmentType(travellerId);
  const { shouldShowPriceModal } = useCheckoutFlow();
  const { watch, trigger } = useFormContext<GuestDetailsForm>();

  // if requireRedeclare is true, always open traveller details sections
  useEffect(() => {
    if (emcTraveller?.requireRedeclare) {
      setOpenSection('travellerDetails');
    }
  }, [emcTraveller?.requireRedeclare]);

  if (!emcTraveller || !travellerId || !emcBoost || !confirmationType) {
    return null;
  }

  const handleConfirmAssessment = async () => {
    const isValid = await trigger(['firstName', 'lastName'], {
      shouldFocus: true,
    });

    if (!isValid) {
      return;
    }
    const checkoutResult = shouldShowPriceModal(watch());
    if (checkoutResult === 'priceChangeModal') {
      return;
    }

    setEmcDeclaring({
      boostId: emcBoost?.boostId ?? '',
      firstName,
      lastName,
      travellerId: travellerId,
      age,
      screeningData:
        emcTraveller?.decryptedScreeningResult?.[0]?.ScreeningPath?.[0]?.SystemData?.ScreeningData,
      requireReDeclare: true,
    });
    setOpenEmcSection('pre-assessment');
  };
  return (
    <>
      <Button
        withFixedWith
        className={twMerge('mb-4', !hasConfirmedAssessment && 'border-2 border-cherry-100')}
        onClick={handleConfirmAssessment}>
        {i18n.t('emcShared.global.redeclare.button')}
      </Button>
      {(!hasConfirmedAssessment || emcTraveller?.requireRedeclare) && (
        <Text className="mb-4 text-cherry-100" variant="caption-12/400">
          {i18n.t('emcShared.global.redeclare.error')}
        </Text>
      )}
    </>
  );
};
