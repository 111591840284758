export const emcSharedGlobalAU = {
  navigationArrow: {
    information: '',
    'pre-assessment': 'Back to Information',
    assessment: 'Back to Pre-assessment check',
    'screening-result': 'Back to Medical Assessment',
  },
  conditions: {
    covered: {
      title: 'Covered Existing Medical Conditions',
    },
    notCovered: {
      title: 'These existing Medical Conditions are excluded',
    },
    button: {
      title: 'Change',
    },
  },
  modal: {
    cancel: {
      title: 'Are you sure you want to cancel your medical assessment?',
      description: 'The Assessment won’t be saved and you won’t be covered.',
      actions: {
        primary: 'No, go back',
        secondary: 'Yes, cancel',
      },
    },
    decline: {
      title: 'What happens if I decline cover for any approved conditions?',
      description:
        'If you or any other traveller on this policy have existing medical conditions and you choose to decline the cover we offer for any of these condition(s), your policy will not include cover for any claim(s) that arise from these existing medical condition(s). This means you will be responsible for any related expenses incurred pre- and on-trip.',
      actions: {
        primary: 'Continue without cover',
        secondary: 'Go back',
      },
    },
    redeclare: {
      title: 'Are you sure you want to exit?',
      description: `Leaving this page means you won't confirm your medical assessment and you won’t be able to complete your purchase.`,
      actions: {
        primary: 'No, go back',
        secondary: 'Yes, exit',
      },
    },
    rejected: {
      title: 'Would you like a copy of your assessment?',
      description:
        "If you'd like to keep a copy of this medical assessment for future reference, please enter your details below.",
      terms: 'I agree to the [Privacy Policy]({{PRIVACY_POLICY}}) and [Terms of Use]({{TERMS}}).',
      actions: {
        primary: 'Skip',
        secondary: 'Submit',
      },
    },
  },
  assessment: {
    button: 'Start Assessment',
  },
  coverSubtitle: 'Cover for existing medical conditions',
  preAssessmentCheckTitle: 'Pre-Assessment check for ',
  medicalAssessmentTitle: 'Medical Assessment for ',
  contact: {
    mobile: `Need help? <a href="tel:{{customerServiceNumberLink}}">{{customerServiceNumber}}</a>`,
    web: 'Need help? [{{ customerServiceNumber }}](tel:{{customerServiceNumberLink}})',
  },
  web: {
    errors: {
      noData:
        'Please make sure to provide your name, last name, and date of birth above to continue.',
    },
  },
  redeclare: {
    button: 'Confirm Assessment',
    error: 'Please confirm your Existing Medical Condition Assessment to continue.',
  },
  errors: {
    yesAndNoNotSelected:
      'Please let us know if you need cover for any existing medical conditions by either declaring them or indicating "no."',
    requireScreening:
      'Please let us know if you need cover for any existing medical conditions by either declaring them or indicating "no."',
  },
};
