import { i18n } from '@i18n';

import { Button, ButtonProps } from '../button';

export interface FooterButtonGroupProps {
  nextButtonProps: ButtonProps;
  backButtonProps: ButtonProps;
}

export const FooterButtonGroup = ({ backButtonProps, nextButtonProps }: FooterButtonGroupProps) => (
  <div
    className={`${
      nextButtonProps.children === i18n.t('travelDates.modal.actions.update') ||
      nextButtonProps.children === i18n.t('selectDestinations.modal.actions.update')
        ? 'align-middle sm:flex-row sm:place-content-between'
        : null
    } border-rain flex w-full flex-col items-center gap-4 border-t py-6`}>
    <Button
      className="w-full"
      isJumboSize={nextButtonProps.isJumboSize}
      variant="mint"
      size="md"
      {...nextButtonProps}
    />
    <Button className="w-full" variant="snow" size="md" {...backButtonProps} />
  </div>
);
