import React from 'react';

import { colors } from 'freely-shared-design';

import { Text } from '../text';
import './starRating.style.css';

interface StarRatingProps {
  ratingValue: number;
  showValue?: boolean;
  transparentBackground?: boolean;
}

export const StarRating: React.FC<StarRatingProps> = ({
  ratingValue,
  showValue = true,
  transparentBackground = false,
}) => {
  return (
    <div className="flex items-center gap-3">
      <span
        className="star-rating"
        style={
          {
            '--rating': ratingValue,
            '--star-color': transparentBackground ? 'transparent' : colors.rain,
          } as React.CSSProperties
        }
      />
      {showValue && <Text variant="title-24/bold">{ratingValue || 'n/a'}</Text>}
    </div>
  );
};
