import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { isUSRegionSelector, useConfigStore, useRegionStore } from 'freely-shared-stores';
import { GuestDetailsForm, ManualAddressFormInterface } from 'freely-shared-types';
import { constructAddressData, getEmcBoost } from 'freely-shared-utils';

import { i18n } from '@i18n';
import {
  CheckoutSectionType,
  getTripSelector,
  useCheckoutStore,
  useEmcStore,
  useGuestStore,
  useTripStore,
} from '@store';
import { isManualAddressSectionOpenSelector } from '@store/checkout/checkout.selectors';
import { hasTravellerErrors, shouldUpdateTrip, validateEmcTravellers } from '@utils';

import { usePriceChangeModal } from '../usePriceChangeModal';

export const useCheckoutFlow = () => {
  // Trip Store
  const syncTrip = useTripStore(state => state.syncTrip);
  const setTrip = useTripStore(state => state.setTrip);
  const isTripPending = useTripStore(state => state.pending);
  const trip = useTripStore(getTripSelector);

  const isManualAddressSectionOpen = useCheckoutStore(isManualAddressSectionOpenSelector);
  const setGuest = useGuestStore(state => state.setGuest);
  const { openPriceChangeModal } = usePriceChangeModal();
  const countryName = useConfigStore(state => state?.regionSpecificConfig?.NAME) ?? '';
  const countryCode = useConfigStore(state => state?.regionSpecificConfig?.CODE) ?? '';
  const region = useRegionStore(state => state.region);
  const methods = useFormContext();
  const setOpenSection = useCheckoutStore(state => state.setOpenSection);
  const setDisplaySummaryDetails = useCheckoutStore(state => state.setDisplaySummaryDetails);
  const isLegalConsentChecked = useCheckoutStore(state => state.isLegalConsentChecked);
  const openSection = useCheckoutStore(state => state.openSection);
  const setHasLegalConsentError = useCheckoutStore(state => state.setHasLegalConsentError);
  const isStripeFormComplete = useCheckoutStore(state => state.isStripeFormComplete);
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const emcBoost = getEmcBoost(trip);
  const setErrorTravellers = useEmcStore(state => state.setErrorTravellers);
  const nameChangedTravellers = useEmcStore(state => state.nameChangedTravellers);
  const setShowEmcErrorsOnContinue = useEmcStore(state => state.setShowEmcErrorsOnContinue);
  const setHasConfirmedAssessment = useCheckoutStore(state => state.setHasConfirmedAssessment);
  const resetNameChangedTraveller = useEmcStore(state => state.resetNameChangedTraveller);
  const isUS = useRegionStore(isUSRegionSelector);
  const setPaymentErrorMessage = useCheckoutStore(state => state.setPaymentErrorMessage);

  const submitTravellerDetails = useCallback(
    async (values: GuestDetailsForm) => {
      const manualAddress: ManualAddressFormInterface = {
        addressCity: values?.addressCity,
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2?.trim(),
        addressPostCode: values?.addressPostCode,
        addressState: values?.addressState,
      };

      const addressData = isManualAddressSectionOpen
        ? constructAddressData({
            formData: manualAddress,
            countryCode,
            countryName,
            country: region?.country,
          })
        : values?.addressData;
      const diffInTrip = shouldUpdateTrip({ guestDetails: values, trip });
      if (Object.keys(diffInTrip).length > 0) {
        openPriceChangeModal({
          onNext: async () => {
            setTrip({
              ...trip,
              ...(diffInTrip?.primaryTraveller && {
                primaryTraveller: diffInTrip.primaryTraveller,
              }),
              ...(diffInTrip?.secondaryTravellers && {
                secondaryTravellers: diffInTrip.secondaryTravellers,
              }),
            });
            resetNameChangedTraveller();
            addressData && setGuest({ ...values, email: values.email?.toLowerCase(), addressData });
            await syncTrip();
          },
        });
        return 'priceChangeModal';
      }
      addressData && setGuest({ ...values, email: values.email?.toLowerCase(), addressData });
    },
    [
      countryCode,
      countryName,
      isManualAddressSectionOpen,
      openPriceChangeModal,
      region?.country,
      resetNameChangedTraveller,
      setGuest,
      setTrip,
      syncTrip,
      trip,
    ],
  );

  const shouldShowPriceModal = useCallback(
    (values: GuestDetailsForm) => {
      const diffInTrip = shouldUpdateTrip({ guestDetails: values, trip });
      if (Object.keys(diffInTrip).length > 0) {
        openPriceChangeModal({
          onNext: async () => {
            setTrip({
              ...trip,
              ...(diffInTrip?.primaryTraveller && {
                primaryTraveller: diffInTrip.primaryTraveller,
              }),
              ...(diffInTrip?.secondaryTravellers && {
                secondaryTravellers: diffInTrip.secondaryTravellers,
              }),
            });
            setGuest({ ...values, email: values.email?.toLowerCase() });
            resetNameChangedTraveller();
            await syncTrip();
          },
        });
        return 'priceChangeModal';
      }
    },
    [openPriceChangeModal, resetNameChangedTraveller, setGuest, setTrip, syncTrip, trip],
  );

  const onCheckoutProgress = useCallback(
    async (type?: CheckoutSectionType, onValid?: () => void | Promise<void>) => {
      await methods?.handleSubmit(
        async value => {
          if (isTripPending) return;
          const checkoutResult = await submitTravellerDetails(value);

          setDisplaySummaryDetails(true);
          if (checkoutResult === 'priceChangeModal') {
            return;
          }
          // validate yes no button for emc
          const errors = validateEmcTravellers(emcBoost, value);

          if (hasTravellerErrors(errors)) {
            setErrorTravellers(errors);
            setShowEmcErrorsOnContinue(true);
            return;
          }

          //update emc traveller error
          setErrorTravellers(errors);

          //validate confirm assessment
          if (nameChangedTravellers && nameChangedTravellers?.length > 0) {
            setHasConfirmedAssessment(false);
            return;
          }

          if (!isLegalConsentChecked && type !== 'travellerDetails') {
            if (openSection === 'legalStuff') {
              setHasLegalConsentError(true);
            }
            return setOpenSection('legalStuff');
          }

          if (openSection === 'payment' && !isStripeFormComplete && region?.country === 'AU') {
            setPaymentErrorMessage(i18n.t('checkout.payment.error.payment'));
          }

          const sectionToOpen = openSection === 'travellerDetails' ? 'legalStuff' : 'payment';
          if (type === 'payment' || sectionToOpen === 'payment') {
            window.scrollTo({ top: windowHeight * 0.75, behavior: 'smooth' });
          }

          if (type) {
            setOpenSection(type);
          } else {
            setOpenSection(sectionToOpen);
          }
          await onValid?.();
        },
        () => {
          // allows the user to open any section if they have not completed the payment form
          if (isUS && openSection === 'payment') {
            type && setOpenSection(type);
            return;
          }
          setOpenSection(openSection);
          const errors = validateEmcTravellers(emcBoost, methods.watch());
          // If the trip is pending, do not show the emc errors
          if (!isTripPending && hasTravellerErrors(errors)) {
            setErrorTravellers(errors);
            setShowEmcErrorsOnContinue(true);
            return;
          }
        },
      )();
    },
    [
      methods,
      isTripPending,
      submitTravellerDetails,
      setDisplaySummaryDetails,
      emcBoost,
      setErrorTravellers,
      nameChangedTravellers,
      isLegalConsentChecked,
      openSection,
      isStripeFormComplete,
      region?.country,
      setShowEmcErrorsOnContinue,
      setHasConfirmedAssessment,
      setOpenSection,
      setHasLegalConsentError,
      setPaymentErrorMessage,
      windowHeight,
      isUS,
    ],
  );

  return {
    submitTravellerDetails,
    onCheckoutProgress,
    shouldShowPriceModal,
  };
};
