import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { Icon, Text } from '@components';
import { useCheckoutFlow } from '@hooks';
import { CheckoutSectionType, useCheckoutStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export interface CheckoutSectionProps {
  sectionNumber: number;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  type: CheckoutSectionType;
  onOpen?: () => void;
  SecondaryComponent?: React.FC;
}

export const CheckoutSection: React.FC<CheckoutSectionProps> = ({
  sectionNumber,
  title,
  subtitle,
  children,
  type,
  SecondaryComponent,
  onOpen,
}) => {
  const isOpen = useCheckoutStore(state => state.openSection === type);
  const isLegalConsentChecked = useCheckoutStore(state => state.isLegalConsentChecked);

  const { onCheckoutProgress } = useCheckoutFlow();
  //is legal consent valid state and in close state
  const isLegalConsentValid = isLegalConsentChecked && type === 'legalStuff' && !isOpen;
  const isTravellerDetailsValid = type === 'travellerDetails' && !isOpen;

  const setDisplaySummaryDetails = useCheckoutStore(state => state.setDisplaySummaryDetails);
  const setOpenSection = useCheckoutStore(state => state.setOpenSection);

  const handleEditTravellerForm = () => {
    setDisplaySummaryDetails(false);
    setOpenSection('travellerDetails');
    sendAnalyticsEvent('Traveller Details Edit Clicked');
  };

  useEffect(() => {
    if (isOpen) onOpen?.();
  }, [isOpen, onOpen]);

  const handleCheckoutProgress = (type: CheckoutSectionType) => {
    onCheckoutProgress(type);
  };

  const renderButton = (
    <button
      onClick={() => handleCheckoutProgress(type)}
      className={twMerge(
        'w-full mb-4 px-3 rounded-xl bg-gray-100 active:scale-105 duration-400 transition focus:outline-none focus:ring-transparent py-6 hover:opacity-50 active:ring-mint-100  focus:border-transparent focus:outline-0 focus:ring-2',
        isOpen && 'bg-transparent px-0 pb-0 pt-0 active:ring-transparent',
      )}>
      <div className="flex flex-row items-center w-full">
        <div className="flex-none">
          {isLegalConsentValid || isTravellerDetailsValid ? (
            <Icon
              type="CheckCircle"
              className="w-[23px] ml-1 mr-[0.62rem] h-auto"
              iconClassName="p-0 h-[23px]"
            />
          ) : (
            <div
              className={twMerge(
                'h-8 w-8 bg-gray-200 rounded-full mr-4 flex items-center justify-center',
                isOpen && 'bg-mint-100 mr-2',
              )}>
              <Text variant={'callout-16/400'}>{sectionNumber}</Text>
            </div>
          )}
        </div>
        <div className="flex-auto text-left">
          <Text variant={isOpen ? 'title-28/700' : 'sub-title-20/bold'}>{title}</Text>
        </div>
        {isLegalConsentValid && (
          <Icon
            type="ArrowRight"
            className="w-[12px] ml-1 mr-[0.62rem] h-auto flex-none"
            iconClassName="p-0 h-[12px]"
          />
        )}
        {isTravellerDetailsValid && (
          <div className="flex-none pt-1">
            <Text
              variant="footnote-13/400"
              className="underline hover:cursor-pointer"
              onClick={handleEditTravellerForm}>
              Edit
            </Text>
          </div>
        )}
      </div>
      {!isOpen && SecondaryComponent ? (
        <div className="mt-4 ml-[2.5rem]">
          <SecondaryComponent />
        </div>
      ) : null}
    </button>
  );

  const renderOpenSection = (
    <div className={twMerge('mx-1 hidden', isOpen && 'block')}>
      <Text className="mb-8">{subtitle}</Text>
      {children}
    </div>
  );

  return (
    <div>
      {renderButton}
      {renderOpenSection}
    </div>
  );
};
