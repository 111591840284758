import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import { REGIONS } from 'freely-shared-constants';
import {
  ADD_BOOST_LABEL,
  BOOST_CATEGORY,
  BOOST_CODE,
  BOOST_TYPES,
  BOOST_UI_STATUS,
  Boost,
  BoostModalType,
  DATE_TIME_DURATION,
  EmcDataType,
  MotorCycleUpsellRules,
  PrimaryTravellerEligibleParams,
  Region,
  RegionCode,
  RootStackParamList,
  TRIP_STATUS,
  Traveller,
  Trip,
  TripDestination,
  TripDurationParams,
} from 'freely-shared-types';

import { getAge, parseISO } from '../datetime/datetime';
import { combineNames } from '../format/format';
import { getPayPerDayRate, isTripFreeOfCharge } from '../trip/common';

/**
 * Returns an array of all child boosts in the boost list that are dependent on the parent boost
 * @param parentBoost
 * @param boosts
 */
export const getBoostsThatDependOnThisBoost = (parentBoost: Boost, boosts: Boost[]) =>
  boosts.filter(b => b?.boostProperty?.anyDependentBoostIds?.includes(parentBoost.boostId)) ?? [];

/**
 * returns an array of parent boosts that the boost child is dependent on
 * @param childBoost
 * @param boosts
 */
export const getBoostDependencies = (childBoost: Boost, boosts: Boost[]) =>
  childBoost?.boostProperty?.anyDependentBoostIds?.map(id => boosts.find(b => b.boostId === id)) ??
  [];

/**
 * Checks if boost is dependent on a parent boost and will evaluate if it is selected or not
 * If there are no parent, it will default to true
 * @param childBoost
 * @param boosts
 */
export const isParentSelected = (childBoost: Boost, boosts: Boost[]) => {
  if (!childBoost?.boostProperty?.anyDependentBoostIds) {
    return true;
  }

  const parentBoost = boosts.filter(it =>
    childBoost?.boostProperty?.anyDependentBoostIds?.includes(it.boostId),
  );

  return parentBoost?.some(it => it.toUpdate?.isAdded ?? it.isAdded);
};

/**
 * Returns a string of combined dependent boost names
 * @param data
 * @param boosts
 */
export const boostDependencyNames = (data: Boost, boosts: Boost[]) =>
  combineNames(getBoostDependencies(data, boosts), ' or ');

/**
 * Returns a list of all added children of the added parent.
 * @param parentBoost
 * @param boosts
 */
export const getAddedDependentChildrenBoosts = (
  parentBoost: Boost | undefined,
  boosts: Boost[] | undefined,
): Boost[] => {
  const isParentBoostAdded = parentBoost?.toUpdate?.isAdded ?? parentBoost?.isAdded;
  if (!parentBoost || !boosts || !isParentBoostAdded) {
    return [];
  }

  const listOfChildBoosts = getBoostsThatDependOnThisBoost(parentBoost, boosts);
  return listOfChildBoosts.filter(child => {
    return child?.toUpdate?.isAdded ?? child.isAdded;
  });
};
export const getDependentChildren = (parentBoost: Boost, boosts: Boost[]) =>
  getBoostsThatDependOnThisBoost(parentBoost, boosts).filter(
    child =>
      child.isAdded &&
      !getBoostDependencies(child, boosts).some(
        parent => parent?.boostId !== parentBoost.boostId && parent?.isAdded,
      ),
  );

/**
 * Returns a boolean value that represents if the boost has anyDependentBoostIds in the boostProprery
 * attribute
 * @param childBoost
 */
export const hasDependencies = (childBoost: Boost) =>
  (childBoost.boostProperty?.anyDependentBoostIds || []).length > 0;

/**
 * Returns a boolean value representing if the boost is the first dependent in the boost list
 * @param childBoost
 * @param boosts
 */
export const isFirstDependant = (childBoost: Boost, boosts: Boost[]) => {
  const index = boosts.findIndex(({ boostId }) => boostId === childBoost.boostId);

  if (get(boosts, index - 1) && hasDependencies(get(boosts, index - 1)) === false) {
    return true;
  }
  return false;
};

/**
 * returns a boolen value representing if the boost is the last dependent in the boost list
 * @param childBoost
 * @param boosts
 */
export const isLastDependant = (childBoost: Boost, boosts: Boost[]) => {
  const index = boosts.findIndex(({ boostId }) => boostId === childBoost.boostId);

  if (get(boosts, index + 1) && hasDependencies(get(boosts, index + 1)) === false) {
    return true;
  }
  return false;
};

/**
 * Returns a boolean value representing if the boost has been selectively not added
 * @param boost
 */
export const isBoostExcluded = (boost: Boost) => boost.isSelected && !boost.isAdded;

/**
 *Returns a boolean value representing if all available boosts are added and their parent dependencies
 * @param boosts
 */
export const areBoostsSelected = (boosts: Boost[]) =>
  !boosts.some(item => {
    if (!item.isSelected) {
      const dependencies = getBoostDependencies(item, boosts);

      if (dependencies.length === 0 || dependencies.some(dep => dep?.isAdded)) {
        return true;
      }
    }
    return false;
  });

/**
 * Returns the add label for appropriate boosts
 * @param boost
 */
export const getAddBoostLabel = (boost: Boost) => {
  const boostPrice = boost.toUpdate ? boost.toUpdate.adjustedPrice : parseInt(boost.price, 10);

  if (boost?.code === 'LUGG' && boostPrice === 0) {
    return ADD_BOOST_LABEL.ADD_ITEMS;
  }

  // This is for showing 'Select Limit' if the boost is "Extra Cancellation" and there is no price selected.
  if (boost?.code === 'CANX' && boostPrice === 0) {
    return ADD_BOOST_LABEL.SELECT_LIMIT;
  }

  // Show customised label for Snow Sports (SSPFREE) and Cruise Cover (CRSF)
  if ([BOOST_CODE.SNOW_SPORTS, BOOST_CODE.CRUISE_COVER].includes(boost.code as BOOST_CODE)) {
    return ADD_BOOST_LABEL.YES;
  }

  return ADD_BOOST_LABEL.ADD_TO_TRIP;
};

/**
 * Returns the exclude boost label for appropriate boosts
 * @param boost
 */
export const getExcludeBoostLabel = (boost: Boost) => {
  // Show customised label for Snow Sports (SSFREE) and Cruise Cover (CRSF)
  if (['SSFREE', 'CRSF'].includes(boost?.code)) {
    return 'No';
  }
  return 'No Thanks';
};

export const isPayPerDayBoost = (boost?: Boost) => {
  if (boost?.toUpdate) {
    return isNumber(boost?.toUpdate?.boostProperty?.payPerDayRate);
  }

  return isNumber(boost?.boostProperty?.payPerDayRate);
};

/**
 * Returns true if the boost has mutually exclusive ids
 * @param selectedBoost
 * @param boostList
 */
export const doesBoostHaveMutuallyExclusiveIds = (
  selectedBoost?: Boost,
  boostList?: Array<Boost>,
) => {
  return (
    Array.isArray(selectedBoost?.boostProperty?.mutuallyExclusiveBoostIds) &&
    (selectedBoost?.boostProperty?.mutuallyExclusiveBoostIds?.length ?? 0) > 0 &&
    boostList
  );
};

/**
 * Decides on what success or failure callback to run based on the trips current screen
 * @param region
 * @param trip
 * @param onSuccess
 * @param onFailure
 */
export const complianceCheck = ({
  region,
  trip,
  onSuccess,
  onFailure,
}: {
  region: Region;
  trip: Trip;
  onSuccess: () => void;
  onFailure: (boostCategory: BOOST_CATEGORY | undefined) => void;
}) => {
  if (region.country === REGIONS.AU.code || region.country === REGIONS.US.code) {
    switch (trip?.currentScreen) {
      case BOOST_CATEGORY.BOOST_SUMMARY:
        return onSuccess();
      case BOOST_CATEGORY.MY_ACTIVITIES:
        return onSuccess();
      case BOOST_CATEGORY.MY_HEALTH:
        return onFailure(BOOST_CATEGORY.MY_HEALTH);
      case BOOST_CATEGORY.MY_STUFF:
        return onFailure(BOOST_CATEGORY.MY_STUFF);
      default:
        return onFailure(BOOST_CATEGORY.MY_HEALTH);
    }
  }
};

/**
 * Returns a boolean value if the input dates affect the original boost pay per day dates
 * @param tripStartDate
 * @param tripEndDate
 * @param editingBoost
 * @param country
 */
export const isPayPerDayDurationAffected = (
  {
    tripStartDate,
    tripEndDate,
    country,
  }: { tripStartDate: string; tripEndDate: string; country?: RegionCode },
  editingBoost: Boost,
) => {
  for (const dateRange of editingBoost.startEndDates) {
    const isPayPerDayStartDateIncluded =
      dateRange.startDate &&
      parseISO(dateRange.startDate, country) >= parseISO(tripStartDate, country);

    const isPayPerDayEndDateIncluded =
      dateRange.endDate && parseISO(dateRange.endDate, country) <= parseISO(tripEndDate, country);

    const result = !(isPayPerDayStartDateIncluded && isPayPerDayEndDateIncluded);

    if (result) {
      return true;
    }
  }

  return false;
};

/**
 *Returns a boolean representing if the boost is added.
 * @param boost
 */
export const isBoostAdded = (boost?: Boost) => {
  return !!(boost?.toUpdate?.isAdded ?? boost?.isAdded);
};

/**
 * Returns a boolean value representing if the child boost parents are added.
 * If they are not added it will return true
 * @param data
 * @param boosts
 */
export const isDisabled = (data: Boost, boosts: Boost[]) => {
  const dependencies = getBoostDependencies(data, boosts);
  return (
    dependencies.length > 0 &&
    !dependencies.some(parent => parent?.toUpdate?.isAdded ?? parent?.isAdded)
  );
};

/**
 * Returns boolean value representing if any parent of the child boost is toggling
 * @param data
 * @param boosts
 */
export const isParentBoostToggling = (data: Boost, boosts: Boost[]) => {
  const dependencies = getBoostDependencies(data, boosts);
  return dependencies.length > 0 && dependencies.some(parent => !!parent?.toggleStatus);
};

/**
 * Returns boolean value representing if any child of the parent boost is toggling
 * @param data
 * @param boosts
 */
export const isChildBoostToggling = (data: Boost, boosts: Boost[]) => {
  const dependencies = getBoostsThatDependOnThisBoost(data, boosts);
  return dependencies.length > 0 && dependencies.some(child => !!child?.toggleStatus);
};

/**
 * Returns boolean value representing if this boost is Eligible
 * @param boost
 * @param region
 */
export const isBoostEligible = (boost?: Boost, region?: Region | null) => {
  if (!boost) return false;
  if (region && region.country === REGIONS.US.code) {
    return boost.isEligible;
  } else {
    return true;
  }
};

/**
 * Returns true if boost toggleIsDisabled. For extra cancellation AU region.
 * @param data
 * @param trip
 * @param isAU
 */
export const isBoostToggleDisabled = (
  data: Boost | undefined,
  trip: Trip | null,
  isAU: boolean,
) => {
  //CANX cannot be remove once customer paid for it
  return trip?.state === TRIP_STATUS.PAID && data?.code === 'CANX' && isAU;
};

/**
 * Returns the boost coverAmountValues
 * If coverAmountValues does not exist, it will return undefined.
 * @param boost
 */
export const getBoostExtraCoverValues = (boost?: Boost) =>
  boost?.toUpdate
    ? boost?.toUpdate?.boostProperty?.coverAmountValues
    : boost?.boostProperty?.coverAmountValues;

/**
 * Returns the boost selectedCoverAmountValues
 * If coverAmountValues does not exist, it will return undefined.
 * @param boost
 */
export const getBoostSelectedExtraCover = (boost?: Boost) =>
  boost?.toUpdate
    ? boost?.toUpdate?.boostProperty?.selectedExtraCover
    : boost?.boostProperty?.selectedExtraCover;

/**
 * Returns an array of specified items if exists in the boost.
 * If specified items does not exist, it will return undefined
 * @param boost
 */
export const getBoostSpecifiedItems = (boost?: Boost) =>
  boost?.toUpdate
    ? boost?.toUpdate?.boostProperty?.specifiedItems
    : boost?.boostProperty?.specifiedItems;

/**
 * returns the boost price
 * @param boost
 */
export const getBoostPrice = (boost?: Boost) =>
  boost?.toUpdate ? boost?.toUpdate?.adjustedPrice : parseFloat(boost?.price ?? '');

/**
 * Returns a boolean value representing if the boost is selected.
 * @param trip
 * @param boost
 */
export const getIsBoostSelected = (trip: Trip | null, boost?: Boost) => {
  const isPaid = trip?.state === TRIP_STATUS.PAID || trip?.state === TRIP_STATUS.UPDATING;
  return !isPaid || !boost?.toUpdate ? boost?.isAdded : boost?.toUpdate.isAdded;
};

/**
 * Finds the traveller by id and returns their first and last name.
 * If the traveller does not exist it will return null.
 * @param travellerId
 * @param travellers
 */
export const getTravellerName = (travellerId: string, travellers: Traveller[]) => {
  const selectedTraveller = travellers?.find(item => item.sortKey === travellerId);
  if (selectedTraveller) {
    return `${selectedTraveller?.firstName} ${selectedTraveller?.lastName ?? ''}`;
  } else {
    return null;
  }
};

export const getSpecifiedItemsAfterRemove = (itemId: string, boost?: Boost) =>
  boost?.toUpdate && boost?.toUpdate.boostProperty
    ? boost?.toUpdate?.boostProperty?.specifiedItems?.filter(
        specifiedItem => specifiedItem.itemId !== itemId,
      )
    : boost?.boostProperty.specifiedItems?.filter(specifiedItem => specifiedItem.itemId !== itemId);

export const getRemovedSpecifiedItems = (itemId: string, boost?: Boost) =>
  boost?.toUpdate && boost?.toUpdate?.boostProperty
    ? boost?.toUpdate?.boostProperty?.specifiedItems?.find(
        specifiedItem => specifiedItem.itemId === itemId,
      )
    : boost?.boostProperty?.specifiedItems?.find(specifiedItem => specifiedItem.itemId === itemId);

export const getExistingSpecifiedItems = (boost: Boost | undefined) => {
  if (
    boost?.toUpdate &&
    boost?.toUpdate?.boostProperty &&
    boost?.toUpdate.boostProperty?.specifiedItems
  ) {
    return boost?.toUpdate?.boostProperty?.specifiedItems;
  }

  if (boost?.toUpdate) {
    return [];
  }

  if (boost?.boostProperty && boost?.boostProperty?.specifiedItems) {
    return boost?.boostProperty?.specifiedItems;
  }

  return [];
};

export const getEmcBoost = (trip: Trip | null) => {
  return trip?.boosts.find(boost => boost.code === 'EMC');
};

/**
 * Returns a boolean representing if the boost is specified items
 * @param boost
 */
export const isBoostSpecifiedItems = (boost?: Boost) => boost?.code === BOOST_TYPES.SPECIFIED_ITEMS;
/**
 * Returns a boolean representing if the boost is extra cancellation
 * @param boost
 */
export const isBoostExtraCancellation = (boost?: Boost) =>
  boost?.code === BOOST_TYPES.EXTRA_CANCELLATION;

/**
 * Returns a boolean representing if the boost is gadget cover
 * @returns
 */
export const isBoostGadgetCover = (boost?: Boost) => boost?.code === BOOST_TYPES.GADGET_COVER;

/**
 * Returns a boolean representing if the boost is either specified items or extra cancellation
 * @param boost
 */
export const isBoostSpecifiedItemsOrExtraCancellation = (boost?: Boost): boolean =>
  isBoostSpecifiedItems(boost) || isBoostExtraCancellation(boost);

/**
 * Checks if the payPerDay Boost is free of charge with the promotion code applied
 * @param trip
 * @param boost
 */
export const isPayPerDayFree = (trip: Trip | null, boost?: Boost | null) =>
  getPayPerDayRate(boost) === 0 && !!trip?.promotionCode?.isFreeOfCharge;

/**
 * Returns a boolean representing if the boost has a price
 * @param boost
 */
export const doesBoostHavePrice = (boost: Boost) =>
  parseInt(boost.price, 10) > 0 || (boost?.toUpdate?.adjustedPrice ?? 0) > 0;

/**
 * Checks to see if the trip has been in post purchase once
 * @param trip
 */
export const isTripPostPurchase = (trip: Trip | null): boolean =>
  trip?.state === TRIP_STATUS.PAID || !!trip?.pendingUpdates;

/**
 * Disables the switch for extra cancellation on post purchase
 * @param boost
 * @param trip
 */
export const isPaidBoostCardToggleDisabled = (boost: Boost, trip: Trip) => {
  //CANX cannot be remove once customer paid for it
  return isTripPostPurchase(trip) && isBoostExtraCancellation(boost);
};

/**
 * Returns a boolean value representing if the boost has duration greater than 0
 * @param boost
 */
export const doesBoostHaveSelectedDays = (boost?: Boost): boolean => {
  if (!boost) {
    return false;
  }

  if (boost?.toUpdate) {
    return boost?.toUpdate.duration > 0;
  }

  return boost?.duration > 0;
};

/**
 * Returns true if the boost is either toggling whilst adding or removing
 * @param boost
 */
export const isBoostToggleLoading = (boost?: Boost): boolean =>
  boost?.toggleStatus === BOOST_UI_STATUS.ADDING ||
  boost?.toggleStatus === BOOST_UI_STATUS.REMOVING;

/**
 * Returns a boolean value representing if the boost is added to a free of charge trip
 * @param boost
 * @param trip
 */
export const isBoostAddedToFreeOfChargeTrip = (boost: Boost | undefined, trip: Trip | null) =>
  isTripFreeOfCharge(trip) && (boost?.toUpdate?.isAdded ?? boost?.isAdded);

/**
 * Returns a boolean value representing if the paid boost can be removed.
 * @param boost
 * @param editTrip
 */
export const canRemovePaidTripBoostListItem = (boost: Boost, editTrip: Trip | null) =>
  !(editTrip?.isTripStarted && boost.category === BOOST_CATEGORY.MY_STUFF);

/**
 * Grabs the modal type of the boost
 * @param boost
 */
export function getBoostModalType(boost?: Boost): BoostModalType | null {
  if (!boost) {
    return null;
  }

  if (isBoostSpecifiedItems(boost)) {
    return 'addSpecifiedItems';
  }

  if (isBoostExtraCancellation(boost)) {
    return 'extraCancellation';
  }

  if (isPayPerDayBoost(boost)) {
    return 'payPerDayCalendar';
  }

  return null;
}

/**
 * Provides the screen name based on provided boost category
 * @param boostCategory
 * @returns
 */
export const getScreenFromBoostCategory = (
  boostCategory: BOOST_CATEGORY | undefined,
): keyof RootStackParamList => {
  switch (boostCategory) {
    case BOOST_CATEGORY.MY_STUFF:
      return 'MyStuff';
    case BOOST_CATEGORY.BOOST_SUMMARY:
      return 'TripSummary';
    case BOOST_CATEGORY.MY_ACTIVITIES:
      return 'MyActivities';
    case BOOST_CATEGORY.MY_EMC:
      return 'EmcDeclaration';
    default:
      return 'MyHealth';
  }
};

/**
 * Returns an array of mutuallyExclusiveBoostIDs,
 * It will return an empty array if the boost does not have any.
 * @param boost
 */
export const getMutuallyExclusiveBoostIds = (boost?: Boost) => {
  return boost?.boostProperty?.mutuallyExclusiveBoostIds ?? [];
};

/**
 * Returns the boost category by route name
 * @param routeName
 */
export const getBoostCategoryByRouteName = (routeName: 'MyActivities' | 'MyStuff') =>
  routeName === 'MyActivities' ? BOOST_CATEGORY.MY_ACTIVITIES : BOOST_CATEGORY.MY_STUFF;

/**
 * get a trip duration based on date time
 *
 * @param startDate
 * @param endDate
 * @param regionCode
 * @param duration
 */
export const getTripDuration = ({
  startDate,
  endDate,
  regionCode,
  duration,
}: TripDurationParams) => {
  const isoStartDate = parseISO(startDate, regionCode);
  const isoEndDate = parseISO(endDate, regionCode);
  return isoEndDate.diff(isoStartDate, duration);
};

/**
 * check if boost is added
 *
 * @param trip
 * @param boostCode
 */
export const isBoostAddedOnTrip = (trip: Trip, boostCode: string) => {
  const boost = trip.boosts.find(boost => boost.code === boostCode);
  return isBoostAdded(boost);
};

/**
 * check if luggage boost modal should open
 *
 * @param trip
 * @param regionCode
 * @param threshold
 */
export const shouldOpenLuggageBoostUpsellModal = (
  trip: Trip,
  regionCode: RegionCode | undefined,
  threshold: number,
) => {
  const params: TripDurationParams = {
    startDate: trip.startDate,
    endDate: trip.endDate,
    regionCode: regionCode || 'AU',
    duration: DATE_TIME_DURATION.WEEK,
  };
  return (
    !isBoostAddedOnTrip(trip, BOOST_CODE.LUGGAGE) && getTripDuration(params).weeks >= threshold
  );
};

/**
 * check if primary traveller is eligible for upsell
 *
 * @param dob
 * @param maxAge
 * @param minAge
 * @param regionCode
 */
export const isPrimaryTravellerAgeEligible = ({
  dob,
  maxAge,
  minAge,
  regionCode,
}: PrimaryTravellerEligibleParams) => {
  if (!dob) {
    return;
  }
  const primaryTravellerAge = getAge(dob, regionCode);
  return primaryTravellerAge >= minAge && primaryTravellerAge <= maxAge;
};

/**
 * has destination selected on trip
 *
 * @param trip
 * @param countryCode
 * @param longName
 */

export const hasDestinationSelectedOnTrip = (trip: Trip, countryCode: string, longName: string) => {
  return trip.destinations.find(
    destination => destination.countryCode === countryCode && destination.longName === longName,
  );
};

/**
 * check if motorcycle boost upsell is eligible to popup modal
 *
 * @param trip
 * @param maxAge
 * @param minAge
 * @param regionCode
 * @param motorCycleUpsellDestinations
 */
export const shouldOpenMotorCycleBoostUpsellModal = (
  trip: Trip,
  maxAge: number | undefined,
  minAge: number | undefined,
  regionCode: RegionCode | undefined,
  motorCycleUpsellDestinations: TripDestination[] | undefined,
) => {
  if (!maxAge || !minAge) {
    return;
  }

  const params: PrimaryTravellerEligibleParams = {
    dob: trip.primaryTraveller?.dob,
    maxAge: maxAge,
    minAge: minAge,
    regionCode: regionCode || 'AU',
  };
  const hasDestination = trip?.destinations?.some(it =>
    motorCycleUpsellDestinations?.map(d => d.countryCode)?.includes(it.countryCode),
  );
  return (
    hasDestination &&
    !isBoostAddedOnTrip(trip, BOOST_CODE.MOTORCYCLE) &&
    isPrimaryTravellerAgeEligible(params)
  );
};

export const getSelectedExtraCoverPrice = (boost?: Boost) => {
  const price = boost?.toUpdate?.adjustedPrice ?? boost?.price;
  return price ?? null;
};

/**
 * check if pre trip cancellation boost is not eligible to show modal
 * @param boost
 * @param region
 */
export const shouldShowNotEligibleBoostModal = (boost: Boost, region?: Region) => {
  if (!boost || region?.country === 'AU') return false;
  return boost.code === BOOST_CODE.PRE_TRIP_CANCELLATION && !boost?.isEligible;
};

/**
 * get my health boost from trip which is mandatory
 * @param trip
 */
export const getMyHealthMandatoryBoost = (trip?: Trip | null) => {
  return trip?.boosts?.find(
    boost => boost.category === BOOST_CATEGORY.MY_HEALTH && boost.isMandatory === true,
  );
};

/**
 * Returns the appropriate title and description for the motorcycle boost upsell modal, based on the trip destinations
 * If trip has only a single upsell destination, it will return the title and description of that destination.
 * Otherwise, it will return the default title and description.
 * @param tripDestinations
 * @param upsellRules
 */
export const getBoostUpsellMotorCycleModalText = (
  tripDestinations: TripDestination[],
  upsellRules?: MotorCycleUpsellRules,
) => {
  let title = upsellRules?.defaultText?.title ?? '';
  let description = upsellRules?.defaultText?.description ?? '';

  const filteredConfigDestinationsByTripDestinations =
    upsellRules?.destinations?.filter(configDestinations =>
      tripDestinations?.some(
        destinations => destinations.countryCode === configDestinations.countryCode,
      ),
    ) ?? [];

  const hasTripIncludedOnlyOneUpsellDestination =
    filteredConfigDestinationsByTripDestinations.length === 1;

  if (hasTripIncludedOnlyOneUpsellDestination) {
    /**
     * We must extract the long name from the trip destination and replace the {{longName}} placeholder in the title and description that is coming from config.
     */
    const upsellDestination = filteredConfigDestinationsByTripDestinations?.[0];

    const tripDestination = tripDestinations?.find(
      it => it.countryCode === upsellDestination?.countryCode,
    );

    const upsellDestinationTitle = filteredConfigDestinationsByTripDestinations?.[0]?.title ?? '';

    const upsellDestinationDescription =
      filteredConfigDestinationsByTripDestinations?.[0]?.description ?? '';

    const destinationName = tripDestination?.countryName ?? tripDestination?.longName ?? '';

    title = upsellDestinationTitle.replace(/{{longName}}/g, destinationName);
    description = upsellDestinationDescription.replace(/{{longName}}/g, destinationName);
  }

  return {
    title,
    description,
  };
};

export function isEmcRequireRedeclare(emcData?: EmcDataType) {
  return !!emcData?.requireRedeclare;
}

export function validateEmcTravellerCanProceed(emcTraveller?: EmcDataType) {
  if (!emcTraveller) return false;
  const isSelected = emcTraveller?.isSelected;
  const hasEvaluatedMedicalConditions = !!emcTraveller?.medicalConditions?.length;
  const isRequireRedeclared = isEmcRequireRedeclare(emcTraveller) && !!emcTraveller.isCompleted;
  const hasNotSelectedDeclaration = isSelected === undefined;
  const hasDeclaredAndSubmittedScreening = isSelected === true && hasEvaluatedMedicalConditions;
  const hasDeclaredNoAndNotSubmittedScreening =
    isSelected === false && !hasEvaluatedMedicalConditions;

  if (hasNotSelectedDeclaration) {
    return false;
  }

  if (isRequireRedeclared) {
    return false;
  }

  if (hasDeclaredNoAndNotSubmittedScreening) {
    return true;
  }

  return hasDeclaredAndSubmittedScreening;
}

export function getEmcTravellerErrorType(emcTraveller?: EmcDataType) {
  if (!emcTraveller) return 'NO_DATA' as const;
  const isSelected = emcTraveller?.isSelected;
  const hasEvaluatedMedicalConditions = !!emcTraveller?.isCompleted;
  const isRequireRedeclared = isEmcRequireRedeclare(emcTraveller) && hasEvaluatedMedicalConditions;
  const hasNotSelectedDeclaration = isSelected === undefined;
  const hasDeclaredYesAndNotSubmittedScreening =
    isSelected === true && !hasEvaluatedMedicalConditions;
  const hasDeclaredNoAndNotSubmittedScreening =
    isSelected === false && !hasEvaluatedMedicalConditions;

  if (hasNotSelectedDeclaration) {
    return 'YES_AND_NO_NOT_SELECTED' as const;
  }

  if (isRequireRedeclared) {
    return 'REQUIRES_REDECLARATION' as const;
  }

  if (hasDeclaredYesAndNotSubmittedScreening) {
    return 'YES_SELECTED_BUT_REQUIRES_SCREENING' as const;
  }

  if (hasDeclaredNoAndNotSubmittedScreening) {
    return undefined;
  }
  // All other scenarios are valid
  return undefined;
}

export function hasEmcDataForAllTravellers(emcBoost: Boost | undefined) {
  return emcBoost?.travellers?.length === emcBoost?.boostProperty?.emcData?.length;
}

export function isEmcBoostAdded(emcData?: EmcDataType) {
  return !!emcData?.isAdded;
}

export function hasAddedEmcBoost(trip: Trip | null) {
  return getEmcBoost(trip)?.boostProperty?.emcData?.some(isEmcBoostAdded) ?? false;
}

export function getEmcAssessments(trip: Trip | null) {
  const emcBoost = getEmcBoost(trip);

  if (!emcBoost) {
    return [];
  }

  return emcBoost.boostProperty.emcData?.filter(({ assessmentNumber }) => !!assessmentNumber) ?? [];
}
