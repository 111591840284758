import React from 'react';

import { BoostCoverBulletPoints } from 'freely-shared-types';

import { Assets } from '@assets';

import { CheckboxSVGIcon } from '../checkboxSVG';
import { Text } from '../text';

interface Props {
  isCovered?: boolean;
  item: BoostCoverBulletPoints;
}

export const BulletPointsItem: React.FC<Props> = ({ item, isCovered }) => {
  return (
    <div>
      <ul>
        {item.value.map((value, index) => (
          <li key={index} className="flex py-1.5">
            {isCovered ? (
              <CheckboxSVGIcon isChecked className="w-6 flex-none" />
            ) : (
              <Assets.NotCovered className="flex-none" />
            )}
            <Text withMarkDown className="ml-3 [&>ul]:list-disc [&>ul]:ml-6 ">
              {value}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  );
};
