import { cva } from 'class-variance-authority';

export type CoveredCardProps = {
  children: React.ReactNode;
  variant?: 'mint' | 'cherry';
  className?: string;
};

const coveredCardContainer = cva('px-4 py-4 border-2 rounded-lg', {
  variants: {
    variant: {
      mint: 'bg-mint-10 border-mint-100' as const,
      cherry: 'bg-cherry-10 border-cherry-100' as const,
    },
  },
});

export const CoveredCard = ({ children, variant, className }: CoveredCardProps) => {
  return <div className={coveredCardContainer({ className, variant })}>{children}</div>;
};
