import { ERROR_CODE } from 'freely-shared-constants';
import { Boost, BoostUpsellType } from 'freely-shared-types';

import { ModalComponentName } from '@components';

export const STEPS_CONFIG = {
  STEP_CIRCLE_WIDTH: '0.75rem', // width of circle
  SM_STEP_CIRCLE_WIDTH: '0.5rem', // width of circle
  INIT_STEP: 0, // initialize current step
};

export const FREELY_WEBSITE_URL = 'https://www.freely.me';

export enum FREELY_WEBSITE_ROUTES {
  GET_HELP = 'get-help',
}

export enum EMAIL_QUOTE_FLOW_TYPE {
  FOOTER = 'footer email a quote',
  ABANDON_CART = 'abandon cart popup',
}

export interface ModalBody {
  type: ModalComponentName;
  boost: Boost;
  title: string | null;
  description: string | null;
  boostUpsellType?: BoostUpsellType | null;
  onComplete?: () => void;
}

export const ERROR_TEXT = {
  [ERROR_CODE.INTERNAL_ERROR]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.internalError.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.internalError.body',
  },
  [ERROR_CODE.QUOTE_ALREADY_PAID]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteAlreadyPaid.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteAlreadyPaid.body',
  },
  // US Only Error: We add a fallback in AU to use the same textId but the text is different
  [ERROR_CODE.QUOTE_ELIGIBILITY_CHANGED]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteEligibilityChanged.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteEligibilityChanged.body',
  },
  [ERROR_CODE.QUOTE_EXPIRED]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteExpired.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteExpired.body',
  },
  [ERROR_CODE.QUOTE_INVALID_STATUS]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteExpired.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteExpired.body',
  },
  [ERROR_CODE.QUOTE_NOT_FOUND]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteNotFound.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteNotFound.body',
  },
  // both errors uses the same message
  [ERROR_CODE.QUOTE_START_DATE_PASSED]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteStartDatePassed.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteStartDatePassed.body',
  },
  [ERROR_CODE.QUOTE_END_DATE_PASSED]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.quoteStartDatePassed.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.quoteStartDatePassed.body',
  },
  [ERROR_CODE.PAYMENT_IN_PROGRESS]: {
    titleI18Text: 'global.errors.errorBoundary.errorText.paymentInProgress.title',
    bodyI18Text: 'global.errors.errorBoundary.errorText.paymentInProgress.body',
  },
} as const;
