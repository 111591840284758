import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { notifyAuthorization } from 'freely-shared-api';
import { TRIP_STATUS } from 'freely-shared-types';

import { useTripStore } from '@store';
import { Stripe } from '@stripe/stripe-js';
import { onProcessingPaymentModal } from '@utils';

const updateTripPolicyStatus = useTripStore.getState().updateTripPolicyStatus;
export const useAfterPayStripePayment = () => {
  const [queryParams] = useSearchParams();
  const payment_intent = queryParams.get('payment_intent');
  const payment_intent_client_secret = queryParams.get('payment_intent_client_secret');

  const onAfterPayRedirect = useCallback(
    async (stripe: Stripe | null) => {
      try {
        if (payment_intent && payment_intent_client_secret) {
          updateTripPolicyStatus({ policyStatus: undefined });
          const stripeResponse = await stripe?.retrievePaymentIntent(payment_intent_client_secret);

          if (stripeResponse?.paymentIntent?.status === 'requires_capture') {
            onProcessingPaymentModal();
            notifyAuthorization({ clientSecret: payment_intent_client_secret });
            return;
          }
          if (stripeResponse?.paymentIntent?.status === 'requires_payment_method') {
            throw new Error('After pay requires payment method');
          }
        }
      } catch (e) {
        updateTripPolicyStatus({ status: TRIP_STATUS.NO_BOOST, policyStatus: 'ERROR' });
      }
    },
    [payment_intent, payment_intent_client_secret],
  );

  return { onAfterPayRedirect };
};
