import { FC, useEffect, useState } from 'react';

import { QuoteLoader } from '@components/quoteLoader';
import { Text } from '@components/text';
import { i18n } from '@i18n';

const messages = i18n.t('global.loadingMessages', {
  returnObjects: true,
});

export interface QuoteLoadingModalProps {
  children?: React.ReactNode;
}

export const QuoteLoading: FC<QuoteLoadingModalProps> = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(index => (index + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center max-w-md text-center gap-8">
      <QuoteLoader />
      <Text variant="h5-34">Building your quote</Text>
      <Text className="text-gray-700" variant="title-24/medium">
        {messages[messageIndex]}
      </Text>
    </div>
  );
};
