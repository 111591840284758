import { Fragment, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { Dialog, Transition } from '@headlessui/react';
import { useQuoteStore, useTripStore } from '@store';

import { QuoteError } from './quoteError/quoteError.component';
import { QuoteLoading } from './quoteLoading/quoteLoading.component';
import { QuoteRetry } from './quoteRetry/quoteRetry.component';

export const GetQuoteLoading = () => {
  const quoteLoadingType = useQuoteStore(state => state.quoteLoadingType);
  const isCreatingOrUpdatingTrip = useTripStore(state => state.isCreatingOrUpdatingTrip);

  const renderContent = useMemo(() => {
    switch (quoteLoadingType.type) {
      case 'quoteLoading':
        return <QuoteLoading />;
      case 'quoteRetry':
        return <QuoteRetry />;
      default:
        return <QuoteError />;
    }
  }, [quoteLoadingType.type]);

  const bgColor = quoteLoadingType.type === 'quoteLoading' ? 'bg-white' : 'bg-mint-100';

  const isError = quoteLoadingType.type === 'quoteRetry' || quoteLoadingType.type === 'quoteError';

  const isOpen = isCreatingOrUpdatingTrip || isError;

  return (
    <Transition.Root show={isOpen}>
      <Dialog open={isOpen} as="div" className="relative z-10" onClose={v => v}>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel
              className={twMerge('w-[100vw] h-[100vh] flex items-center justify-center', bgColor)}>
              {renderContent}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
