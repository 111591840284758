import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import { MIN_VALID_CREDIT_LENGTH, mmYYFormat } from 'freely-shared-constants';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { PAYMENT_TYPE } from 'freely-shared-types';
import {
  formatCardNumberByType,
  getCardBrand,
  getRegionDateTime,
  validate,
  validateCardNumberByType,
  validateExpiryDateWeb,
} from 'freely-shared-utils';

import { CardTypeDropdown, Input } from '@components';
import { CheckoutFormUS } from '@hooks';
import { useCheckoutStore } from '@store';

export const CreditCardForm = () => {
  const { control, trigger, clearErrors, setValue, watch } = useFormContext<CheckoutFormUS>();

  const creditCardNumber = watch('cardDetails.cardNumber');
  const rawCardNumber = creditCardNumber.replace(/ /g, '');
  const [cardType, setCardType] = useState<PAYMENT_TYPE | undefined>();
  const openSection = useCheckoutStore(state => state.openSection);

  const region = useRegionStore(getRegionSelector);

  let detectedCardType = (getCardBrand(rawCardNumber) as PAYMENT_TYPE) ?? cardType ?? undefined;
  const formattedCardNumber = formatCardNumberByType(detectedCardType) ?? '#### #### #### #### ###';
  const isCardNumberValid = rawCardNumber.length >= MIN_VALID_CREDIT_LENGTH;

  const handleCardNumberChange = (value: string) => {
    detectedCardType && setValue('cardDetails.cardType', detectedCardType);
    const rawNumber = value.replace(/ /g, '');
    // clear card type if card number is empty
    if (rawNumber.length < 1) {
      setCardType(undefined);
    }
    setValue('cardDetails.cardType', getCardBrand(rawNumber) as PAYMENT_TYPE | undefined);
  };

  const handleCardTypeChange = async (selected: PAYMENT_TYPE | undefined) => {
    setValue('cardDetails.cardType', selected);
    setCardType(selected);
    await trigger('cardDetails.cardNumber'); // Trigger validation immediately after selection

    //clear type error if card number is greater is valid
    if (isCardNumberValid) {
      clearErrors('cardDetails.cardNumber');
    }
  };

  return (
    <form className="space-y-4">
      <Controller
        control={control}
        name="cardDetails.cardNumber"
        rules={{
          validate: {
            requiredCardNumber: value => {
              if (openSection !== 'payment') {
                return undefined;
              }
              if (!value) {
                return 'Your card number is invalid.';
              }
            },
            manualCardSelectedValidation: value => {
              if (openSection !== 'payment') {
                return undefined;
              }
              const rawNumber = value.replace(/ /g, '');
              // if the cardNumber is pasted and card type is not detected, detect card type
              detectedCardType = detectedCardType ?? (getCardBrand(rawNumber) as PAYMENT_TYPE);

              if (detectedCardType) {
                //if card does not match the length return error
                if (!validateCardNumberByType(rawNumber, detectedCardType)) {
                  return 'Your card number is invalid.';
                }
                return true;
              }

              //if card type is not detected and card number is greater than 13 characters return error to select card type
              if (rawNumber.length >= MIN_VALID_CREDIT_LENGTH) {
                return 'Please select a card type.';
              }

              return isCardNumberValid || 'Your card number is invalid.';
            },
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <PatternFormat
            onPaste={(e: any) => {
              onChange(e.target.value);
              handleCardNumberChange(e.target.value);
            }}
            format={formattedCardNumber}
            customInput={Input}
            inputMode="numeric"
            hasError={!!error}
            showErrorIcon={!!error}
            errorMessage={error?.message}
            labelProps={{
              children: 'Card Number',
              variant: 'sub-heading-14/400',
            }}
            svgIconRight={
              <CardTypeDropdown
                value={detectedCardType}
                showDropdown={rawCardNumber.length >= MIN_VALID_CREDIT_LENGTH}
                onChange={handleCardTypeChange}
              />
            }
            placeholder="0000 0000 0000 0000"
            value={value}
            onBlur={() => {
              // Validate on blur if no errors and card type is defined
              if (!error && detectedCardType) {
                trigger('cardDetails.cardNumber');
              }
            }}
            onChange={e => {
              onChange(e.target.value);
              handleCardNumberChange(e.target.value);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="cardDetails.expiryDate"
        rules={{
          validate: (v?: string) => {
            if (openSection !== 'payment') {
              return undefined;
            }
            if (!v) {
              return `Your card's expiration date is incomplete.`;
            }
            return validateExpiryDateWeb(
              v,
              getRegionDateTime(region?.country),
              "Your card's expiration date is incomplete.",
              "Your card's expiration date is in the past.",
            );
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <PatternFormat
            customInput={Input}
            format={mmYYFormat}
            inputMode="numeric"
            hasError={!!error}
            showErrorIcon={!!error}
            mask="_"
            errorMessage={error?.message}
            labelProps={{
              children: 'Expiration',
              variant: 'sub-heading-14/400',
            }}
            placeholder="MM/YY"
            value={field.value}
            onChange={e => {
              const { value } = e.target;
              const [month, year] = value.split('/');

              const formattedMonth = parseInt(month, 10) > 12 ? `0${parseInt(month, 10)}` : month;

              const newValue = `${formattedMonth}/${year || ''}`;
              field.onChange(newValue);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="cardDetails.nameOnCard"
        rules={{
          validate: {
            name: v => {
              if (openSection !== 'payment') {
                return undefined;
              }
              return validate('cardHolderName', v.trim());
            },
          },
          maxLength: {
            value: 40,
            message: 'Maximum of 40 characters for cardholder name.',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Input
            hasError={!!error}
            value={field.value}
            onChange={e => {
              field.onChange(e.target.value);
            }}
            showErrorIcon={!!error}
            errorMessage={error?.message}
            labelProps={{ children: 'Name on card', variant: 'sub-heading-14/400' }}
            placeholder="First and last name"
          />
        )}
      />
    </form>
  );
};
