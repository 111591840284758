import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  getCoolingOffPeriodDaysSelector,
  getRegionSelector,
  isAURegionSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { getDestinationsLabel, getFullDateStr } from 'freely-shared-utils';

import { Assets } from '@assets';
import { i18n } from '@i18n';
import { getDestinationBackgroundImagesSelector, getTripSelector, useTripStore } from '@store';

import { AppStoreRating } from '../appStoreRating';
import { PaymentMethodIcons } from '../paymentMethodIcons';
import { Text, TextProps } from '../text';

export type TripHeaderProps = {
  titleTextProps?: TextProps;
  subtitleTextProps?: TextProps;
  tag?: string;
  fullDestinations?: string;
  price?: string;
  variant?: 'QUOTE' | 'CONFIRMATION' | 'CHECKOUT';
  className?: string;
  statusVariant?: 'mint' | 'lime';
};

/**
 * Trip Header component (Banner)
 * variants:
 * DEFAULT: content is left aligned
 * QUOTE: used in quote flow, centered items and larger banner height
 * CONFIRMATION: left aligned when medium to large, centered when small, and larger banner height
 */
export const TripHeader: FC<TripHeaderProps> = ({
  titleTextProps,
  subtitleTextProps,
  tag,
  fullDestinations,
  price,
  variant,
  className = 'md:h-80 h-96',
  statusVariant = 'mint',
}) => {
  const isQuote = variant === 'QUOTE';
  const isCheckOut = variant === 'CHECKOUT';
  const isQuoteOrCheckOut = isQuote || isCheckOut;
  const isConfirmation = variant === 'CONFIRMATION';
  const isDefault = !isQuoteOrCheckOut && !isConfirmation;
  return (
    <header
      className={twMerge(
        isQuoteOrCheckOut && '!md:h-[370px] !h-fit',
        isConfirmation && 'h-fit',
        className,
        'relative flex w-full items-center',
      )}>
      <div // content row container
        className={twMerge(
          'flex justify-center w-full flex-col lg:flex-row',
          isDefault && 'entry-content items-start',
          isQuoteOrCheckOut && 'entry-content items-center w-full',
          isConfirmation && 'entry-content items-center lg:items-start',
        )}>
        <div // content column container
          className={twMerge(
            'flex w-full flex-col py-8 ',
            isDefault && 'items-start',
            isQuoteOrCheckOut && ' w-full ',
            isConfirmation && 'items-center lg:items-start lg:pr-[40%]',
          )}>
          {tag && (
            <Text
              variant="caption-12/bold"
              className={twMerge(
                {
                  mint: 'bg-mint-100',
                  lime: 'bg-lime',
                }[statusVariant],
                'inline-flex items-center rounded-full px-3 py-1',
                (titleTextProps || subtitleTextProps) && 'mb-4',
              )}>
              {tag}
            </Text>
          )}
          {titleTextProps && (
            <Text
              useFB5Font
              variant={{ lg: 'h2-52', sm: 'h4-40' }}
              className={twMerge(
                'text-white',
                subtitleTextProps && 'pb-4',
                isConfirmation && 'text-center lg:text-left',
              )}
              withMarkDown
              {...titleTextProps}
            />
          )}
          {subtitleTextProps && (
            <Text
              variant={{ lg: 'title-24/regular', sm: 'sub-title-20/regular' }}
              className={twMerge(
                'text-white',
                fullDestinations && 'pb-4',
                isConfirmation && 'text-center lg:text-left w-full break-words',
              )}
              {...subtitleTextProps}
            />
          )}
          {fullDestinations && (
            <Text variant="h7-26" className="line-clamp-3 text-ellipsis text-white">
              {fullDestinations}
            </Text>
          )}
          {price && <PriceSection price={price} />}
          {isQuoteOrCheckOut && <QuoteBanner variant={variant} />}
        </div>
      </div>
      <BannerImage />
    </header>
  );
};

const PriceSection = ({ price }: { price: string }) => {
  const isAU = useRegionStore(isAURegionSelector);

  return (
    <div className="flex w-full flex-col lg:flex-row lg:justify-between">
      <Text variant={{ lg: 'h2-52', sm: 'h4-40' }} className="text-white">
        {price} <span className="text-[28px]">Total</span>
      </Text>
      {/* app store rating on the same row as price */}
      {isAU && <AppStoreRating />}
    </div>
  );
};

const BannerImage = () => {
  const imageSrcSet = useTripStore(getDestinationBackgroundImagesSelector);
  const hasImage = imageSrcSet && Object.values(imageSrcSet).length > 0;

  if (hasImage) {
    return (
      <img
        srcSet={`${imageSrcSet.desktop} 1024pxw, ${imageSrcSet.tablet} 768pxw, ${imageSrcSet.mobile} 640pxw`}
        src={imageSrcSet.desktop}
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
    );
  }

  // default steal background
  return <div className="bg-steal absolute inset-0 -z-10 h-full w-full object-cover" />;
};

type QuoteBannerProps = {
  variant: 'QUOTE' | 'CHECKOUT';
};
const QuoteBanner: React.FC<QuoteBannerProps> = ({ variant }) => {
  const region = useRegionStore(getRegionSelector);
  const coolingDaysSelector = useConfigStore(getCoolingOffPeriodDaysSelector);
  const trip = useTripStore(getTripSelector);
  const destinationsList = getDestinationsLabel(trip);
  const startDate = getFullDateStr(trip.startDate);
  const endDate = getFullDateStr(trip.endDate);
  const tripDateStr = `On ${startDate} to ${endDate}`;

  const quoteTitleText = variant === 'CHECKOUT' ? 'checkout.header.title' : 'myQuote.header.title';
  const coolOffPeriod = coolingDaysSelector(region?.state ?? '');

  return (
    <div className="gap-2 md:gap-4 lg:gap-10 flex flex-col justify-between md:mt-4  space-y-7 md:space-y-0 ">
      <div className="justify-start">
        <Assets.Zurich className="w-22" />
      </div>
      <div className="flex flex-col md:flex-row justify-between md:items-center">
        <div className="space-y-3 flex-1">
          <Text
            variant={{ lg: 'title-24/400', md: 'body-18/500', sm: 'body-18/500' }}
            className="text-white">
            {i18n.t(quoteTitleText)}
          </Text>
          <div className="flex flex-row justify-between w-full items-center">
            <Text
              useFB5Font
              variant={{ lg: 'h2-52', sm: 'h4-40', md: 'h4-40' }}
              className="text-white">
              <div className="sm:line-clamp-1 lg:h-[3.7rem] md:h-[2.81rem]">
                {destinationsList.split(',').length >= 9 ? (
                  <>
                    {destinationsList.split(',')[0]} <span className="text-[28px]"> + 9 more</span>
                  </>
                ) : (
                  destinationsList
                )}
              </div>
            </Text>
          </div>
          <div className="flex flex-row justify-start items-center w-full">
            <Text
              variant={{ lg: 'title-24/400', md: 'body-18/500', sm: 'body-18/500' }}
              className="text-white ">
              {tripDateStr}
            </Text>
            <div className="ml-2 flex items-center rounded-[0.25rem] bg-white px-[0.5rem]">
              <Text variant={{ lg: 'sub-heading-14/500', sm: 'caption-12/500' }}>
                {i18n.t('checkout.header.timeZoneLabel')}
              </Text>
            </div>
          </div>
        </div>
        <div className="w-full border-b-[0.12rem] border-white border-opacity-40 md:hidden my-6"></div>
        <div className="space-y-6">
          <div className="flex md:justify-end items-end">
            <TotalPrice />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full md:items-center">
        <div>
          <AppStoreRating />
        </div>
        <div className="hidden lg:flex space-x-12">
          <div className="flex items-center">
            <Assets.Support height={22} width={22} />
            <Text variant="sub-heading-14/400" className="px-1 text-white" withMarkDown>
              {i18n.t('myQuote.header.travelSupport')}
            </Text>
          </div>
          <div className="flex items-center">
            <Assets.CalendarCoolOff height={22} width={22} className="text-white" />
            <Text variant="sub-heading-14/400" className="px-1 text-white" withMarkDown>
              {i18n.t('myQuote.header.coolingPeriod', { coolOffPeriod })}
            </Text>
          </div>
        </div>
        <div className="flex items-center flex-wrap mt-2 md:mt-0">
          <div className="flex">
            <Assets.Verified height={22} width={22} />
            <Text variant="sub-heading-14/400" className="text-white pr-2 inline" withMarkDown>
              {i18n.t('myQuote.header.securePayment')}
            </Text>
          </div>
          <div className="flex-auto lg:flex-none">
            <PaymentMethodIcons className="ml-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

const TotalPrice = () => {
  const trip = useTripStore(getTripSelector);
  const tripPrice = trip.price / 100;
  return (
    <>
      <Text
        variant={{ lg: 'h4-40', md: 'h5-34', sm: 'h5-34' }}
        className="text-white pb-[0.4rem] pr-1">
        $
      </Text>
      <Text variant={{ lg: 'h1-64', md: 'h2-52', sm: 'h4-40' }} className="text-white pr-2">
        {tripPrice}
      </Text>
      <Text
        variant={{ lg: 'title-24/700', md: 'callout-16/700', sm: 'callout-16/700' }}
        className="text-white pb-[0.3rem]">
        {i18n.t('myQuote.header.total')}
      </Text>
    </>
  );
};
