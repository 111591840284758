import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { postEmcEmailAssessmentLetter } from 'freely-shared-api';
import { getRegionState, useConfigStore } from 'freely-shared-stores';
import { EMCEmailAssessment, EmcDataType } from 'freely-shared-types';
import { emailAssessmentSchema, logToSentry } from 'freely-shared-utils';
import { getEmcBoost } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Button, Input, ModalBodyContainer, Text } from '@components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  closeModalSelector,
  getTripSelector,
  useEmcStore,
  useModalStore,
  useTripStore,
} from '@store';

export const EMCRejectedEmailAssessment = () => {
  const { emcCurrentTraveller } = useEmcTraveller();
  const trip = useTripStore(getTripSelector);
  const region = getRegionState();
  const emcBoost = getEmcBoost(trip);
  const { assessmentNumber } = emcCurrentTraveller as EmcDataType;
  const closeModal = useModalStore(closeModalSelector);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { control, handleSubmit } = useForm<EMCEmailAssessment>({
    resolver: zodResolver(emailAssessmentSchema),
  });
  const privacyPolicyLink = useConfigStore(state => state?.config?.AU.PRIVACY_STATEMENT);
  const termsLink = useConfigStore(state => state?.config?.AU.APP_TERMS);

  const handleFormSubmit = useCallback(
    async (data: EMCEmailAssessment) => {
      if (!region || !assessmentNumber) return;
      setIsLoading(true);
      try {
        await postEmcEmailAssessmentLetter({
          name: data.name,
          email: data.email,
          assessmentNumber,
          boostId: emcBoost?.boostId ?? '',
          region: region,
        });
        closeModal();
        setOpenEmcSection(undefined);
      } catch (error) {
        setErrorMessage((error as Error)?.message);
        logToSentry(new Error('Failed to email medical assessment letter.' + error));
      } finally {
        setIsLoading(false);
      }
    },
    [assessmentNumber, closeModal, emcBoost?.boostId, region, setOpenEmcSection],
  );

  const handleCloseModal = useCallback(() => {
    closeModal();
    setOpenEmcSection(undefined);
  }, [closeModal, setOpenEmcSection]);

  return (
    <ModalBodyContainer
      icon={<Assets.AlertOutline />}
      header={<Text variant="h6-28">{i18n.t('emcShared.global.modal.rejected.title')}</Text>}
      description={
        <>
          <Text variant="callout-16/400">
            {i18n.t('emcShared.global.modal.rejected.description')}
          </Text>
          <form className="w-full">
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4">
                  <Input
                    {...field}
                    placeholder="Complete Name"
                    hasError={!!error}
                    errorMessage={error?.message}
                    className="bg-white"
                    errorMessageClassName="text-left"
                  />
                </div>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className="mb-4">
                  <Input
                    {...field}
                    placeholder="Email Address"
                    hasError={!!error}
                    errorMessage={error?.message}
                    className="bg-white"
                    errorMessageClassName="text-left"
                  />
                </div>
              )}
            />
            <Text
              variant="sub-heading-14/400"
              className="mb-4 text-left [&>a]:text-charcoal"
              withMarkDown>
              {i18n.t('emcShared.global.modal.rejected.terms', {
                PRIVACY_POLICY: privacyPolicyLink,
                TERMS: termsLink,
              })}
            </Text>
            {errorMessage && (
              <Text variant="sub-heading-14/400" className="text-cherry-100 mb-4 text-left">
                {errorMessage}
              </Text>
            )}

            <div className="flex flex-col sm:flex-row justify-between space-x-0 sm:space-x-6">
              <Button
                size="md"
                className="w-full mb-5 sm:mb-0"
                variant="snow"
                onClick={handleCloseModal}>
                {i18n.t('emcShared.global.modal.rejected.actions.primary')}
              </Button>
              <Button
                isLoading={isLoading}
                size="md"
                className="w-full"
                variant="charcoal"
                onClick={handleSubmit(handleFormSubmit)}>
                {i18n.t('emcShared.global.modal.rejected.actions.secondary')}
              </Button>
            </div>
          </form>
        </>
      }
    />
  );
};
