import { logToSentry } from 'freely-shared-utils';

import { TripCostEditor } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';

const TAG = 'TripCost';
export const TripCost = () => {
  const handleNext = async () => {
    try {
      router.navigate(ROUTE_PATHS().tripDepositDate);
    } catch (err) {
      logToSentry(err as Error, { tag: TAG });
    }
  };

  return (
    <main>
      <TripCostEditor onNext={handleNext} onBack={() => router.navigate(-1)} />
    </main>
  );
};
