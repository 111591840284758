import React from 'react';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { i18n } from '@i18n';

export const EmailQuoteFailed: React.FC = () => (
  <div className="flex flex-col  items-center justify-center space-y-5 pb-3">
    <Assets.ToolsCircle width={72} />
    <Text variant="h6-28" className="text-left">
      {i18n.t('emailQuote.emailQuoteFailed.title')}
    </Text>
    <Text variant="callout-16/400">{i18n.t('emailQuote.emailQuoteFailed.subtitle')}</Text>
    <div className="flex items-center border-t px-3 border-steal border-opacity-30 ">
      <Text variant="callout-16/400" className="py-4 text-center">
        {i18n.t('emailQuote.emailQuoteFailed.description')}
      </Text>
    </div>
    <Button variant="snow" size="md" className="w-full md:w-[45%]">
      {i18n.t('emailQuote.emailQuoteFailed.contactNumber')}
    </Button>
  </div>
);
