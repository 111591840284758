import { useCallback } from 'react';

import { ERROR_CODE } from 'freely-shared-constants';
import {
  RegionState,
  isUSRegionSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { Region } from 'freely-shared-types';

import { Assets } from '@assets';
import { ErrorScreenProps } from '@components';
import { i18n } from '@i18n';
import { ERROR_TEXT } from '@types';

type Props = {
  quoteId?: string | null;
  region?: Region;
  onGetNewQuote: () => void;
  onGoHomepage: () => void;
};

function assistancePhoneNumberSelector(state: RegionState) {
  const isUS = isUSRegionSelector(state);
  return isUS
    ? 'global.errors.errorBoundary.phoneNumber.maintenanceMode'
    : 'global.errors.errorBoundary.phoneNumber.quoteNotFound';
}

export const useErrorText = ({ quoteId, onGetNewQuote, onGoHomepage }: Props) => {
  const phoneNumberI18NText = useRegionStore(assistancePhoneNumberSelector);

  const assistanceNumberConfig =
    useConfigStore(state => state.regionSpecificConfig?.CUSTOMER_SERVICE_OVERSEAS_NUMBER) ??
    '+61 2 8907 5085';

  const getErrorScreenProps = useCallback(
    (err?: Error): ErrorScreenProps | null => {
      const assistanceNumber = i18n.t(phoneNumberI18NText);
      const errorText =
        ERROR_TEXT[err?.name as keyof typeof ERROR_TEXT] ?? ERROR_TEXT[ERROR_CODE.INTERNAL_ERROR];

      const errorProps: ErrorScreenProps = {
        titleTextProps: {
          children: i18n.t(errorText.titleI18Text),
          variant: 'h6-28',
        },
        bodyTextProps: {
          children: i18n.t(errorText.bodyI18Text, { QUOTE_ID: quoteId ?? '' }),
          variant: 'callout-16/medium',
        },
        Icon: Assets.FaceFrown,
        actions: [
          {
            children: i18n.t('global.errors.errorBoundary.buttonActions.getANewQuote'),
            variant: 'white',
            onClick: onGetNewQuote,
          },
        ],
      };

      switch (err?.name) {
        case ERROR_CODE.QUOTE_ALREADY_PAID:
          errorProps.Icon = Assets.CheckCircleOutline;
          break;

        case ERROR_CODE.QUOTE_NOT_FOUND:
          errorProps.actions = [
            {
              children: i18n.t('global.errors.errorBoundary.buttonActions.getANewQuote'),
              variant: 'white',
              onClick: onGetNewQuote,
            },
            {
              children: i18n.t(phoneNumberI18NText),
              variant: 'snow',
              onClick: () => {
                window.location.href = `tel:${assistanceNumber?.replace(/\s+/g, '')}`;
              },
            },
          ];
          break;
        case ERROR_CODE.QUOTE_INVALID_STATUS:
          errorProps.actions = [
            {
              children: i18n.t('global.errors.errorBoundary.buttonActions.getANewQuote'),
              variant: 'white',
              onClick: onGetNewQuote,
            },
            {
              children: i18n.t(phoneNumberI18NText),
              variant: 'snow',
              onClick: () => {
                window.location.href = `tel:${assistanceNumber?.replace(/\s+/g, '')}`;
              },
            },
          ];
          break;

        case ERROR_CODE.QUOTE_START_DATE_PASSED:
          errorProps.actions = [
            {
              children: i18n.t('global.errors.errorBoundary.buttonActions.getANewQuote'),
              variant: 'white',
              onClick: onGetNewQuote,
            },
          ];
          break;

        case ERROR_CODE.QUOTE_END_DATE_PASSED:
          errorProps.actions = [
            {
              children: i18n.t('global.errors.errorBoundary.buttonActions.getANewQuote'),
              variant: 'white',
              onClick: onGetNewQuote,
            },
          ];
          break;

        case ERROR_CODE.PAYMENT_IN_PROGRESS:
          errorProps.actions = [
            {
              children: assistanceNumberConfig,
              variant: 'white',
              onClick: () => {
                window.location.href = `tel:${assistanceNumberConfig?.replace(/\s+/g, '')}`;
              },
            },
          ];
          break;

        case ERROR_CODE.INTERNAL_ERROR:
          errorProps.actions = [
            {
              children: i18n.t('global.errors.errorBoundary.buttonActions.backToHomePage'),
              variant: 'white',
              onClick: onGoHomepage,
            },
          ];
          break;
      }

      return errorProps;
    },
    [quoteId, onGetNewQuote, phoneNumberI18NText, assistanceNumberConfig, onGoHomepage],
  );

  return {
    getErrorScreenProps,
  };
};
