import { del, get, post, put } from 'aws-amplify/api';
import lodashGet from 'lodash/get';

import type {
  EMCEmailAssessmentRequest,
  PaymentOption,
  Region,
  Trip,
  TripDuration,
} from 'freely-shared-types';
import { currentCredentials, throwError } from 'freely-shared-utils';

export const fetchTrips = async ({ region }: { region: Region }) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/trips/list`;

  try {
    const result = await get({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Getting trips failed. Please try again.' });
  }
};

export const fetchTripById = async (arg: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/trips/${arg.tripId}`;

  try {
    const result = await get({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({ error, message: 'Getting trip by Id failed. Please try again.' });
  }
};

export const fetchUnauthTrip = async ({ region }: { region: Region }) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/unauth/trips`;

  try {
    const identity = await currentCredentials();

    const result = await get({
      apiName,
      path,
      options: {
        identity,
      } as any,
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({ error, message: 'Getting unauth trip failed. Please try again.' });
  }
};

export const linkUnauthTrip = async (arg: { identityId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/trips/unauth-trip-link`;

  try {
    const parts = arg.identityId.split(':');
    const result = await post({
      apiName,
      path,
      options: {
        body: {
          identityId: lodashGet(parts, 1) || arg.identityId,
        },
      },
    }).response;

    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({ error, message: 'Linking unauth trip failed. Please try again.' });
  }
};

export const deleteTrip = async (arg: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/trips/${arg.tripId}`;
  try {
    const result = await del({ apiName, path }).response;
    return result as any;
  } catch (error) {
    return throwError({ error, message: 'Deleting trip failed. Please try again.' });
  }
};

export const cancelPaidTrip = async (arg: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/paid-trips/${arg.tripId}`;
  try {
    const result = await del({ apiName, path }).response;
    return await (result as any)?.body?.json?.(); // AMPLIFY API response type is not correct AND ASSUMES WE CANT NOT AWAIT JSON RESPONSE
  } catch (error) {
    return throwError({ error, message: 'Cancelling trip failed. Please try again.' });
  }
};

export const updatePaidTripDuration = async (arg: {
  tripId: string;
  tripDuration: TripDuration;
  region: Region;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/paid-trips/${arg.tripId}`;
  const options = {
    body: {
      ...arg.tripDuration,
    },
  };
  try {
    const result = await put({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    //We are ignoring BE 400 response code and handling it in FE
    if (
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message === 'Trip end date must after pay per day boost end date'
    ) {
      return;
    }

    throwError({ error, message: 'Updating paid trip duration failed. Please try again.' });
  }
};

export const resetUpdatingPaidTrip = async (arg: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/paid-trips/${arg.tripId}/reset`;
  try {
    const result = await post({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Reset updating paid trip failed. Please try again.' });
  }
};

export const confirmUpdatingPaidTrip = async (arg: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/paid-trips/${arg.tripId}/confirm`;
  try {
    const result = await post({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Confirm updating paid trip failed. Please try again.' });
  }
};

export const updateTrip = async (arg: {
  editTrip: Trip;
  region: Region;
  onError?: (error: unknown) => void;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/trips/${arg.editTrip.sortKey}`;
  const options = {
    body: arg.editTrip,
  } as any;

  try {
    const result = await put({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    arg.onError && arg.onError(error);
    throwError({ error, message: 'Updating trip failed. Please try again.' });
  }
};

export const updateUnauthTrip = async (arg: {
  editTrip: Trip;
  region: Region;
  onError?: (error: unknown) => void;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/unauth/trips/${arg.editTrip.sortKey}`;

  try {
    const identity = await currentCredentials();
    const options = {
      body: arg.editTrip,
      identity,
    } as any;

    const result = await put({
      apiName,
      path,
      options,
    }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    //FIXME: this is a hack to ignore trip has no changes error for unauth trip creation flow
    if (error.response.data.message === 'Trip has no changes') {
      return;
    }

    arg.onError && arg.onError(error);
    throwError({ error, message: 'Updating trip failed. Please try again.' });
  }
};

export const fetchSecondaryTravellers = async ({ region }: { region: Region }) => {
  const apiName = 'Trip';
  const path = `/${region.country}/trips/secondary-travellers`;
  try {
    const result = await get({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'fetching secondary travellers failed. Please try again.' });
  }
};

export const createUnauthTrip = async (arg: {
  newTrip: Trip;
  region: Region;
  isMock?: boolean;
  onError?: (error: unknown) => void;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/unauth/trips`;
  const identity = await currentCredentials();
  const options = {
    identity,
    body: arg.newTrip,
    queryParams: arg.isMock ? { isMock: true } : undefined,
  } as any;

  try {
    const result = await post({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    arg.onError && arg.onError(error);
    throwError({ error, message: 'Creating trip failed. Please try again.' });
  }
};

export const purchaseTrip = async (arg: {
  tripId: string;
  region: Region;
  paymentOption: PaymentOption;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country}/trips/${arg.tripId}/purchase`;

  try {
    const body: any = {
      paymentType: arg.paymentOption.type ?? 'Other',
      tokenType: arg.paymentOption.tokenType ?? null,
    };

    if (arg.paymentOption.paymentToken) {
      body.paymentToken = arg.paymentOption.paymentToken;
    }

    if (arg.paymentOption.cardNumber) {
      body.cardNumber = arg.paymentOption.cardNumber;
    }

    if (arg.paymentOption.nameOnCard) {
      body.cardHolderName = arg.paymentOption.nameOnCard;
    }

    if (arg.paymentOption.expiryDate) {
      body.cardExpirationDate = arg.paymentOption.expiryDate;
    }

    const result = await post({
      apiName,
      path,
      options: {
        body,
      },
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Purchasing Trip failed. Please try again.' });
  }
};

export const purchaseUnauthTrip = async (arg: {
  tripId: string;
  region: Region;
  paymentOption: PaymentOption;
}) => {
  const apiName = 'Trip';
  const path = `/${arg.region.country.toLowerCase()}/unauth/trips/${arg.tripId}/purchase`;
  const identity = await currentCredentials();
  try {
    const body: Record<string, string | null> = {
      paymentType: arg.paymentOption.type ?? 'Other',
      tokenType: arg.paymentOption.tokenType ?? null,
    };

    if (arg.paymentOption.paymentToken) {
      body.paymentToken = arg.paymentOption.paymentToken;
    }

    if (arg.paymentOption.cardNumber) {
      body.cardNumber = arg.paymentOption.cardNumber;
    }

    if (arg.paymentOption.nameOnCard) {
      body.cardHolderName = arg.paymentOption.nameOnCard;
    }

    if (arg.paymentOption.expiryDate) {
      body.cardExpirationDate = arg.paymentOption.expiryDate;
    }

    const options = {
      body,
      identity,
    };

    const result = await post({
      apiName,
      path,
      options,
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Purchasing Trip failed. Please try again.' });
  }
};

export const validateApplePaySession = async ({
  region,
  appleUrl,
}: {
  region: Region;
  appleUrl: string;
}) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLocaleLowerCase()}/validateApplePaySession`;

  try {
    const identity = await currentCredentials();

    const body: Record<string, string> = {
      appleUrl,
    };
    const options = {
      body,
      identity,
    };

    const result = await post({
      apiName,
      path,
      options,
    }).response;

    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Validating Apple Pay Session failed. Please try again.' });
  }
};

export const refreshPolicy = async ({ region, tripId }: { tripId: string; region: Region }) => {
  const apiName = 'Trip';
  const path = `/${region.country}/paid-trips/${tripId}/refreshPolicy`;
  try {
    const result = await get({
      apiName,
      path,
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Refreshing policy failed. Please try again.' });
  }
};

type ApplyCodePayload = {
  tripId: string;
  region: Region;
  discountCode: string;
  email?: string;
  screeningParameters?: Record<string, unknown>;
};

export const applyDiscountCodeToTrip = async ({
  tripId,
  region,
  discountCode,
  screeningParameters,
}: ApplyCodePayload) => {
  const apiName = 'Trip';
  const path = `/${region.country}/trips/${tripId}/promotion/apply-code`;
  try {
    const result = await post({
      apiName,
      path,
      options: {
        body: {
          discountCode,
          screeningParameters,
        },
      } as any,
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Adding discount code failed. Please try again.' });
  }
};

export const removeDiscountCodeFromTrip = async ({
  tripId,
  region,
  screeningParameters,
}: {
  tripId: string;
  region: Region;
  screeningParameters?: Record<string, unknown>;
}) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/trips/${tripId}/promotion/delete-code`;

  try {
    const result: any = await post({
      apiName,
      path,
      options: {
        body: {
          screeningParameters,
        },
      } as any,
    }).response;
    return await result.body.json();
  } catch (error) {
    return throwError({ error, message: 'Removing discount code failed. Please try again.' });
  }
};

export const applyDiscountCodeToUnauthTrip = async ({
  tripId,
  region,
  discountCode,
  email,
  screeningParameters,
}: ApplyCodePayload) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/unauth/trips/${tripId}/promotion/apply-code`;
  try {
    const identity = await currentCredentials();

    const result = await post({
      apiName,
      path,
      options: {
        body: email
          ? { discountCode, email, screeningParameters }
          : { discountCode, screeningParameters },
        identity,
      } as any,
    }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    throwError({
      error,
      message: 'Adding discount code failed. Please try again.',
    });
  }
};

export const removeDiscountCodeFromUnauthTrip = async ({
  tripId,
  region,
  screeningParameters,
}: {
  tripId: string;
  region: Region;
  screeningParameters?: Record<string, unknown>;
}) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/unauth/trips/${tripId}/promotion/delete-code`;
  try {
    const identity = await currentCredentials();

    const result: any = await post({
      apiName,
      path,
      options: {
        identity,
        body: {
          screeningParameters,
        },
      } as any,
    }).response;
    return await result.body.json();
  } catch (error: any) {
    throwError({
      error,
      message: 'Removing discount code failed. Please try again.',
    });
  }
};

export const processunAuthFreeOfChargeTripThroughPromotion = async ({
  tripId,
  region,
}: {
  tripId: string;
  region: Region;
}) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/unauth/trips/${tripId}/promotion/purchase`;
  try {
    const identity = await currentCredentials();
    const result = await post({
      apiName,
      path,
      options: {
        identity,
      } as any,
    }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    throwError({
      error,
      message: 'Adding discount code failed. Please try again.',
    });
  }
};

export const processFreeOfChargeTripThroughPromotion = async ({
  tripId,
  region,
}: {
  tripId: string;
  region: Region;
}) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}/trips/${tripId}/promotion/purchase`;
  try {
    const result = await post({
      apiName,
      path,
    }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    throwError({
      error,
      message: 'Adding discount code failed. Please try again.',
    });
  }
};

export const postEmcEmailAssessmentLetter = async ({
  name,
  email,
  boostId,
  assessmentNumber,
  region,
  isAuthenticated = false,
}: EMCEmailAssessmentRequest) => {
  const apiName = 'Trip';
  const path = `/${region.country.toLowerCase()}${
    isAuthenticated ? '' : '/unauth'
  }/emc/email/${assessmentNumber}`;
  const identity = await currentCredentials();

  try {
    const result = await post({
      apiName,
      path,
      options: {
        identity,
        body: {
          name,
          email,
          boostId,
        },
      } as any,
    }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    return throwError({
      error,
      message: 'Fail to email medical assessment letter. Please try again.',
    });
  }
};
