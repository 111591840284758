import get from 'lodash/get';
import { useMemo, useState } from 'react';

import { REGIONS } from 'freely-shared-constants';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { SelectOption, State } from 'freely-shared-types';

import { Button } from '@components/button';
import { Combobox } from '@components/combobox';
import { Container } from '@components/container';
import { Text } from '@components/text';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { useTripStore } from '@store';
import { testProps } from '@utils';

const setRegion = useRegionStore.getState().setRegion;
const resetTripState = useTripStore.getState().resetTripState;

export const StateOfResidence = () => {
  const region = useRegionStore(getRegionSelector);
  const [selectedState, setSelectedState] = useState(region?.state);

  const canGoNext = !!selectedState;

  const states = useMemo<State[]>(
    () => (region?.country === 'AU' ? [] : get(REGIONS, `${region?.country}.states`) || []),
    [region?.country],
  );

  const selectedValue = states.find(item => item.code === selectedState);
  const defaultValue = {
    id: selectedValue?.code,
    label: selectedValue?.name,
    value: selectedValue?.code,
  } as SelectOption;

  const handleNext = () => {
    if (region?.country && canGoNext) {
      resetTripState(); // reset trip sortKey and trip state for new policy flow
      setRegion({ ...region, state: selectedState });
      router.navigate(ROUTE_PATHS().tripDestinations);
    }
  };

  return (
    <main>
      <Container
        titleText={i18n.t('stateOfResidence.title')}
        subtitleText={i18n.t('stateOfResidence.subtitle')}
        className="flex flex-1 flex-col"
        hasRegionResidencyStatus>
        <div className="mx-auto w-full max-w-lg flex-1">
          <Combobox
            items={states.map(item => ({
              id: item.code,
              label: item.name,
              value: item,
            }))}
            type="select"
            onSelect={item => setSelectedState((item as State).code)}
            defaultValue={defaultValue}
            inputProps={{
              placeholder: i18n.t('stateOfResidence.input.placeholder'),
            }}
          />
          <div className="mt-6 flex w-full flex-col items-center gap-4 border-t pt-6">
            <Button
              {...testProps('state-of-residence-agreement-next-button')}
              isJumboSize
              variant="mint"
              size="md"
              disabled={!canGoNext}
              onClick={handleNext}>
              Next
            </Button>
            <Text variant="sub-heading-14/medium" withMarkDown>
              {i18n.t('stateOfResidence.footNote.title')}
            </Text>
          </div>
        </div>
      </Container>
    </main>
  );
};
