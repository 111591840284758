import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: 'mint' | 'charcoal' | 'white';
}

const sizes = {
  xs: 'h-2.5 w-2.5',
  sm: 'h-5 w-5',
  md: 'h-10 w-10',
  lg: 'h-12 w-12',
  xl: 'h-14 w-14',
};

export const Loader: FC<LoaderProps> = ({ size = 'md', color, ...rest }) => (
  <div {...rest} className={twMerge('flex justify-center items-center', rest.className)}>
    <svg
      className={twMerge(
        sizes[size],
        'animate-spin text-black',
        color === 'mint' && `!text-mint-100`,
        color === 'charcoal' && `!text-charcoal`,
        color === 'white' && `!text-white`,
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
);
