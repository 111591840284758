import { useEffect, useRef } from 'react';

import { guestSubscription } from 'freely-shared-api';
import { useConfigStore } from 'freely-shared-stores';
import {
  COIUpdatedEvent,
  GeneralEventType,
  IndivudualEvent,
  PolicyIssuedEvent,
  TRIP_STATUS,
} from 'freely-shared-types';
import { logToSentry } from 'freely-shared-utils';

import { ROUTE_PATHS, router } from '@routes/router';
import { useGuestStore, useTripStore } from '@store';

const TAG = 'useGuestSubscription';

type GuestSubscriptionPayload = {
  data: { guestSubscription: COIUpdatedEvent | PolicyIssuedEvent | IndivudualEvent };
};

/**
 * A hook that subscribes to guestSubscription only when the guestId is created.
 */
export function useGuestSubscription() {
  const updateTripPolicyStatus = useTripStore(state => state.updateTripPolicyStatus);

  const userId = useGuestStore(state => state.guest?.userId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subscription = useRef<any>(null);

  const isPenguinTimeoutEnabled =
    useConfigStore(state => state?.regionSpecificConfig?.RULES.PAYMENT_TIMEOUT_ENABLED) ?? true;

  const processPaymentTimeoutSecs =
    useConfigStore(
      state => state?.regionSpecificConfig?.RULES.PAYMENT_PROCESSING_TIMEOUT_SECONDS,
    ) ?? 15;

  const isNewPolicyIssuanceTagsEnabled =
    useConfigStore.getState().regionSpecificConfig?.FEATURE_TOGGLE?.newPolicyIssuanceTags?.enabled;

  useEffect(() => {
    function onNext({ data: { guestSubscription } }: GuestSubscriptionPayload) {
      const eventType = guestSubscription?.eventType;

      switch (eventType) {
        case GeneralEventType.POLICY_ISSUED:
          // Edge case: If the policy issuance is TIMEOUT before 15 secs, we should navigate to the confirmation page
          if (isPenguinTimeoutEnabled && guestSubscription?.policyIssuance === 'TIMEOUT') {
            updateTripPolicyStatus({
              status: TRIP_STATUS.WAITING_PAYMENT,
            });
            router.navigate(ROUTE_PATHS().confirmation);
          }
          if (
            (guestSubscription.policyIssuance === 'SUCCESS' && !isNewPolicyIssuanceTagsEnabled) ||
            (guestSubscription.policyIssuance !== 'FAILURE' && isNewPolicyIssuanceTagsEnabled)
          ) {
            updateTripPolicyStatus({
              status: TRIP_STATUS.PAID,
              policyNumber: guestSubscription.policyNumber,
            });
          }

          if (guestSubscription?.policyIssuance === 'FAILURE') {
            updateTripPolicyStatus({ policyStatus: 'ERROR' });
          }
          break;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onError(data: any) {
      logToSentry(new Error(data?.error?.errors[0].message), { tag: TAG });
    }

    try {
      if (isPenguinTimeoutEnabled) {
        // Set up the timer to unsubscribe after 15 seconds
        const timerId = setTimeout(() => {
          if (subscription.current) {
            subscription.current.unsubscribe();
          }
        }, (processPaymentTimeoutSecs - 1) * 1000);

        if (userId && !subscription?.current) {
          guestSubscription(onNext, onError)
            .then(sub => {
              subscription.current = sub;
            })
            .catch(err => {
              logToSentry(err);
            });
        }
        return () => {
          clearTimeout(timerId);
        };
      } else {
        if (userId && !subscription?.current) {
          guestSubscription(onNext, onError)
            .then(sub => {
              subscription.current = sub;
            })
            .catch(err => {
              logToSentry(err);
            });
        }
      }
    } catch (e) {
      logToSentry(e as Error);
    }
  }, [
    isPenguinTimeoutEnabled,
    processPaymentTimeoutSecs,
    updateTripPolicyStatus,
    userId,
    isNewPolicyIssuanceTagsEnabled,
  ]);
}
