import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { shallow } from 'zustand/shallow';

import { colors } from 'freely-shared-design';
import { formatPromoCode } from 'freely-shared-utils';

import { Assets } from '@assets';
import { i18n } from '@i18n';
import { promoCodeSelector, useFeatureFlagsStore, useTripStore } from '@store';
import { growsurfApi, sendAnalyticsEvent, testProps } from '@utils';

import { Icon } from '../icon';
import { Input } from '../input';
import { Loader } from '../loader';
import { Text } from '../text';

export const DiscountCodeInput = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => {
  const error = useTripStore(state => state.error);
  const setError = useTripStore(state => state.setError);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const applyDiscountCode = useTripStore(state => state.applyDiscountCode);
  const getAutoDiscount = useFeatureFlagsStore(state => state.getAutoDiscount);
  const tripPromotionCodeData = useTripStore(promoCodeSelector, shallow);
  const trip = useTripStore(state => state.trip);

  // payment intent should only be called on the payment screen
  const handleClick = useCallback(async () => {
    if (!discountCode.trim()) {
      return false;
    }

    setIsLoading(true);

    const formattedDiscountCode = formatPromoCode(discountCode);

    const referralData = await growsurfApi.getReferralData();
    const autoDiscountCode = getAutoDiscount({
      secondaryTravellers: trip.secondaryTravellers,
      appliedDiscountCode: formattedDiscountCode,
      destinations: trip.destinations,
      referralData,
    });

    if (autoDiscountCode && autoDiscountCode !== formattedDiscountCode) {
      setError({ message: 'invalid' } as Error);
      setIsLoading(false);
      return;
    }

    await applyDiscountCode(formattedDiscountCode);

    setDiscountCode('');
    setIsLoading(false);
  }, [
    applyDiscountCode,
    discountCode,
    getAutoDiscount,
    setError,
    trip.destinations,
    trip.secondaryTravellers,
  ]);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClearError = () => {
    setError(undefined);
    setDiscountCode('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    setDiscountCode(e.target.value);
  };

  const formatInputOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formattedCode = formatPromoCode(e.target.value);

    if (formattedCode) {
      sendAnalyticsEvent('Discount Code Typed', { text: formattedCode });
    }

    setDiscountCode(formattedCode);
  };

  // Error Component
  if (error?.message && (error.message.includes('expired') || error?.message.includes('invalid'))) {
    const variant = error.message.includes('expired') ? 'expired' : 'invalid';
    return <ErrorInput variant={variant} onClick={handleClearError} />;
  }

  // Applied Discount Component
  const hasDiscountCode = !!tripPromotionCodeData?.discountCode;
  if (hasDiscountCode && !discountCode) {
    const promoCode = tripPromotionCodeData?.discountCode ?? '';
    const label = tripPromotionCodeData?.appLabel;
    return <AppliedDiscount promoCode={promoCode} label={label} />;
  }

  // Default Input Component
  const svgIconRight = (
    <div className="h-[32px] w-[32px] flex item-center justify-center">
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <Icon
          {...testProps('add-discount-code')}
          rounded
          type="Add"
          fill="charcoal"
          className="h-[32px] w-[32px] cursor-pointer"
          iconClassName="p-[8px] text-snow"
          onClick={handleClick}
        />
      )}
    </div>
  );
  return (
    <DiscountInput
      isLoading={isLoading}
      disabled={hasDiscountCode}
      value={discountCode}
      svgIconRight={svgIconRight}
      onBlur={formatInputOnBlur}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      {...props}
    />
  );
};

/**
 * Handle Error Input
 */
type ErrorInputProps = {
  variant: keyof typeof messages;
  onClick: () => void;
};

const messages = {
  invalid: 'Enter a valid code',
  expired: 'This code has expired',
} as const;

const ErrorInput = ({ variant, onClick }: ErrorInputProps) => {
  return (
    <div className="w-full">
      <Input
        disabled
        onClick={onClick}
        defaultValue={messages[variant]}
        className="text-sm bg-white text-cherry-100 outline-2 border-cherry-100"
        svgIcon={<Assets.AlertIcon height={12} width={12} fill={colors.cherry[100]} />}
        svgIconRight={
          <Icon
            onClick={onClick}
            rounded
            type="Close"
            fill="cherry"
            className="h-[32px] w-[32px] cursor-pointer"
            iconClassName="p-[8px]"
          />
        }
        svgRightIconClassName="!right-[6px]"
      />
    </div>
  );
};

/**
 * Discount Input Component
 */
interface DiscountInputProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  disabled: boolean;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  svgIconRight: React.ReactElement;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const DiscountInput = ({
  disabled,
  onBlur,
  onChange,
  value,
  svgIconRight,
  onKeyDown,
  isLoading,
  className,
  ...rest
}: DiscountInputProps) => {
  return (
    <div
      className={twMerge('w-full lg:border lg:border-rain lg:rounded-full', className)}
      {...rest}>
      <Input
        {...testProps('add-discount-code-input')}
        disabled={disabled}
        autoCapitalize="characters"
        onBlur={onBlur}
        onKeyPress={onKeyDown}
        placeholder="Add discount code"
        onChange={onChange}
        value={value}
        svgIconRight={svgIconRight}
        svgRightIconClassName={twMerge('!right-[6px]', !isLoading && 'pr-1')}
        className="pl-5 !py-4 text-sm focus:bg-white focus:outline-1 focus:outline-mint-100 font-golos font-medium text-charcoal/60"
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

/**
 * Applied discount Component
 */

interface AppliedDiscountProps {
  promoCode: string;
  label: string;
}

const AppliedDiscount = ({ promoCode, label }: AppliedDiscountProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const removeDiscountCode = useTripStore(state => state.removeDiscountCode);

  return (
    <div className="flex items-center justify-between w-full gap-3 py-3 lg:pl-5 lg:border lg:border-rain lg:rounded-full lg:pr-3">
      <div className="flex flex-wrap items-center gap-1">
        <div className="flex items-center gap-1">
          <Assets.Discount fill="black" height={16} width={16} className="w-4 h-4" />
          <Text variant={{ sm: 'sub-heading-14/bold', lg: 'callout-16/bold' }}>
            {i18n.t('checkout.discount.title')}
          </Text>
        </div>
        <Text variant={{ sm: 'sub-heading-14/regular', lg: 'callout-16/regular' }}>({label})</Text>
      </div>
      <div className="flex items-center justify-center gap-2 ">
        <Text
          variant={{ sm: 'sub-heading-14/bold', lg: 'callout-16/medium' }}
          className="underline">
          {promoCode}
        </Text>
        <div className="h-[32px] w-[32px] flex item-center justify-center">
          {isLoading ? (
            <Loader size="sm" />
          ) : (
            <Icon
              type="Close"
              rounded
              fill="snow"
              className="h-[32px] w-[32px] cursor-pointer"
              onClick={async () => {
                setIsLoading(true);
                await removeDiscountCode();
                setIsLoading(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
