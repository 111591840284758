import {
  applyDiscountCodeToUnauthTrip,
  fetchUnauthTrip,
  getCreditCardToken,
  processunAuthFreeOfChargeTripThroughPromotion,
  purchaseUnauthTrip,
  removeDiscountCodeFromUnauthTrip,
  updateUnauthBoost,
} from 'freely-shared-api';
import {
  openApiInstances,
  saveScreeningResult as saveScreeningResultApi,
} from 'freely-shared-open-api';
import { GetStore, getRegionCountry, getRegionState, useConfigStore } from 'freely-shared-stores';
import {
  Boost,
  PaymentOption,
  ScreeningResultRequest,
  Trip,
  US_PAYMENT_TOKEN_TYPE,
  UpdateBoostArgument,
} from 'freely-shared-types';
import {
  getEmcBoost,
  getEmcRegionsFromDestinations,
  getVeriskScreeningParametersForRedeclare,
  logToSentry,
  throwError,
} from 'freely-shared-utils';

import { useBoostDetailsStore } from '@store/boost';
import { TOptionalFields } from '@types';
import { sendAnalyticsECommerce, sendAnalyticsEvent, webEnv } from '@utils';

import { TripStore } from './trip.store';

const TAG = 'trip.api';
export const fetchTrip = async (get: GetStore<TripStore>) => {
  const { setTrip, setPending, setError } = get();

  const region = getRegionState();
  if (!region) {
    return;
  }

  try {
    setPending(true);
    const response = (await fetchUnauthTrip({
      region: region,
    })) as Trip;

    setTrip(response);
    return response;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const updateBoostInTrip = async (
  get: GetStore<TripStore>,
  { boostId, updatePayload, isRefreshingTrip = true }: UpdateBoostArgument,
) => {
  const { trip, setPending, setError, setTrip } = get();
  const region = getRegionState();
  const boost = trip.boosts?.find(it => it.boostId === boostId);

  if (!trip) {
    throw new Error('Trip is undefined');
  }

  if (!boost) {
    throw new Error('Boost is undefined');
  }

  if (!region) {
    return;
  }

  try {
    setPending(true);
    const updatedBoost = await updateUnauthBoost({ region, boostId, updatePayload, isPaid: false });
    if (isRefreshingTrip) {
      const updatedTrip = (await fetchUnauthTrip({
        region: region,
      })) as Trip;
      setTrip(updatedTrip);
    }
    return updatedBoost as Boost;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const applyDiscountCodeInTrip = async (
  get: GetStore<TripStore>,
  discountCode: string,
  email?: string,
  ignoreAnalytics?: boolean,
) => {
  const { setTrip, setPending, setError, trip } = get();
  const region = getRegionState();

  if (!region) {
    return;
  }

  if (!trip?.sortKey) {
    throw new Error('Trip sort key is not defined');
  }
  if (!discountCode) {
    throw new Error('Discount code is not defined');
  }

  try {
    setPending(true);
    const response = await applyDiscountCodeToUnauthTrip({
      tripId: trip.sortKey,
      region,
      discountCode,
      email,
      screeningParameters: getVeriskScreeningParametersForRedeclare({
        env: import.meta.env.VITE_ENV,
        regionIds: getEmcRegionsFromDestinations(trip.destinations),
        tripDuration: getEmcBoost(trip)?.duration,
      }),
    });
    setTrip(response);
    if (!ignoreAnalytics) {
      sendAnalyticsECommerce('Promotion Clicked', { promoCode: discountCode });
      sendAnalyticsEvent('Partner ID added', { partnerId: discountCode });
      sendAnalyticsEvent('Discount Code Applied', { discountCode });
    }
    return response;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const removeDiscountCodeFromTrip = async (get: GetStore<TripStore>) => {
  const { setTrip, setPending, setError, trip } = get();
  const region = getRegionState();

  if (!trip || !trip.sortKey || !trip.promotionCode?.discountCode) {
    throw Error('Cannot remove discount code. Trip is not defined.');
  }

  if (!region) {
    return;
  }

  try {
    setPending(true);
    const response = (await removeDiscountCodeFromUnauthTrip({
      region: region,
      tripId: trip.sortKey,
      screeningParameters: getVeriskScreeningParametersForRedeclare({
        env: import.meta.env.VITE_ENV,
        regionIds: getEmcRegionsFromDestinations(trip.destinations),
        tripDuration: getEmcBoost(trip)?.duration,
      }),
    })) as Trip;
    setTrip(response);

    sendAnalyticsEvent('Discount Code Removed', {
      discountCode: trip?.promotionCode?.discountCode,
    });

    setTrip(response);
    return response;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const saveScreeningResult = async (
  get: GetStore<TripStore>,
  data: TOptionalFields<
    ScreeningResultRequest,
    | 'firstName'
    | 'lastName'
    | 'travellerId'
    | 'result'
    | 'boostId'
    | 'primaryTravellerId'
    | 'country'
    | 'isCompleted'
  >,
) => {
  const emcBoostId = getEmcBoost(get().trip)?.boostId;
  const primaryTravellerId = get()?.trip?.userId;
  const { setPending, setError } = get();
  const getConfig = useConfigStore.getState().getConfig;
  const country = getRegionCountry();
  const emcDeclaring = useBoostDetailsStore.getState()?.emcDeclaring;
  const travellerId = data.travellerId ?? emcDeclaring?.travellerId;
  const firstName = data.firstName ?? emcDeclaring?.firstName;
  const lastName = data.lastName ?? emcDeclaring?.lastName;

  if (!primaryTravellerId || !country || !emcBoostId || !firstName || !lastName || !travellerId) {
    return;
  }

  try {
    setPending(true);
    const config = await getConfig();

    openApiInstances.Trip.defaults.baseURL = config?.web.OPEN_APIS?.endpoints?.find(
      i => i.name === 'Trip',
    )?.endpoint;

    openApiInstances.Trip.defaults.headers.common['x-api-key'] =
      country === 'AU'
        ? import.meta.env.VITE_AU_TRIP_API_KEY
        : import.meta.env.VITE_US_TRIP_API_KEY;
    openApiInstances.Trip.defaults.headers.common.platform = 'web';
    openApiInstances.Trip.defaults.headers.common['isGuest'] = true;
    return await saveScreeningResultApi({
      ...data,
      boostId: emcBoostId,
      primaryTravellerId,
      country,
      firstName,
      lastName,
      travellerId,
    });
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const processFreeOfChargePromotionInTrip = async (get: GetStore<TripStore>) => {
  const { setPending, setError, trip } = get();
  const region = getRegionState();

  if (!region) {
    return;
  }

  if (!trip || !trip.sortKey || !trip.promotionCode?.discountCode) {
    throw Error('Cannot proceed with free promotion. Trip is not defined.');
  }

  try {
    setPending(true);
    const response = await processunAuthFreeOfChargeTripThroughPromotion({
      tripId: trip.sortKey,
      region: region,
    });
    return response;
  } catch (error) {
    logToSentry(error as Error, { tag: TAG });
    setError(error as Error);
  } finally {
    setPending(false);
  }
};

export const purchaseUsTrip = async (get: GetStore<TripStore>, paymentOption: PaymentOption) => {
  const { setPending, setError, trip } = get();
  const currency = useConfigStore.getState().regionSpecificConfig?.CURRENCY;
  const region = getRegionState();
  const creditCardTokenization =
    useConfigStore.getState()?.regionSpecificConfig?.FEATURE_TOGGLE?.creditCardTokenization;

  if (!region) {
    return;
  }

  if (!trip?.sortKey) {
    throw Error('Trip sort key is not defined');
  }

  try {
    setPending(true);

    const _paymentOption = { ...paymentOption };

    if (
      creditCardTokenization?.enabled &&
      _paymentOption.tokenType === US_PAYMENT_TOKEN_TYPE.CREDIT_CARD
    ) {
      _paymentOption.tokenType = US_PAYMENT_TOKEN_TYPE.CREDIT_CARD_TOKEN;
      const creditCardToken = await getCreditCardToken({
        authToken: webEnv.VITE_US_CARD_TOKEN_AUTH,
        apiUrl: webEnv.VITE_US_CARD_TOKEN_URL,
        paymentDetails: {
          cardNumber: _paymentOption.cardNumber,
          cardExpirationDate: _paymentOption.expiryDate,
          cardHolderName: _paymentOption.nameOnCard,
          paymentType: _paymentOption?.type ?? undefined,
          country: 'USA',
        },
      });

      if (!creditCardToken?.token) {
        throw new Error('Error: Unable to tokenize credit card.');
      }
      _paymentOption.paymentToken = creditCardToken.token;

      delete _paymentOption.cardNumber;
    }

    const response = await purchaseUnauthTrip({
      paymentOption: _paymentOption,
      region: region,
      tripId: trip.sortKey,
    });
    return response;
  } catch (error) {
    setError(error as Error);

    sendAnalyticsEvent('Payment Failure', {
      revenue: trip.price ? trip.price / 100 : 0,
      currency,
    });
    throwError({ error, message: 'Failed to purchase trip. Please try again.' });
  } finally {
    setPending(false);
  }
};
