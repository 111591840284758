import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { Assets } from '@assets';
import { Notification } from '@components/notification';
import { Text } from '@components/text';
import { i18n } from '@i18n';
import { useFeatureFlagsStore, useTripStore } from '@store';

export const AutoDiscountCard = () => {
  const [appliedDiscountValue, appliedDiscountCode] = useTripStore(
    state => [
      state.trip.promotionCode?.discount ?? '',
      state.trip.promotionCode?.discountCode ?? '',
    ],
    shallow,
  );
  const getGroupDiscountPayload = useFeatureFlagsStore(state => state.getGroupDiscountPayload);

  const getDestinationDiscountPayload = useFeatureFlagsStore(
    state => state.getDestinationDiscountPayload,
  );

  const getReferralDiscountPayload = useFeatureFlagsStore(
    state => state.getReferralDiscountPayload,
  );

  const groupDiscountTitle = i18n.t('checkout.discount.group.title');

  const destinationDiscountTitle = i18n.t('checkout.discount.destination.title');

  const referralDiscountTitle = i18n.t('checkout.discount.referral.title');

  const headingTitle = useMemo(() => {
    if (getGroupDiscountPayload(appliedDiscountCode)) {
      return groupDiscountTitle;
    }
    if (getDestinationDiscountPayload(appliedDiscountCode)) {
      return destinationDiscountTitle;
    }

    if (getReferralDiscountPayload(appliedDiscountCode)) {
      return referralDiscountTitle;
    }
  }, [
    appliedDiscountCode,
    destinationDiscountTitle,
    getDestinationDiscountPayload,
    getGroupDiscountPayload,
    getReferralDiscountPayload,
    groupDiscountTitle,
    referralDiscountTitle,
  ]);

  if (headingTitle) {
    return (
      <Notification
        variant="info"
        left={<RedeemIcon />}
        right={<Text variant="body-18/500">{appliedDiscountValue}</Text>}
        headingProps={{ children: headingTitle, variant: 'body-18/bold' }}
        textContainerStyle="flex w-full"
      />
    );
  }
  return null;
};

const RedeemIcon = () => (
  <div className="w-5 h-5 mr-2">
    <Assets.Redeem className="w-full h-full" fill="black" />
  </div>
);
